import React, { useEffect, useState } from "react";
import styles from "../../../Components/Scss/Admin.module.scss";
import { useAuth } from "../../../Context/AuthContext";
import Table from "../../../Components/Global/Table";
import { Link, useNavigate } from "react-router-dom";
import CompanyLayout from "../../../Components/Company/Layouts";
import Filters from "../../../Components/Global/transaction/Filters";
import { toNumber } from "lodash";
import Unauthorised from "../../Global/Unauthorised";
import { HasPermission } from "../../../Components/Global/Permission";
import { currencyFormat, roundUp } from "../../../Utils/Common";
import Loader from "../../../Components/Global/widget/Loader";
import download from "downloadjs";
import Axios from "../../../Config/AxiosInstance";
import convertToLocalTime from "../../../Utils/timeZoneConverter";

const DEFAULT_SORT = {
  field: "name",
  direction: "asc",
};

function AllTerminals({ permission }) {
  const { token, selectedStoreIds, selectedDateList } = useAuth();
  const [data, setData] = useState([]);
  const [datePick, setDatePick] = useState();

  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [tableLoading, setTableLoading] = useState(true);
  const [from, setFrom] = useState(selectedDateList[0]);
  const [to, setTo] = useState(selectedDateList[1]);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState();
  const navigate = useNavigate();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [sort, setSort] = useState(DEFAULT_SORT);

  const requestData = {
    type: "store",
    days: "",
    s: search,
    from,
    to,
    page: currentPage,
    size: perPage,
    stores: currentStoreId,
    sort,
  };

  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);
  useEffect(() => {
    if (token && currentStoreId) {
      setData([]);
      setTableLoading(true);
      Axios(token)
        .post("/terminals", requestData)
        .then((response) => {
          if (response?.data?.response?.records) {
            setData(response.data.response.records);
            setTotalRows(response.data.response.totalItems);
          }
          setTableLoading(false);
        })
        .catch(() => {
          setTableLoading(false);
        });
    }
  }, [token, datePick, search, currentPage, currentStoreId, sort, perPage]);
  const handleOnPageChange = async (val) => {
    if (val) {
      setCurrentPage(val);
    }
  };

  const handleRowClick = (val) => {
    console.log(val);
    navigate(`/terminal/${val?.terminal_external_id}`);
  };

  const COLUMNS = [
    {
      name: "#",
      selector: (_, index) => index + 1,
      width: "50px",
    },
    {
      name: "Terminal Name",
      selector: (row) => row?.name,
      sortable: true,
      sortField: "terminalName",
    },
    {
      name: "Username",
      selector: (row) => row?.username,
      sortable: true,
      sortField: "username",
    },
    {
      name: "Location",
      selector: (row) => row?.location,
      sortable: true,
      sortField: "location",
    },
    {
      name: "Store Name",
      selector: (row) => row?.store_name,
      sortable: true,
      sortField: "storeName",
    },
    {
      name: "Sales",
      selector: (row) => currencyFormat(roundUp(row?.orderAmount || 0)),
      sortable: true,
    },
    {
      name: "Tip",
      selector: (row) => currencyFormat(toNumber(row?.tip || 0)),
      sortable: true,
    },
    {
      name: "Sales + Tip",
      selector: (row) => currencyFormat(roundUp(row?.totalAmount || 0)),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div
          className={`cursor-pointer font-medium tableBtn break-normal rounded-full px-4 py-1 leading-5 mr-3`}
          onClick={() => handleRowClick(row)}
        >
          View
        </div>
      ),
    },
  ];
  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(
      convertToLocalTime(val[0])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTo(
      convertToLocalTime(val[1])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
  };

  const handleSearchSubmit = async (val) => {
    if (val) {
      setSearch(val);
    }
  };

  const handleDownload = async () => {
    try {
      setDownloadLoading(true);
      const data = await Axios(token).post(
        "/download/terminal/report",
        requestData,
        {
          responseType: "blob",
        },
      );
      setDownloadLoading(false);
      download(
        data.data,
        `Terminal reports ${convertToLocalTime(new Date()).format(
          "MMMM Do YYYY",
        )}`,
      );
    } catch (e) {
      setDownloadLoading(false);
    }
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(currentPage);
  };

  const handleSort = async (column, sortDirection) => {
    let col_name = "";
    if (column === "Terminal Name") {
      col_name = "name";
    }
    if (column === "Sales") {
      col_name = "orderAmount";
    }
    if (column === "Sales + Tip") {
      col_name = "totalAmount";
    }
    if (column === "Tip") {
      col_name = "tip";
    }
    if (column === "Store Name") {
      col_name = "store_name";
    }
    if (column === "Location") {
      col_name = "location";
    }
    if (column === "Refunds") {
      col_name = "totalRefundAmount";
    }
    setSort({
      field: col_name,
      direction: sortDirection,
    });
  };

  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        justifyContent: "center !important",
        "&:nth-child(3), &:nth-child(2), &:nth-child(8), &:nth-child(6), &:nth-child(5)":
          {
            textAlign: "left",
            justifyContent: "start !important",
          }, // Apply to the first header cell (Name)
        // '&:nth-child(5), &:nth-child(6)': {
        //   textAlign: 'right',
        //   justifyContent: 'end !important'
        // },
      },
    },
    cells: {
      style: {
        textAlign: "center",
        justifyContent: "center !important",
        "&:nth-child(3), &:nth-child(2), &:nth-child(8), &:nth-child(6), &:nth-child(5)":
          {
            textAlign: "left",
            justifyContent: "start !important",
          }, // Apply to the first column cells (Name)
        // '&:nth-child(5), &:nth-child(6)': {
        //   textAlign: 'right',
        //   justifyContent: 'end !important'
        // },
      },
    },
  };

  return (
    <>
      {downloadLoading && <Loader />}

      {HasPermission(permission) ? (
        <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
          <div className="flex items-center justify-between">
            <h5 className={`text-xl md:text-2xl font-semibold flex-1 mr-5`}>
              All Terminals
            </h5>
            {HasPermission(["create_terminal"]) && (
              <Link
                to={"/register/terminal"}
                className={`px-3 py-2 md:py-2.5 md:px-5 text-xs md:text-sm text-white flex items-center rounded bg-green-500 focus:bg-green-700 hover:bg-green-700 right-0 top-0 border-0 outline-none`}
              >
                Register Terminal
              </Link>
            )}
          </div>
          <div className={` mt-5`}>
            <div className={``}>
              <Filters
                placeHolder={"Terminal Name"}
                date={handleDateChange}
                onSearchSubmit={handleSearchSubmit}
                onSearchReset={() => setSearch("")}
                downloadBtn={true}
                onDownload={handleDownload}
                downloadBtnDisable={!data.length}
              />

              <div className={`${styles.table_ui_style} mt-5`}>
                <Table
                  pending={tableLoading}
                  columns={COLUMNS}
                  data={data}
                  perPage={perPage}
                  totalRows={totalRows}
                  showPointer={false}
                  onPageChange={handleOnPageChange}
                  // onRowClick={handleRowClick}
                  defaultSortFieldId={2}
                  defaultSortAsc={DEFAULT_SORT.direction === "asc"}
                  sortServer
                  handleSort={handleSort}
                  customStyles={customStyles}
                  sortIcon={
                    <svg
                      width="9"
                      height="12"
                      viewBox="0 0 9 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.5 1.5L4.5 10.5M4.5 10.5L8 7M4.5 10.5L1 7"
                        stroke="#064278"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  }
                  onRowPerPageChange={(currentRowsPerPage, currentPage) =>
                    handleRowsPerPageChange(currentRowsPerPage, currentPage)
                  }
                />
              </div>
            </div>
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </>
  );
}

export default AllTerminals;
