import DOMPurify from "dompurify";
import isEmpty from "validator/es/lib/isEmpty";
import { toNumber } from "lodash";

export const inputValidation = (input, isNumber = false) => {
  if (isNumber && encodeURI(input)) {
    const sanitize = DOMPurify.sanitize(input);
    return toNumber(sanitize);
  } else if (!isNumber && encodeURI(input) && !isEmpty(input)) {
    return DOMPurify.sanitize(input);
  }
  return "";
};
