import React, { useEffect, useState } from "react";
import styles from "../../../Components/Scss/Admin.module.scss";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import CompanyLayout from "../../../Components/Company/Layouts";
import Filters from "../../../Components/Global/transaction/Filters";
import Table from "../../../Components/Global/Table";
import { HasPermission } from "../../../Components/Global/Permission";
import Unauthorised from "../../Global/Unauthorised";
import { toNumber } from "lodash";
import { currencyFormat } from "../../../Utils/Common";
import download from "downloadjs";
import Loader from "../../../Components/Global/widget/Loader";
import Axios from "../../../Config/AxiosInstance";
import convertToLocalTime from "../../../Utils/timeZoneConverter";

export default function Refunds({ permission }) {
  const { token, selectedStoreIds } = useAuth();
  const [transactionFor, setTransactionFor] = useState("all");
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState();
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState("");
  const [datePick, setDatePick] = useState([]);
  const [to, setTo] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const navigate = useNavigate();
  const getTransactions = async () => {
    try {
      setTableLoading(true);
      let userType = "store";
      const data = await Axios(token).post(`/all-refund`, {
        s: search,
        days: "",
        from: from,
        to: to,
        size: perPage,
        page: currentPage,
        type: userType,
        stores: currentStoreId,
        transactionFor: transactionFor,
      });
      if (data && data.data && data.data.response && data.data.response) {
        const { records, ...pagination } = data.data.response;
        filterData(records);
        setPagination(pagination);
        setTableLoading(false);
      } else {
        setPagination(null);
        setTableLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);
  const columns = [
    {
      name: "Date",
      selector: (row) => row?.date,
    },
    {
      name: "Customer",
      selector: (row) => row?.customer,
      style: {
        textTransform: "capitalize",
      },
    },
    {
      name: "Processed By",
      selector: (row) => row?.proceedBy,
      style: {
        textTransform: "capitalize",
      },
    },
    {
      name: "Order Amount",
      selector: (row) => row?.amount,
      style: {
        paddingTop: "0",
        paddingBottom: "0",
        button: {
          Width: "200px",
        },
      },
    },
    {
      name: "Refunded Amount",
      selector: (row) => row?.refund,
      style: {
        paddingTop: "0",
        paddingBottom: "0",
        div: {
          background: "rgba(239,68,68,0.09)",
          color: "rgb(239,68,68)",
          fontWeight: "bolder",
          borderRadius: "1.5rem",
          padding: "5px 15px",
        },
      },
    },
    {
      name: "Refunded By",
      selector: (row) => row?.refundedBy,
      style: {
        textTransform: "capitalize",
      },
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      style: {
        textTransform: "capitalize",
      },
    },
    {
      name: "Reason",
      selector: (row) => row?.reason?.replace(/_/g, " "),
      style: {
        textTransform: "capitalize",
      },
    },
  ];
  useEffect(() => {
    if (currentStoreId && token) {
      setTransactions([]);
      getTransactions().then();
    }
  }, [
    currentStoreId,
    token,
    datePick,
    search,
    transactionFor,
    currentPage,
    perPage,
  ]);
  const filterData = (data) => {
    let records = [];
    if (data) {
      data.map((items, index) => {
        records.push({
          id: index + 1,
          no: index + 1,
          date: convertToLocalTime(items?.createdAt).format("ll LT"),
          customer: items.Customer?.fullName?.toLowerCase(),
          order_id: items?.order_id,
          refund: currencyFormat(toNumber(items?.amount)),
          amount: currencyFormat(toNumber(items?.Transaction?.order_amount)),
          reason: items?.reason,
          external_id: items?.Transaction?.transactions_external_id,
          type: items?.type,
          proceedBy: `${items?.proceedBy?.UserProfile?.first_name || ""} ${
            items?.proceedBy?.UserProfile?.last_name || ""
          }`,
          refundedBy: `${items?.User?.UserProfile?.first_name || ""} ${
            items?.User?.UserProfile?.last_name || ""
          }`,
        });
      });
      setTransactions(records);
    }
  };

  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(
      convertToLocalTime(val[0])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTo(
      convertToLocalTime(val[1])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
  };
  const handleOnRowClick = (val) => {
    navigate(`/transaction/${val.external_id}`);
  };

  const handlePageChange = (page) => {
    if (page) {
      setCurrentPage(page);
    }
  };

  const quarries = {
    stores: currentStoreId,
    s: search,
    days: "",
    from: from,
    to: to,
    transactionFor: transactionFor,
    page: currentPage,
    size: perPage,
    type: "store",
  };
  const handleDownload = async () => {
    try {
      setLoading(true);
      const data = await Axios(token).post(
        "/download/refund/report2",
        quarries,
        {
          responseType: "blob",
        },
      );
      setLoading(false);
      download(
        data.data,
        `Refunds reports ${convertToLocalTime(new Date()).format(
          "MMMM Do YYYY",
        )}`,
      );
    } catch (e) {
      setLoading(false);
    }
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(currentPage);
  };
  return (
    <>
      {loading && <Loader />}
      {HasPermission(permission) ? (
        <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
          <div className={`mt-2`}>
            <Filters
              isDashboard={true}
              download={false}
              downloadBtn={true}
              placeHolder={"Customer Name"}
              onDownload={handleDownload}
              paymentMenu={true}
              currentMenuItem={transactionFor}
              paymentMenuKey={[
                { name: "All", key: "all" },
                {
                  name: "Refunds",
                  key: "REFUND",
                },
                { name: "Voids", key: "VOID" },
              ]}
              date={handleDateChange}
              onMenuClick={(key) => setTransactionFor(key)}
              onSearchSubmit={(val) => setSearch(val)}
              onSearchReset={() => setSearch("")}
              downloadBtnDisable={!transactions.length}
            />
            <div className={`mt-5 ${styles.table_ui_style}`}>
              <Table
                pending={tableLoading}
                data={transactions}
                totalRows={pagination?.totalItems}
                perPage={pagination?.size}
                columns={columns}
                showPointer={true}
                onRowClick={handleOnRowClick}
                onPageChange={handlePageChange}
                onRowPerPageChange={(currentRowsPerPage, currentPage) =>
                  handleRowsPerPageChange(currentRowsPerPage, currentPage)
                }
              />
            </div>
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </>
  );
}
