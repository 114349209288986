import { toNumber } from "lodash";
import Config from "../Config/index.json";

export const makeDashboardUrl = (
  Role,
  terminalId = null,
  permissions,
  driverId = null,
  userInfo = {},
  inTerminal = false,
) => {
  let URL;
  if (
    userInfo &&
    (userInfo.isFirstTime || userInfo.resetPin || userInfo.resetPassword)
  ) {
    URL = "/update/password";
  } else if ((Role === "terminal" || inTerminal) && terminalId) {
    if (permissions && permissions.includes("make_standard_payment")) {
      URL = `/terminal/standard`;
    } else if (
      permissions &&
      permissions.includes("make_contactless_payment")
    ) {
      URL = `/terminal/contactless/${terminalId}`;
    } else {
      URL = `/unauthorised`;
    }
  } else if (Role === "driver" && driverId) {
    if (permissions && permissions.includes("make_standard_payment")) {
      URL = `/terminal/standard`;
    } else {
      URL = `/unauthorised`;
    }
  } else if (Role === "dispatcher" && driverId) {
    if (permissions && permissions.includes("assign_driver_for_contactless")) {
      URL = `/terminal/contactless/${driverId}`;
    } else {
      URL = `/unauthorised`;
    }
  } else {
    URL = "/dashboard";
  }
  return URL;
};
export const currencyFormat = (num) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(roundUp(num));

export const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")

    .replace(/^-+/, "")
    .replace(/-+$/, "");
};

export const filterAmount = (n, isTips, amountLimits = 1000) => {
  let result = 0;
  let number = ("" + n).slice(0, 6);
  if (toNumber(number)) {
    result = toNumber(number) / 100;
    if (isTips) {
      result = number / 10;
    }
    if (number.indexOf(".") === -1) {
      if (toNumber(number) <= 1000 && !isTips) {
        return toNumber(number);
      } else if (toNumber(number) <= toNumber(amountLimits) && !isTips) {
        return toNumber(number);
      } else if (toNumber(number) <= 100 && isTips) {
        return toNumber(number);
      } else {
        return result;
      }
    } else {
      return toNumber(number);
    }
  }
  return "";
};

export const getAPIUrl = () => {
  try {
    let URL;
    const hostname = window.location.hostname;
    if (hostname === "localhost" || hostname === "192.168.0.170") {
      URL = `${Config.LocalURL}/api/v1`;
    } else if (
      hostname === "192.168.1.103" ||
      hostname === "192.168.0.101" ||
      hostname === "192.168.0.182" ||
      hostname === "192.168.29.97"
    ) {
      URL = `${Config.twisamLocal}/api/v1`;
    } else if (
      hostname === "app.gainfunds.com" ||
      hostname === "app.instantxfer.com"
    ) {
      URL = `${Config.ServerURL}/api/v1`;
    } else if (
      hostname === "staging-terminal.gainfunds.com" ||
      hostname === "staging-terminal.instantxfer.com"
    ) {
      URL = `${Config.StageURL}/api/v1`;
    } else if (hostname === "development-terminal.gainfunds.com") {
      URL = `${Config.ServerURL}/api/v1`;
    } else if (
      hostname === "dev-terminal.gainfunds.com" ||
      hostname === "dev-terminal.instantxfer.com"
    ) {
      URL = `${Config.DevelopmentURL}/api/v1`;
    } else if (
      hostname === "mirror-terminal.gainfunds.com" ||
      hostname === "mirror-terminal.instantxfer.com"
    ) {
      URL = `${Config.MirrorURL}/api/v1`;
    } else {
      throw new Error(
        `Unsupported environment, hostname = '${hostname}', place = 'App'`,
      );
    }
    return URL;
  } catch (e) {
    return null;
  }
};

export const roundUp = (value, decimalPlaces = 2) =>
  toNumber(
    toNumber(
      `${Math.round(
        toNumber(`${value || 0}e${decimalPlaces}`),
      )}e-${decimalPlaces}`,
    ).toFixed(decimalPlaces),
  );

export const QRURL = (url) => {
  if (url?.includes("scan")) {
    return "https://mirror-customer.gainfunds.com";
  }
  if (url?.includes("localhost")) {
    return "http://localhost:3331";
  }
  return "https://gainfunds.com";
};

export function cleanCustomerIDNumber(IdNumber) {
  return IdNumber.replace(/[^A-Z0-9-]/g, "");
}

export function formatAmount(inputValue) {
  // Remove non-numeric characters except for the decimal point
  let input = inputValue.replace(/[^0-9.]/g, "");

  // Ensure only one decimal point is present
  const parts = input.split(".");
  if (parts.length > 2) {
    input = `${parts[0]}.${parts.slice(1).join("")}`;
  }

  // Parse the integer part and decimal part separately
  let [integerPart, decimalPart] = input.split(".");
  integerPart = integerPart || "0";

  // Ensure the integer part does not exceed 200000
  let num = parseInt(integerPart, 10);
  if (num > 200000) {
    num = 200000;
  }

  // Format the integer part and limit the decimal part to two digits
  let formattedNum = num.toString();
  if (decimalPart) {
    formattedNum += "." + decimalPart.substring(0, 2);
  }

  // Ensure the formatted number is within the allowed range
  if (parseFloat(formattedNum) > 2000) {
    formattedNum = "2000.00";
  }

  return formattedNum;
}
