import React, { useState } from "react";
import { useAuth } from "../../../Context/AuthContext";
import Loader from "../../Global/widget/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormField } from "./FormField";
import { validationSchema } from "./validationSchema";
import { AVAILABLE_FILE_EXTENSIONS } from "./constants";
import convertToLocalTime from "../../../Utils/timeZoneConverter";
import axios from "axios";

export default function ChargebackCommentModal({ onClose, chargeback }) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: convertToLocalTime(new Date().toDateString(), "PPp"),
      customer: chargeback?.Customer.fullName?.toUpperCase(),
    },
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const addEvidence = async (data) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("chargeback_id", chargeback?.id);
      formData.append("date", data.date);
      formData.append("comment", data.comment);

      for (const file of data.files) {
        formData.append("files", file);
      }

      await axios({
        url: `/chargeback/addEvidence`,
        method: "post",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setLoading(false);
      setCreated(true);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      {loading && <Loader />}

      <div className="relative mx-auto my-6 w-11/12 md:w-2/4 lg:w-2/6 xl:w-96">
        <button
          onClick={onClose}
          className="absolute z-10 text-gray-400 top-3 right-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-2xl outline-none pb-7 focus:outline-none">
          <div className="relative px-6 pt-5">
            <div className="flex items-center justify-between border-b pb-2">
              <div>
                <h5 className={`text-lg font-semibold text-gray-700`}>
                  Store Chargeback Evidence
                </h5>
              </div>
            </div>
          </div>

          {created && (
            <div className="p-10 flex flex-col items-center">
              <div className="mb-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-16 h-16 stroke-green-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div className="text-xl">The evidence was added</div>
            </div>
          )}

          {!created && (
            <div className="mt-3 flex flex-col justify-between px-6 border-solid border-blueGray-200 rounded-b min-h-[370px] max-h-[500px] overflow-y-scroll scrollbar scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white">
              <div>
                <FormField label="Date">
                  <input
                    {...register("date")}
                    type="text"
                    disabled
                    className="font-normal h-full text-sm p-2.5 pr-5 text-gray-400 w-full block border border-gray-200 bg-transparent focus:border-green-600 placeholder-gray-500"
                  />
                </FormField>
                <FormField label="Customer">
                  <input
                    {...register("customer")}
                    type="text"
                    disabled
                    className="font-normal h-full text-sm p-2.5 pr-5 text-gray-400 w-full block border border-gray-200 bg-transparent focus:border-green-600 placeholder-gray-500"
                  />
                </FormField>
                <FormField label="Comment" error={errors.comment?.message}>
                  <textarea
                    {...register("comment")}
                    className="font-normal h-full text-sm p-2.5 pr-5 text-gray-600 w-full block border border-gray-200 bg-transparent focus:border-green-600 placeholder-gray-500"
                  />
                </FormField>
                <FormField
                  label="Attach file"
                  error={errors.files?.message}
                  hint={"Next formats are allowed: ".concat(
                    AVAILABLE_FILE_EXTENSIONS.join(", "),
                  )}
                >
                  <input
                    {...register("files")}
                    type="file"
                    multiple
                    accept={AVAILABLE_FILE_EXTENSIONS.join(",")}
                    className="font-normal h-full text-sm p-2.5 pr-5 text-gray-600 w-full block border border-gray-200 bg-transparent focus:border-green-600 placeholder-gray-500"
                  />
                </FormField>
              </div>

              <button
                onClick={handleSubmit(addEvidence)}
                className="px-3 mb-3 text-sm font-semibold uppercase text-white py-3 bg-green-500 rounded bold-style hover:bg-green-700 duration-500 ease-in-out"
              >
                Add evidence
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
