import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PrivetRoute from './ProtectedRoute';
import {StandardCustomerContextProvider} from "../Context/StandaredCustomerContext";

const StandardFirstSteps = lazy(() => import('../Page/SalesAssociate/userFlow/StandardFirstSteps'));
const LoginWithPhone = lazy(() => import('../Page/SalesAssociate/userFlow/loginWithPhone'));
const LoginWithDocument = lazy(() => import('../Page/SalesAssociate/userFlow/loginWithDocument'));
const CustomerTransferMoney = lazy(() => import('../Page/SalesAssociate/userFlow/customerTransferMoney'));
const CustomerTransferMemo = lazy(() => import('../Page/SalesAssociate/userFlow/CustomerTransferMemo'));
const TerminalActivities = lazy(() => import('../Page/SalesAssociate/userFlow/TerminalActivities'));
const ContactLess = lazy(() => import('../Page/SalesAssociate/userFlow'));
const TerminalContactLessTransactions = lazy(() => import('../Page/SalesAssociate/TerminalContactLessTransactions'));
const BudtenderSignup = lazy(() => import('../Page/SalesAssociate/BudtenderSignup'));

const TerminalRoutesWrapper = ({ children }) => (
  <StandardCustomerContextProvider>
    {children}
  </StandardCustomerContextProvider>
);

const TerminalRoutes = [
  <Route
    path="/terminal/standard"
    element={
      <TerminalRoutesWrapper>
        <PrivetRoute role={['terminal', 'driver']} permission={['make_standard_payment', 'make_all_payments']}>
          <StandardFirstSteps />
        </PrivetRoute>
      </TerminalRoutesWrapper>
    }
    key="terminal-standard"
  />,
  <Route
    path="/terminal/standard/auth/phone"
    element={
      <TerminalRoutesWrapper>
        <PrivetRoute role={['terminal', 'driver']} permission={['make_standard_payment', 'make_all_payments']}>
          <LoginWithPhone />
        </PrivetRoute>
      </TerminalRoutesWrapper>
    }
    key="terminal-auth-phone"
  />,
  <Route
    path="/terminal/standard/auth/document"
    element={
      <TerminalRoutesWrapper>
        <PrivetRoute role={['terminal', 'driver']} permission={['make_standard_payment', 'make_all_payments']}>
          <LoginWithDocument />
        </PrivetRoute>
      </TerminalRoutesWrapper>
    }
    key="terminal-auth-document"
  />,
  <Route
    path="/terminal/standard/transfer/money"
    element={
      <TerminalRoutesWrapper>
        <PrivetRoute role={['terminal', 'driver']} permission={['make_standard_payment', 'make_all_payments']}>
          <CustomerTransferMoney />
        </PrivetRoute>
      </TerminalRoutesWrapper>
    }
    key="terminal-transfer-money"
  />,
  <Route
    path="/terminal/standard/transfer/memo"
    element={
      <TerminalRoutesWrapper>
        <PrivetRoute role={['terminal', 'driver']} permission={['make_standard_payment', 'make_all_payments']}>
          <CustomerTransferMemo />
        </PrivetRoute>
      </TerminalRoutesWrapper>
    }
    key="terminal-transfer-memo"
  />,
  <Route
    path="/terminal/contactless"
    element={
      <TerminalRoutesWrapper>
        <PrivetRoute
          role={['terminal', 'dispatcher']}
          permission={['make_contactless_payment', 'make_all_payments', 'assign_driver_for_contactless']}
        >
          <ContactLess />
        </PrivetRoute>
      </TerminalRoutesWrapper>
    }
    key="terminal-contactless"
  />,
  <Route
    path="/terminal/transactions/contactless"
    element={
      <TerminalRoutesWrapper>
        <PrivetRoute
          role={['terminal', 'dispatcher']}
          permission={['view_own_transactions', 'view_contactless_payment_links']}
        >
          <TerminalContactLessTransactions />
        </PrivetRoute>
      </TerminalRoutesWrapper>
    }
    key="terminal-transactions-contactless"
  />,
  <Route
    path="/terminal/activities"
    element={
      <TerminalRoutesWrapper>
        <PrivetRoute
          role={['terminal', 'dispatcher']}
          permission={['view_own_transactions', 'view_contactless_payment_links']}
        >
          <TerminalActivities />
        </PrivetRoute>
      </TerminalRoutesWrapper>
    }
    key="terminal-activities"
  />,
  <Route path="/terminal/signup" element={<BudtenderSignup />} key="terminal-signup" />,
];

export default TerminalRoutes;
