import React, { useEffect, useState } from "react";
import styles from "../../../Components/Scss/Admin.module.scss";
import { useAuth } from "../../../Context/AuthContext";
import Table from "../../../Components/Global/Table";
import { useNavigate } from "react-router-dom";
import CompanyLayout from "../../../Components/Company/Layouts";
import Filters from "../../../Components/Global/transaction/Filters";
import Unauthorised from "../../Global/Unauthorised";
import { HasPermission } from "../../../Components/Global/Permission";
import { startCase, toLower } from "lodash";
import Axios from "../../../Config/AxiosInstance";
import convertToLocalTime from "../../../Utils/timeZoneConverter";

function Transactions({ permission }) {
  const { token, selectedStoreIds } = useAuth();
  const [customers, setCustomers] = useState();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [from, setFrom] = useState("");
  const [datePick, setDatePick] = useState();
  const [to, setTo] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [totalRows, setTotalRows] = useState();
  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);
  useEffect(() => {
    if (token && currentStoreId) {
      setLoading(true);
      //ref.current.continuousStart();
      Axios(token)
        .post(`/customers`, {
          s: search,
          days: "",
          from: from,
          to: to,
          page: currentPage,
          size: perPage,
          type: "store",
          stores: currentStoreId,
        })
        .then((response) => {
          if (response?.data?.response?.records) {
            const { records, totalItems, currentPage, size } =
              response.data.response;
            filterData(records, (currentPage - 1) * size);
            setTotalRows(totalItems);
          }
          setLoading(false);
        });
    }
  }, [token, datePick, search, currentPage, currentStoreId, perPage]);
  const filterData = (data, offset) => {
    const customerRecord = [];
    data.map((items, index) => {
      customerRecord.push({
        id: index + offset + 1,
        serialNo: index + offset + 1,
        date: convertToLocalTime(items.Customer.createdAt).format("PPP"),
        customerName: items.Customer?.fullName?.toLowerCase(),
        external_id: items.Customer?.customer_external_id,
        store: items.Store?.store_name,
      });
    });
    setCustomers(customerRecord);
  };
  const columns = [
    {
      name: "#",
      selector: (row) => row.serialNo,
      width: "60px",
    },
    {
      name: "Registration Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => startCase(toLower(row.customerName)),
      sortable: true,
    },
    // {
    //   name: "Account No.",
    //   selector: (row) => row.store,
    // },
    {
      name: "Store Name",
      selector: (row) => row?.store,
      sortable: true,
      sortField: "storeName",
    },
  ];
  const handleRowClick = (val) => {
    navigate(`/customer/${val.external_id}`);
  };
  const handleSearchSubmit = async (val) => {
    if (val) {
      setSearch(val);
    }
  };
  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(
      convertToLocalTime(val[0])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTo(
      convertToLocalTime(val[1])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
  };
  const handleOnPageChange = async (val) => {
    if (val) {
      setCurrentPage(val);
    }
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(currentPage);
  };
  return (
    <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
      {HasPermission(permission) ? (
        <>
          <div>
            <div className="flex justify-between">
              <div className="items-center mb-4 sm:flex sm:col-span-2">
                <h5
                  className={`text-xl md:text-2xl font-semibold mr-4 sm:pb-0 text-gray-700`}
                >
                  All Customers
                </h5>
              </div>
            </div>

            <Filters
              download={true}
              date={handleDateChange}
              downloadBtn={false}
              onSearchSubmit={handleSearchSubmit}
              placeHolder={"Customer Name"}
              onSearchReset={() => setSearch("")}
            />
            <div className={`${styles.table_ui_style} mt-5`}>
              <Table
                pending={loading}
                columns={columns}
                data={customers}
                showPointer={true}
                totalRows={totalRows}
                perPage={perPage}
                onRowClick={handleRowClick}
                onPageChange={handleOnPageChange}
                onRowPerPageChange={(currentRowsPerPage, currentPage) =>
                  handleRowsPerPageChange(currentRowsPerPage, currentPage)
                }
                sortIcon={
                  <svg
                    width="9"
                    height="12"
                    viewBox="0 0 9 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.5 1.5L4.5 10.5M4.5 10.5L8 7M4.5 10.5L1 7"
                      stroke="#064278"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                }
              />
            </div>
          </div>
        </>
      ) : (
        <Unauthorised />
      )}
    </CompanyLayout>
  );
}

export default Transactions;
