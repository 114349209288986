import React from "react";
import {logout} from "../logout";

export default function UnAuthenticated() {
  return (
    <>
      <div>
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none`}
        >
          <div className="relative mx-auto my-6 w-[90vw] md:w-96">
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-2xl outline-none focus:outline-none">
              <div className="relative flex-auto px-6 pt-8">
                <div className={`text-red-600`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-16 h-16 mx-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"
                    />
                  </svg>
                </div>
                <p className="mt-3 text-xl font-semibold leading-relaxed text-center text-blueGray-500">
                  Session Expired!
                </p>
                <p className="mt-2 text-sm leading-relaxed text-center text-gray-500 text-blueGray-500">
                  Please refresh your page or login back into your account.
                </p>
              </div>
              <div className="flex items-center justify-center px-6 pb-8 mt-5 border-solid rounded-b border-blueGray-200">
                <button
                  className="px-3 md:px-6 py-1.5 md:py-2 mb-1 mr-1 text-sm font-medium text-gray-600 transition-all duration-150 ease-linear rounded outline-none bg-slate-200 focus:outline-none"
                  type="button"
                  onClick={logout}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="fixed inset-0 z-40 backdrop-blur-sm bg-white/30" />
      </div>
    </>
  );
}
