import React from "react";
import Error from "../../Components/Global/widget/404";

export default function Unauthorised() {
  return (
    <div>
      <Error
        headLine={`Unauthorised`}
        message={`You dont have permission to visit this page. Please contact to your administrator`}
      />
    </div>
  );
}
