import React from "react";
import authorization from "../../Public/admin-images/authorization.png";

export default function AuthorisedForPayment({ message, headLine }) {
  return (
    <>
      <div className="bg-white rrrrrrrrrr relative    w-full">
        <div className="flex items-center py-5  justify-center">
          <div className="bg-white rounded-md flex items-center justify-center mx-4 md:w-2/3 ">
            <div className="flex flex-col items-center  ">
              <img className="px-4 max-h-80" src={authorization} alt="" />
              <h1 className="px-4 pt-8 pb-4 text-center text-5xl font-bold leading-10 text-gray-800">
                {headLine}{" "}
              </h1>
              <p className="px-4 pb-10 text-base text-center text-gray-600 leading-normal">
                {message}
              </p>
              <div className="flex items-center"></div>
            </div>
          </div>
          d
        </div>
      </div>
    </>
  );
}
