import React, { useEffect, useState } from "react";
import styles from "../../../Components/Scss/Admin.module.scss";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import CompanyLayout from "../../../Components/Company/Layouts";
import Filters from "../../../Components/Global/transaction/Filters";
import Table from "../../../Components/Global/Table";
import Unauthorised from "../../Global/Unauthorised";
import { HasPermission } from "../../../Components/Global/Permission";
import download from "downloadjs";
import Loader from "../../../Components/Global/widget/Loader";
import { roundUp } from "../../../Utils/Common";
import Axios from "../../../Config/AxiosInstance";
import convertToLocalTime from "../../../Utils/timeZoneConverter";

export default function Chargeback({ permission }) {
  const { token, selectedStoreIds } = useAuth();

  const [chargebacks, setChargebacks] = useState([]);

  const hiddenChart = true;

  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState("");
  const [datePick, setDatePick] = useState();
  const [to, setTo] = useState("");
  const perPage = 10;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState();

  const getChargebacks = async () => {
    let userType = "store";

    const data = await Axios(token).post(`/chargeback/list`, {
      s: search,
      days: "",
      from: from,
      to: to,
      size: perPage,
      type: userType,
      stores: currentStoreId,
      page: currentPage,
    });

    setChargebacks(data?.data?.response?.records);
    setTotalRows(data?.data?.response?.totalItems);
  };

  const columns = [
    {
      name: "Date Received",
      selector: (row) => convertToLocalTime(row?.createdAt).format("PPP"),
    },
    {
      name: "Store Name",
      selector: (row) => row?.store_name ?? "",
    },
    {
      name: "Customer Name",
      selector: (row) => row?.fullName ?? "",
    },
    {
      name: "Amount Disputed",
      selector: (row) => roundUp(row?.disputed_amount || 0).toFixed(2),
      style: {
        paddingTop: "0",
        paddingBottom: "0",
        div: {
          background: "rgba(5, 66, 120, 0.0509803922)",
          color: "#054278",
          fontWeight: "bolder",
          borderRadius: "1.5rem",
          padding: "5px 15px",
          minWidth: "75px",
        },
      },
    },
    // {
    //   name: "Reason",
    //   selector: (row) => row?.chargeback_category,
    // },
    {
      name: "Status",
      selector: (row) => row?.chargeback_status,
    },
  ];

  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);

  useEffect(() => {
    if (currentStoreId && token) getChargebacks().then();
  }, [currentStoreId, token, datePick, search, currentPage]);

  const handleSearchSubmit = async (val) => {
    setSearch(val);
  };

  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(
      convertToLocalTime(val[0])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTo(
      convertToLocalTime(val[1])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
  };

  const handleOnRowClick = (val) => {
    navigate(`/chargeback/${val.chargeback_external_id}`);
  };

  const quarries = {
    stores: currentStoreId,
    s: search,
    days: "",
    from: from,
    to: to,
    transactionFor: "",
    page: currentPage,
    size: perPage,
    type: "store",
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const data = await Axios(token).post(
        "/download/chargebacks/report",
        quarries,
        {
          responseType: "blob",
        },
      );
      setLoading(false);
      download(
        data.data,
        `Chargeback reports ${convertToLocalTime(new Date()).format(
          "MMMM Do YYYY",
        )}`,
      );
    } catch (e) {
      setLoading(false);
    }
  };

  const handlePageChange = (val) => {
    setCurrentPage(val);
  };

  const handleSearchReset = () => {
    setSearch("");
  };

  return (
    <>
      {loading && <Loader />}
      {HasPermission(permission) ? (
        <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
          <div
            className={`grid md:grid-cols-12 grid-cols-1 ${
              hiddenChart ? "mt-2" : "mt-2"
            }`}
          >
            <div
              className={`xl:col-span-12 md:col-span-12 ${styles.table_ui_style}`}
            >
              <Filters
                download={false}
                downloadBtn={true}
                paymentMenu={true}
                onDownload={handleDownload}
                date={handleDateChange}
                onSearchSubmit={handleSearchSubmit}
                onSearchReset={handleSearchReset}
                downloadBtnDisable={!chargebacks.length}
              />
              <div className={`mt-5`}>
                <Table
                  pending={loading}
                  columns={columns}
                  data={chargebacks}
                  totalRows={totalRows}
                  perPage={perPage}
                  onRowClick={handleOnRowClick}
                  onPageChange={handlePageChange}
                  showPointer
                />
              </div>
            </div>
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </>
  );
}
