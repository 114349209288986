const MB_1 = 1000000;

export const MAX_FILE_SIZE = 100 * MB_1;
export const MAX_FILE_NAME_LENGTH = 50;
export const AVAILABLE_FILE_EXTENSIONS = [
  '.pdf',
  '.docx',
  '.doc',
  '.jpg',
  '.jpeg',
  '.png',
  '.mp4',
  '.mov',
  '.wmv',
  '.avi',
  '.flv'
];
