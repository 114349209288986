import React, { Fragment, useState } from "react";
import styles from "../../Scss/Admin.module.scss";
import { useAuth } from "../../../Context/AuthContext";
import Modal from "../../Modal/modal";
import CompanyLayout from "../Layouts";
import { useNavigate } from "react-router-dom";
import Axios from "../../../Config/AxiosInstance";

export default function PasswordChange() {
  const [errorTypeModal, steErrorTypeModal] = useState("Error");
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const { token } = useAuth();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [error, setError] = useState("");
  const [state, setState] = useState({
    new_password: "",
    new_confirm_password: "",
    old_password: "",
  });
  const route = useNavigate();
  const { new_confirm_password, new_password, old_password } = state;
  const OnChangeHandel = ({ target }) => {
    setState({ ...state, [target.name]: target.value });
  };

  const modalErrorCallBack = (value) => {
    setIsErrorOpen(value);
    if (value === true) {
      route("/dashboard");
    }
  };
  const modalCloseCallBack = (value) => {
    setIsErrorOpen(value);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      old_password: old_password,
      new_password: new_password,
    };
    setButtonLoader(true);
    Axios(token)
      .post("/current/user/password/change", data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        if (response.status === 200) {
          setButtonLoader(false);
          steErrorTypeModal("Success");
          setIsErrorOpen(true);
          setState({
            old_password: "",
            new_password: "",
            new_confirm_password: "",
          });
          return setError(`Password has been changed`);
        } else if (response.status === 201) {
          setButtonLoader(false);
          steErrorTypeModal("Error");
          setIsErrorOpen(true);
          return setError(response.data.message);
        } else {
          setButtonLoader(false);
          steErrorTypeModal("Error");
          setIsErrorOpen(true);
          return setError(response.message);
        }
      })
      .catch(function (error) {
        setButtonLoader(false);
        steErrorTypeModal("Error");
        setIsErrorOpen(true);
        setState({
          old_password: "",
          new_password: "",
          new_confirm_password: "",
        });
        return setError(`${error}`);
      });
  };
  return (
    <>
      <CompanyLayout>
        <div className="grid px-3 lg:grid-cols-8 md:grid-cols-5 md:px-0">
          <div className="lg:col-span-4 lg:col-start-3 md:col-span-3 md:col-start-2">
            {error && (
              <Modal
                isModalOpen={isErrorOpen}
                showCancel={true}
                CloseOnClick={modalCloseCallBack}
                modalCallBack={modalErrorCallBack}
                type={errorTypeModal}
                message={error}
              />
            )}

            <Fragment>
              <form className="" onSubmit={submitHandler}>
                <div>
                  <div className="grid md:grid-cols-2 gap-3 md:gap-5 mt-5">
                    <h5
                      className={`text-xl md:text-2xl font-semibold mr-4 sm:pb-0 text-gray-700`}
                    >
                      Password change
                    </h5>
                    <div className="col-span-full">
                      <label className="block pb-1 text-sm text-gray-500">
                        Current Password
                      </label>
                      <input
                        type="password"
                        name={`old_password`}
                        id={"old_password"}
                        value={old_password}
                        onChange={OnChangeHandel}
                        className={`${styles.form_control}`}
                        placeholder="********"
                        required={true}
                      />
                    </div>

                    <div className="">
                      <label className="block pb-1 text-sm text-gray-500">
                        New Password
                      </label>
                      <input
                        type="password"
                        maxLength="25"
                        name={`new_password`}
                        id={"new_password"}
                        value={new_password}
                        onChange={OnChangeHandel}
                        className={`${styles.form_control}`}
                        placeholder="********"
                        required={true}
                      />
                    </div>

                    <div className="">
                      <label className="block pb-1 text-sm text-gray-500">
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        name={`new_confirm_password`}
                        id={"new_confirm_password"}
                        value={new_confirm_password}
                        onChange={OnChangeHandel}
                        className={`${styles.form_control}`}
                        placeholder="********"
                        required={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4 md:mt-7">
                  <button
                    type="submit"
                    className={`w-full flex items-center justify-center bg-green-500 text-white uppercase tracking-wider py-2 md:py-3 px-4 text-xs md:text-base hover:bg-green-700 transition-all disabled:bg-green-400 rounded-md`}
                  >
                    <span> Change password</span>
                    <span
                      className={` ${
                        buttonLoader ? "inline-block" : "hidden"
                      } w-5 h-5 ml-5 transition-all duration-300 delay-150 border-2 border-solid rounded-full border-white-400 border-t-transparent animate-spin`}
                    ></span>
                  </button>
                </div>
              </form>
            </Fragment>
          </div>
        </div>
      </CompanyLayout>
    </>
  );
}
