import React, { useEffect, useState } from "react";
import Unauthorised from "../../Global/Unauthorised";
import { HasPermission } from "../../../Components/Global/Permission";
import Loader from "../../../Components/Global/widget/Loader";
import CompanyLayout from "../../../Components/Company/Layouts";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import Axios from "../../../Config/AxiosInstance";

export default function BankStatement({ permission }) {
  const { store_id, id } = useParams();
  const { token } = useAuth();
  const [tableLoading, setTableLoading] = useState(false);
  const [pdf, setPdf] = useState("");

  useEffect(() => {
    setTableLoading(false);
    if (token) {
      getFindPDF().then();
    }
  }, [store_id, id]);

  const getFindPDF = async () => {
    setTableLoading(true);
    const fetchPDF = await Axios(token).post(
      `/bank-statement/preview-pdf`,
      {
        store_id,
        id,
      },
      {
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (fetchPDF.status === 200 && fetchPDF.data) {
      setPdf(fetchPDF.data.response);
      setTableLoading(false);
    }
  };

  return (
    <CompanyLayout>
      {tableLoading && <Loader />}
      {HasPermission(permission) ? (
        <>
          <div className="flex items-center justify-between">
            <h5 className={`text-xl md:text-2xl font-semibold flex-1 mr-5`}>
              View Statement
            </h5>
          </div>
          <div className={` mt-5`}>
            <div className={``}>
              <embed
                src={pdf}
                type="application/pdf"
                width="100%"
                style={{ height: "calc(100vh - 16.25rem)" }}
              />
            </div>
          </div>
        </>
      ) : (
        <Unauthorised />
      )}
    </CompanyLayout>
  );
}
