import React from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "./CustomTableStyle";
import LoadingTable from "./LoadingTable";
import NoData from "./widget/NoData";

const Table = ({
  columns,
  data,
  totalRows,
  onPageChange,
  showPointer,
  onRowClick,
  showPagination = true,
  perPage = 20,
  pending,
  conditionalRowStyles,
  handleSort,
  onRowPerPageChange,
  isNoRowsPerPage = false,
  ...props
}) => {
  const handlePageChange = (val) => {
    if (onPageChange) {
      onPageChange(val);
    }
  };
  const handleRowClicked = (val) => {
    if (onRowClick) {
      onRowClick(val);
    }
  };

  const handleRowPerPage = (currentRowsPerPage, currentPage) => {
    if (onRowPerPageChange) {
      onRowPerPageChange(currentRowsPerPage, currentPage);
    }
  };

  const handleSortColumn = (column, sortDirection) => {
    if (handleSort) {
      handleSort(column?.name, sortDirection);
    }
  };
  return (
    <>
      <DataTable
        className="pagenation"
        responsive={true}
        columns={columns}
        data={data}
        highlightOnHover={true}
        defaultSortAsc={false}
        striped={false}
        pagination={showPagination}
        paginationServer={true}
        paginationTotalRows={totalRows}
        customStyles={customStyles}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        onChangePage={handlePageChange}
        onRowClicked={handleRowClicked}
        pointerOnHover={showPointer}
        paginationComponentOptions={{ noRowsPerPage: isNoRowsPerPage }}
        progressPending={pending}
        progressComponent={<LoadingTable />}
        noDataComponent={<NoData />}
        conditionalRowStyles={conditionalRowStyles}
        onSort={handleSortColumn}
        onChangeRowsPerPage={handleRowPerPage}
        fixedHeader
        {...props}
      />
    </>
  );
};

export default Table;
