import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

function GradientChart({ data, name, height, yxisMax, yxisMin }) {
  const [state, setState] = useState();
  useEffect(() => {
    const state = {
      series: [
        {
          name: name,
          data: data,
        },
      ],
      options: {
        grid: {
          show: false,
        },
        chart: {
          height: height,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          type: "smooth",
          color: "#1c8e44",
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          logBase: 0,
          tickAmount: 0,
          min: yxisMin,
          max: yxisMax,
          forceNiceScale: true,
          floating: true,
          opposite: false,
          labels: {
            show: false,
          },
        },
        colors: ["#1c8e44"],
      },
    };
    setState(state);
  }, [data, yxisMax, yxisMin]);
  return (
    <div>
      {state && (
        <Chart
          type="line"
          options={state.options}
          series={state.series}
          height={height}
        />
      )}
    </div>
  );
}

export default GradientChart;
