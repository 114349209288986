import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ProfileDropdown from "../../Global/widget/profileDropdown";
import NavBar from "./NavBar";
import { Listbox } from "@headlessui/react";
import { useAuth } from "../../../Context/AuthContext";
import TerminalModal from "../../Global/widget/TerminalModal";
import Loader from "../../Global/widget/Loader";
import StoreModal from "../../Global/widget/StoreModal";
import QRCode from "qrcode";
import Modal from "../../../Components/Modal/modal";
import GainLogo from "../../Global/Gainlogo";
import Axios from "../../../Config/AxiosInstance";
import { isEmpty } from "lodash";
import { HasPermission } from "../../Global/Permission";
import StoreInfoModal from "../../Global/widget/StoreInfoModal";

const Header = ({ onStoreChange, isthisTranstionDetailsPage }) => {
  const {
    userInfo,
    storeId,
    createIsFormDisable,
    setCreateIsFormDisable,
    setStoreId,
    token,
    setIsAllStoreSelected,
    setCompanyId,
    companyId,
    isAllStoreSelected,
    selected,
    setSelected,
    setSelectedStoreIds,
    selectedStoreIds,
    setIsAssociateAuthenticated,
    setCurrentAssociatePin,
    setInTerminalPermissions,
    setInTerminalInfo,
  } = useAuth();

  const [terminalData, setTerminalData] = useState();
  const [loading, setLoading] = useState(false);
  const [userInfo_Store, setUserInfo_Store] = useState([]);
  const [openStoreModal, setOpenStoreModal] = useState(false);
  const [isQrOpen, setIsQrOpen] = useState(false);
  const [QRCodeData, setQRCodeData] = useState();
  const [openTerminalModal, setOpenTerminalModal] = useState(false);
  const [showStoreInfo, setShowStoreInfo] = useState(false);

  const newLocation = useLocation();
  const getLocation = newLocation.pathname.split("/");

  useEffect(() => {
    if (userInfo) {
      const allStores = userInfo.stores;
      allStores?.sort((a, b) => a.account_number - b.account_number);
      setUserInfo_Store(allStores);

      setIsAssociateAuthenticated(false);
      setInTerminalPermissions(null);
      setInTerminalInfo(null);
      setCurrentAssociatePin("");

      if (!selected) {
        if (allStores?.length > 1) {
          const storeArray = allStores.map((store) => store.id);
          setIsAllStoreSelected(true);
          setSelected("All");
          setSelectedStoreIds(storeArray);
        } else {
          allStores.length > 0 && setSelected(allStores[0]?.name);
          setSelectedStoreIds([allStores[0]?.id]);
          setIsAllStoreSelected(false);
        }
      }
    }

    setCreateIsFormDisable(
      selectedStoreIds?.length > 1 &&
        (getLocation[2] === "terminal" || getLocation[2] === "associate"),
    );
  }, [
    userInfo,
    selected,
    selectedStoreIds,
    getLocation,
    setCreateIsFormDisable,
    setIsAllStoreSelected,
    setSelected,
    setSelectedStoreIds,
    setIsAssociateAuthenticated,
    setInTerminalPermissions,
    setInTerminalInfo,
    setCurrentAssociatePin,
  ]);

  const handleStoreChange = (value) => {
    if (!isEmpty(value)) {
      const allStores = userInfo.stores;
      if (value === "all") {
        const storeArray = allStores.map((store) => store.id);
        setIsAllStoreSelected(true);
        setSelected("All");
        setSelectedStoreIds(storeArray);
      } else {
        const selectedStore = allStores.find((store) => store.id === value);
        setIsAllStoreSelected(false);
        setStoreId(value);
        setCompanyId("");
        setSelected(selectedStore?.name);
        setSelectedStoreIds([value]);
      }
      onStoreChange?.([value]);
    }
  };

  const TerminalModalOpen = () => {
    if (selectedStoreIds) {
      setLoading(true);
      Axios(token)
        .get(`/all-terminals/${selectedStoreIds}`)
        .then((response) => {
          setTerminalData(response.data?.response || []);
          setOpenTerminalModal(true);
        })
        .catch(() => {
          setTerminalData([]);
          setOpenTerminalModal(true);
        })
        .finally(() => setLoading(false));
    }
  };

  const onModalClick = (data) => setOpenTerminalModal(data);

  const handleShowStoreModel = () => setOpenStoreModal(!openStoreModal);

  const onSelectStore = async (val) => {
    handleStoreChange(val);
    handleShowStoreModel();
  };

  const getQrUrl = () => {
    const origin = window.location.origin;
    if (origin.includes("localhost")) {
      return `http://localhost:3331/qrpay/${selectedStoreIds}`;
    } else if (origin.includes("mirror-terminal")) {
      return `https://mirror-customer.gainfunds.com/qrpay/${selectedStoreIds}`;
    } else if (origin.includes("dev-terminal")) {
      return `https://dev-customer.gainfunds.com/qrpay/${selectedStoreIds}`;
    } else {
      return `https://gainfunds.com/qrpay/${selectedStoreIds}`;
    }
  };

  const handleShowQR = () => {
    setIsQrOpen(true);
    QRCode.toDataURL(getQrUrl(), { version: 10 })
      .then(setQRCodeData)
      .catch(console.error);
  };

  function handleStoreInfo() {
    setShowStoreInfo(true);
  }

  return (
    <>
      {loading && <Loader />}
      {openStoreModal && (
        <StoreModal
          show={openStoreModal}
          onStoreModalClose={handleShowStoreModel}
          onSelectStore={onSelectStore}
          data={userInfo_Store}
        />
      )}
      {openTerminalModal && (
        <TerminalModal
          show={openTerminalModal}
          onModalClick={onModalClick}
          data={terminalData}
        />
      )}
      {isQrOpen && (
        <Modal
          isModalOpen={isQrOpen}
          showOk
          modalCallBack={() => setIsQrOpen(false)}
          type="Scan QR code to proceed"
          message="Click on QR to download it"
          qrCode={QRCodeData}
        />
      )}
      {showStoreInfo && (
        <StoreInfoModal
          show={showStoreInfo}
          data={userInfo_Store}
          onStoreModalClose={() => setShowStoreInfo(false)}
        />
      )}
      <header className="shadow-sm flex-shrink-0 backdrop-blur-xl relative z-[99999] bg-white p-0 lg:px-5 lg:py-2">
        <div className="flex items-center justify-between px-2 lg:px-5 w-full">
          <div className="flex items-center space-x-3">
            <Link to="/dashboard" className="brandLogo">
              <GainLogo classes="w-24" />
            </Link>
            {!isthisTranstionDetailsPage && userInfo?.stores && (
              <Listbox
                value={
                  isAllStoreSelected
                    ? `company:${companyId}`
                    : `store:${storeId}`
                }
                onChange={handleStoreChange}
              >
                <div className="relative mt-1">
                  <Listbox.Button
                    className={`${
                      createIsFormDisable
                        ? "border-green-300 shadow-lg shadow-green-200"
                        : "border-gray-200"
                    } relative w-20 lg:w-auto md:w-auto py-2 pl-3 pr-10 border rounded text-left text-gray-500 font-semibold cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 text-sm md:text-sm`}
                    onClick={handleShowStoreModel}
                  >
                    <span className="block truncate">{selected}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none top-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </div>
              </Listbox>
            )}
            <div>
              <button
                className="bg-green-500 text-white hover:bg-green-700 rounded px-3 py-1.5 md:py-2 leading-5 transition-all text-xs"
                onClick={handleStoreInfo}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0 0 50 50"
                  className={`!text-white w-5`}
                  fill={`#fff`}
                >
                  <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="hidden xl:flex ml-auto pr-5">
            <NavBar />
          </div>
          <div className="relative z-[9] flex items-center space-x-3">
            <div className="flex items-center space-x-3">
              {[
                "terminal",
                "make_standard_payment",
                "make_contactless_payment",
                "make_all_payments",
              ].some(HasPermission) && (
                <div className="relative">
                  {isAllStoreSelected ? (
                    <button
                      className="bg-green-200 text-white rounded px-3 py-1.5 md:py-2 leading-5 transition-all text-xs"
                      disabled
                    >
                      Terminal
                    </button>
                  ) : (
                    <div className="gap-2 flex items-center">
                      <p
                        className="flex text-sm text-green-400 cursor-pointer"
                        onClick={handleShowQR}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-8 h-8"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
                          />
                        </svg>
                      </p>
                      <button
                        onClick={TerminalModalOpen}
                        className="bg-green-500 text-white hover:bg-green-700 rounded px-3 py-1.5 md:py-2 leading-5 transition-all text-xs"
                      >
                        Terminal
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <ProfileDropdown data={true} />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
