import React from "react";
import ErrorBigImage from "../../../Public/admin-images/undrawPageNotFound.png";
import UnauthorisedImage from "../../../Public/admin-images/Unauthorised.png";
import { Link } from "react-router-dom";
import GainLogo from "../Gainlogo";

const Error = ({
  message,
  headLine,
  browserButton = false,
  errorType = "404",
}) => {
  return (
    <div
      className={`bg-white ${
        errorType === "404" ? "relative" : "fixed h-screen z-50"
      }    w-full`}
    >
      {errorType === "404" && (
        <header
          className={` py-2  md:px-7 px-4 z-30 relative sm:fixed w-full top-0 pt-6`}
        >
          <div className="mx-auto container text-center">
            <div className="flex items-center justify-center">
              <Link to={"/"}>
                <GainLogo classes={`w-24`} />
              </Link>
            </div>
          </div>
        </header>
      )}
      <div
        className={`flex items-center ${
          errorType !== "404" ? "py-5" : " sm:h-screen"
        }  justify-center`}
      >
        <div className="bg-white rounded-md flex items-center justify-center mx-4 md:w-2/3 ">
          <div
            className={`flex flex-col items-center ${
              errorType !== "404" ? "" : "py-16"
            } `}
          >
            {headLine !== "404" ? (
              <img className="px-4" src={UnauthorisedImage} alt="" />
            ) : (
              <img className="px-4" src={ErrorBigImage} alt="" />
            )}
            <h1 className="px-4 pt-8 pb-4 text-center text-5xl font-bold leading-10 text-gray-800">
              {headLine ? headLine : "OOPS!"}{" "}
            </h1>
            <p className="px-4 pb-10 text-base  text-center text-gray-600 leading-normal">
              {message
                ? message
                : "No signal here! we cannot find the page you are looking for "}
            </p>
            <div className="flex items-center">
              {headLine === "404" && (
                <Link
                  to={"/"}
                  className="mx-1 text-center px-8 py-4 border rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 transition-all focus:ring-opacity-50 focus:ring-green-700"
                >
                  Go Back
                </Link>
              )}
              {browserButton && (
                <>
                  <a
                    href={`intent://${
                      window.location.host + window.location.pathname
                    }#Intent;scheme=https;package=com.android.chrome;end`}
                    className="mx-1 text-center py-4 border px-3 rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 transition-all focus:ring-opacity-50 focus:ring-green-700"
                  >
                    Go open on Chrome
                  </a>
                  <a
                    href={`intent://${
                      window.location.host + window.location.pathname
                    }#Intent;scheme=https;package=org.mozilla.firefox;end`}
                    className="mx-1 text-center py-4 px-3 border rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 transition-all focus:ring-opacity-50 focus:ring-green-700"
                  >
                    Go open on Firefox
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Error;
