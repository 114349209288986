import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import { isEmpty } from "lodash";
import Axios from "../../../Config/AxiosInstance";

export default function TerminalModal({ show, data, onModalClick }) {
  const [showModal, setShowModal] = useState(false);
  const { setTerminalInfo, token, setTerminalId, setUserInfo, userInfo } =
    useAuth();
  let history = useNavigate();
  useEffect(() => {
    setShowModal(show);
  }, [show]);
  const modalIsClose = (e) => {
    e.preventDefault();
    setShowModal(false);
    onModalClick(false);
  };
  const onTerminalClick = (id) => {
    if (id) {
      Axios(token)
        .post(
          `/auth/validate/terminal/${id}`,
          {},
          {
            headers: { Authorization: "Bearer " + token },
          },
        )
        .then((response) => {
          if (
            response.data &&
            response.data.response &&
            response.data.response.status === "valid"
          ) {
            setTerminalInfo(response.data.response);
            setTerminalId(id);
            setUserInfo({ ...userInfo, terminal: response.data.response });
            if (userInfo?.permissions.includes("make_standard_payment")) {
              history(`/terminal/standard`);
            } else if (
              userInfo?.permissions.includes("make_contactless_payment")
            ) {
              history(`/terminal/contactless/${id}`);
            }
            // contactless
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      alert("This terminal ");
    }
  };
  return (
    showModal && (
      <>
        <div>
          <div className="fixed inset-0 z-50 flex  overflow-x-hidden overflow-y-auto outline-none focus:outline-none mt-auto items-center">
            <div
              className={`relative mx-auto flex flex-col justify-center  lg:w-1/2 w-5/6 h-[80vh] ${
                data.lenght > 10 ? "top-5" : ""
              }`}
            >
              <div className="relative flex flex-col w-full p-4 mb-5 bg-white border-0 rounded-lg shadow-2xl outline-none focus:outline-none overflow-auto">
                <div className="flex justify-between pb-2 mb-2 border-b">
                  <p className="text-base text-gray-600">Terminal</p>
                  <button onClick={modalIsClose}>
                    <p className="text-gray-500 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </p>
                  </button>
                </div>
                <div className="">
                  {!isEmpty(data) ? (
                    <div
                      className={`${
                        data.length >= 2
                          ? "grid grid-cols-3 "
                          : "flex justify-center"
                      } gap-3`}
                    >
                      {data.map((value) => (
                        <div
                          key={value.id}
                          className={`rounded px-4 py-2 my-1 bg-slate-50 text-base cursor-pointer  col-span-3 md:col-span-1`}
                          onClick={() => onTerminalClick(value.id)}
                        >
                          <p>Name: {value.name}</p>
                          <p className="text-xs text-gray-400">
                            Locations: {value.location}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    "No Terminal Found!"
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 backdrop-blur-sm bg-white/30" />
        </div>
      </>
    )
  );
}
