import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import download from "downloadjs";

export default function TempPINPasswordModal({
  isModalOpen = false,
  onClose,
  data,
  isUpdate = false,
  type = "User",
}) {
  const [showModal, setShowModal] = useState(isModalOpen);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const route = useNavigate();

  function modalIsClose() {
    if (onClose) {
      setShowModal(false);
      onClose(true);
    }
    if (type === "Terminal") {
      route("/all-terminals");
    } else {
      route("/users");
    }
  }

  let makeDownload = "";
  if (data.fullName) {
    makeDownload += `Name: ${data.fullName}\n`;
  } else if (data.username) {
    makeDownload += `Username: ${data.username}\n`;
  }
  if (data.temp) {
    makeDownload += `Temporary password: ${data.temp}\n`;
  }
  if (data.tempp) {
    makeDownload += `Temporary PIN: ${data.tempp}\n`;
  }

  const HandleDownload = () => {
    console.log(data);
    download(makeDownload, "credentials.txt", "text/plain");
  };

  const HandleCopy = () => {
    navigator.clipboard.writeText(makeDownload).then();
    setIsLinkCopied(true);
  };
  setTimeout(() => {
    setIsLinkCopied(false);
  }, 10000);
  return (
    <>
      {showModal ? (
        <div className={`relative`}>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-[90vw] md:w-96">
              <button
                onClick={modalIsClose}
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "100px",
                  color: "#ffffff",
                }}
                className="flex items-center justify-center bg-green-500 absolute z-10 text-gray-400 top-3 right-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 text-sm"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-2xl outline-none focus:outline-none">
                <div className="relative flex-auto px-6 pt-8">
                  <div className={`text-green-600`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-16 h-16 mx-auto"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      />
                    </svg>
                  </div>
                  {/*<p className="mt-3 text-xl font-semibold leading-relaxed text-center text-blueGray-500">*/}
                  {/*    Success*/}
                  {/*</p>*/}
                  <p className="mt-2 text-sm leading-relaxed text-center text-gray-500 text-blueGray-500">
                    {type}{" "}
                    <span className={`font-bold`}>{data?.username} </span> has
                    been {isUpdate ? "updated" : "created"} Successfully.
                  </p>
                  {data?.temp && (
                    <p className="mt-2 text-sm leading-relaxed text-center text-gray-500 text-blueGray-500">
                      Temporary password is: {data.temp}
                    </p>
                  )}
                  {data?.tempp && (
                    <p className="mt-2 text-sm leading-relaxed text-center text-gray-500 text-blueGray-500">
                      Temporary PIN is: {data.tempp}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-center px-6 pb-8 mt-5 border-solid rounded-b border-blueGray-200">
                  {data?.temp && data.tempp && (
                    <>
                      <button
                        className={`${
                          isLinkCopied ? "bg-slate-400" : "bg-slate-200"
                        } px-6 py-2 mb-1 mr-1 text-sm font-medium text-gray-600 transition-all duration-150 ease-linear rounded outline-none focus:outline-none`}
                        type="button"
                        onClick={HandleCopy}
                      >
                        {" "}
                        {isLinkCopied ? "Copied!" : "Copy"}
                      </button>
                      <button
                        className="px-4 md:px-6 py-1.5 md:py-2 mb-1 mr-1 text-sm font-medium text-white transition-all duration-150 ease-linear bg-green-500 rounded outline-none focus:outline-none"
                        type="button"
                        onClick={HandleDownload}
                      >
                        {" "}
                        Download
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 backdrop-blur-sm bg-white/30" />
        </div>
      ) : null}
    </>
  );
}
