import React from "react";

export default function Loader({ zIndex = 10000 }) {
  return (
    <div
      className="p-5 bg-white/30 backdrop-blur-sm fixed w-full h-full flex justify-center items-center "
      style={{ top: 0, left: 0, right: 0, bottom: 0, zIndex: zIndex }}
    >
      <div>
        <div
          className={`w-8 transition-all ml-5 h-8 border-2 border-gray-800 border-t-transparent delay-150 duration-300 border-solid rounded-full animate-spin`}
        />
        <p className="text-base mt-4">
          Loading
          <span className="animate-ping opacity-75 pl-1">.</span>
          <span className="animate-ping opacity-75">.</span>
          <span className="animate-ping opacity-75">.</span>
          <span className="animate-ping opacity-75">.</span>
        </p>
      </div>
    </div>
  );
}
