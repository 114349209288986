import React, { useCallback, useEffect, useState } from "react";
import styles from "../../Components/Scss/Admin.module.scss";
import Table from "../../Components/Global/Table";
import { useAuth } from "../../Context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/Global/widget/Loader";
import CompanyLayout from "../../Components/Company/Layouts";
import Filters from "../../Components/Global/transaction/Filters";
import { toNumber } from "lodash";
import { HasPermission } from "../../Components/Global/Permission";
import Unauthorised from "../Global/Unauthorised";
import { currencyFormat } from "../../Utils/Common";
import download from "downloadjs";
import Axios from "../../Config/AxiosInstance";
import convertToLocalTime from "../../Utils/timeZoneConverter";

const COLUMNS = [
  {
    name: "#",
    selector: (row) => row.serialNo,
    width: "50px",
  },
  {
    name: "Date",
    selector: (row) => row.date,
  },
  // {
  //   name: "Account No",
  //   selector: (row) => row.storeName,
  //   wrap: true,
  // },

  {
    name: "Store Name",
    selector: (row) => row?.storeName,
    sortable: true,
    sortField: "storeName",
  },
  {
    name: "Employee Name",
    selector: (row) => row.associate,
    wrap: true,
  },
  {
    name: "Order Amount",
    selector: (row) => row.orderAmount,
  },
  {
    name: "Payment Type",
    selector: (row) => row.paymentType,
    wrap: true,
  },
];

export default function CustomerDetails({ permission }) {
  const { userInfo, token, selectedStoreIds } = useAuth();
  const [amount, setAmount] = useState();
  const [tips, setTips] = useState();
  const [refund, setRefund] = useState();
  const [salesAmount, setSalesAmount] = useState();
  const [salesPlusTips, setSalesPlusTips] = useState();
  const [noOfTransactions, setNoOfTransactions] = useState();
  const [hiddenChart, setHiddenChart] = useState(true);
  const [customer, setCustomer] = useState("");
  const [allTransactionData, setAllTransactionData] = useState([]);
  const [datePick, setDatePick] = useState();
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [totalRows, setTotalRows] = useState();
  const perPage = 10;
  const [search, setSearch] = useState("");
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [currentPage, setCurrentPage] = useState(1);
  const [resApiData, setResApiData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const getCustomerId = location.pathname.split("/").slice(-1);
  const getTransactions = async () => {
    let userType = "store";
    setTableLoading(true);
    const data = await Axios(token).post(
      `/customer/transctions/list/${getCustomerId}`,
      {
        s: search,
        days: "",
        from: from,
        to: to,
        size: perPage,
        page: currentPage,
        type: userType,
        stores: currentStoreId,
      },
    );
    if (data && data.data && data.data.response && data.data.response.records) {
      const { records, totalItems, currentPage, size } = data.data.response;
      filterData(records, (currentPage - 1) * size);
      setTotalRows(totalItems);
    }
    setTableLoading(false);
  };
  useEffect(() => {
    setLoading(false);
    //ref.current.continuousStart();
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);
  useEffect(() => {
    if (token && currentStoreId) {
      getTransactions().then();
      let userType = "store";
      setResApiData(false);
      Axios(token)
        .post(`/customer/transctions/${getCustomerId}`, {
          type: userType,
          from: from,
          to: to,
          s: search,
          stores: currentStoreId,
        })
        .then((response) => {
          if (response.data.response) {
            //ref.current.complete();
            setResApiData(true);
            const transactionsAmountCount = currencyFormat(
              toNumber(response.data.response.metabox.total_order_amount),
            );
            const tipsAmountCount = currencyFormat(
              toNumber(response.data.response.metabox.totaltips),
            );
            const refundAmountCount = currencyFormat(
              toNumber(response.data.response.metabox.returns),
            );
            setNoOfTransactions(
              response.data.response.metabox.noOfTransactions,
            );
            setSalesAmount(
              currencyFormat(response.data.response.metabox.salesAmount),
            );

            setSalesPlusTips(
              currencyFormat(response.data.response.metabox.salesPlusTips),
            );
            const getCustomerName = response.data.response.response.fullName;

            setCustomer(getCustomerName);
            setAmount(transactionsAmountCount);
            setTips(tipsAmountCount);
            setRefund(refundAmountCount);
          } else {
            setResApiData(true);
          }
        })
        .catch(() => {
          setResApiData(true);
        });
    }
  }, [token, datePick, search, currentStoreId, currentPage]);

  const filterData = (data, offset) => {
    const records = data.map((items, index) => {
      return {
        serialNo: index + offset + 1,
        date: convertToLocalTime(items?.transaction.createdAt).format("PPp"),
        storeName: items?.transaction?.Store?.store_name,
        associate:
          items?.associate?.first_name + " " + items?.associate?.last_name,
        orderAmount: currencyFormat(toNumber(items?.transaction?.order_amount)),
        paymentType: items?.transaction?.payment_type,
        external_id: items?.transaction?.transactions_external_id,
      };
    });

    setAllTransactionData(records);
  };
  const handleSearchSubmit = async (val) => {
    if (val) {
      setSearch(val);
    }
  };
  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(
      convertToLocalTime(val[0])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTo(
      convertToLocalTime(val[1])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
  };
  const handleRowClick = useCallback((val) => {
    navigate(`/transaction/${val.external_id}`);
  }, []);
  const handleOnPageChange = useCallback((val) => {
    if (val) {
      setCurrentPage(val);
    }
  }, []);
  const quarries = {
    stores: currentStoreId,
    customer_external_id: getCustomerId,
    from: from,
    to: to,
  };
  const handleDownload = async () => {
    try {
      setLoading(true);
      const data = await Axios(token).post(
        "/download/transaction/report/customer",
        quarries,
        {
          responseType: "blob",
        },
      );
      setLoading(false);
      download(
        data.data,
        `Customer transactions reports ${convertToLocalTime(new Date()).format(
          "MMMM Do YYYY",
        )}`,
      );
    } catch (e) {
      setLoading(false);
    }
  };
  const HandelHiddenChart = (e) => {
    const hiddenChartVal = e.target.checked;
    setHiddenChart(hiddenChartVal);
  };

  return (
    <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
      {loading && <Loader />}
      {HasPermission(permission) ? (
        <>
          <div>
            {resApiData ? (
              <div className="px-5 pt-4 bg-slate-100 rounded-xl">
                <div className="flex flex-wrap items-center justify-between pb-4">
                  <div>
                    <p className="text-xl md:text-3xl font-bold text-gray-700 capitalize">
                      {customer ? customer.toLowerCase() : ""}
                    </p>
                    <p className="mt-2 mb-2 text-sm font-semibold text-gray-500">
                      Registration Date & Time:{" "}
                      <span className="text-gray-700 border-gray-400">
                        {convertToLocalTime(userInfo.createdAt).format("PPp")}
                      </span>
                    </p>
                  </div>
                  <div className="flex item-center">
                    <div className="flex items-center mx-3">
                      <p className="pr-2 text-gray-600">
                        {hiddenChart ? "Disable" : "Enable"} Chart
                      </p>

                      <div className={`${styles.switch_box}`}>
                        <input
                          type="checkbox"
                          id="HiddenChartId"
                          defaultChecked={hiddenChart}
                          name="HiddenChartId"
                          onChange={(e) => HandelHiddenChart(e)}
                          className={` ${styles.input_switch}`}
                        />
                        <label htmlFor="HiddenChartId" className={`m-0`} />
                        <div className={` ${styles.switch_status_box}`}></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  {hiddenChart && (
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-3 pt-4 pb-4 border-t">
                      <div className="flex items-center p-3 bg-white rounded">
                        <div className="ml-3">
                          <p className="text-xs tracking-wide text-gray-500">
                            Sales Amount:
                          </p>
                          <p className="mt-1 text-base font-semibold text-gray-700">
                            {salesAmount}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center p-3 bg-white rounded">
                        <div className="ml-3">
                          <p className="text-xs tracking-wide text-gray-500">
                            Tips:
                          </p>
                          <p className="mt-1 text-base font-semibold text-gray-700">
                            {tips}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center p-3 bg-white rounded">
                        <div className="ml-3">
                          <p className="text-xs tracking-wide text-gray-500">
                            Sales Amount + Tips:
                          </p>
                          <p className="mt-1 text-base font-semibold text-gray-700">
                            {salesPlusTips}
                          </p>
                        </div>
                      </div>

                      <div className="flex items-center p-3 bg-white rounded">
                        <div className="ml-3">
                          <p className="text-xs tracking-wide text-gray-500">
                            Total Processed:
                          </p>
                          <p className="mt-1 text-base font-semibold text-gray-700">
                            {amount}
                          </p>
                        </div>
                      </div>

                      <div className="flex items-center p-3 bg-white rounded">
                        <div className="ml-3">
                          <p className="text-xs tracking-wide text-gray-500">
                            Number of Transactions:
                          </p>
                          <p className="mt-1 text-base font-semibold text-gray-700">
                            {" "}
                            {noOfTransactions}
                          </p>
                        </div>
                      </div>

                      <div className="flex items-center p-3 bg-white rounded">
                        <div className="ml-3">
                          <p className="text-xs tracking-wide text-gray-500">
                            Returns:
                          </p>
                          <p className="mt-1 text-base font-semibold text-gray-700">
                            {" "}
                            {refund}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="px-5 pt-4 bg-slate-100 rounded-xl animate-pulse">
                <div className="flex items-center justify-between pb-4 border-b">
                  <div>
                    <p className="h-6 rounded bg-slate-300 w-36 "></p>
                    <p className="h-3 mt-2 rounded bg-slate-300 w-60"></p>
                  </div>
                  <div>
                    <div className="inline-flex px-10 py-4 rounded bg-slate-300"></div>
                  </div>
                </div>

                <div className="grid grid-cols-4 gap-3 pt-4 pb-4 border-t">
                  {Array.from(Array(6), (e, i) => {
                    return (
                      <div
                        key={i}
                        className="flex items-center p-3 rounded bg-white/75"
                      >
                        {/* <div className="w-12 h-12 p-2 bg-slate-200 rounded-xl"></div> */}
                        <div className="ml-3">
                          <p className="w-20 h-2 rounded bg-slate-200 "></p>
                          <p className="w-10 h-4 mt-2 rounded bg-slate-200"></p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="items-center pt-7">
              <h5 className="mt-4 md:mt-8 mb-4 text-xl font-semibold text-gray-700">
                All Transaction
              </h5>
              <div className="">
                <Filters
                  placeHolder={"Search"}
                  enableSearch={false}
                  download={true}
                  onDownload={handleDownload}
                  downloadBtn={true}
                  date={handleDateChange}
                  onSearchSubmit={handleSearchSubmit}
                  onSearchReset={() => setSearch("")}
                  downloadBtnDisable={!allTransactionData.length}
                />
              </div>
            </div>
            <div>
              <div className={`mt-5  ${styles.table_ui_style}`}>
                <Table
                  pending={tableLoading}
                  data={allTransactionData}
                  columns={COLUMNS}
                  totalRows={totalRows}
                  showPointer
                  perPage={perPage}
                  onRowClick={handleRowClick}
                  onPageChange={handleOnPageChange}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Unauthorised />
      )}
    </CompanyLayout>
  );
}
