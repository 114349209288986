import React, { useEffect, useState } from "react";
import CompanyLayout from "../../../Components/Company/Layouts";
import styles from "../../../Components/Scss/Admin.module.scss";
import IconImage from "../../../Public/admin-images/form-icon-image.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import Modal from "../../../Components/Modal/modal";
import Loader from "../../../Components/Global/widget/Loader";
import { HasPermission } from "../../../Components/Global/Permission";
import Unauthorised from "../../Global/Unauthorised";
import { trimStart } from "lodash";
import TempPINPasswordModal from "../../../Components/Modal/TempPINPasswordModal";
import Axios from "../../../Config/AxiosInstance";

export default function EditTerminal({ permission }) {
  const { selectedStoreIds, token, createIsFormDisable } = useAuth();

  const [updatePassword, setUpdatePassword] = useState(false);
  const route = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const newLocation = useLocation();
  const getLocation = newLocation.pathname.split("/")[2];
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [data, setData] = useState();
  const [state, setState] = useState({
    username: "",
    name: "",
    location: "",
    confirm_pin: "",
    pin: "",
    confirmPassword: "",
    password: "",
  });
  const { name, location, username } = state;

  useEffect(() => {
    Axios(token)
      .get(`/terminal/edit/${getLocation}`)
      .then(function (response) {
        const getData = response.data.response;
        setState({
          terminal_external_id: getData.terminal_external_id,
          username: getData?.User?.username,
          name: getData?.name,
          location: getData?.location,
          confirm_pin: "",
          pin: "",
          confirmPassword: "",
          password: "",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedStoreIds]);
  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      terminal_external_id: getLocation,
      name: name,
      username: username,
      location: location,
      password: updatePassword,
    };
    setLoading(true);
    Axios(token)
      .post("/terminal/update", data)
      .then(function (response) {
        setLoading(false);
        if (response?.data?.status && response.data?.temp) {
          setConfirmationModal(true);
          setData(response.data);
        } else {
          route(`/all-terminals`);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setError(error?.response?.data?.message || "Something went wrong");
        setIsModalOpen(true);
      });
  };

  const HandelUpdatePassword = (e) => {
    const hiddenChartVal = e.target.checked;
    setUpdatePassword(hiddenChartVal);
  };

  return (
    <>
      {HasPermission(permission) ? (
        <CompanyLayout>
          {loading && <Loader />}
          {isModalOpen && (
            <Modal
              isModalOpen={isModalOpen}
              type={"Error"}
              message={error}
              showOk={true}
              modalCallBack={() => setIsModalOpen(false)}
            />
          )}
          {confirmationModal && data && (
            <TempPINPasswordModal
              data={data}
              isUpdate={true}
              isModalOpen={confirmationModal}
              type={`Terminal`}
            />
          )}

          <div className="md:max-w-[75vw] mt-4 mx-auto">
            <div className="md:pl-8 xl:col-span-10 lg:col-span-9 md:col-span-8">
              <div className="relative md:pb-6 lg:items-center lg:flex-row">
                {createIsFormDisable && (
                  <div className="absolute bottom-0 left-0 right-0 z-10 flex justify-center bg-white/30 -top-3 backdrop-blur-sm">
                    <div className="pt-24 text-center text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mx-auto h-14 w-14 "
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={1}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                      <span className="relative block pt-2 text-lg text-gray-700">
                        Please select a store to edit terminal!
                      </span>
                    </div>
                  </div>
                )}

                <div className={`${styles.form_card} md:px-5 md:pt-0 pt-5`}>
                  <div className="">
                    <div className={` flex items-center`}>
                      <div
                        className={`w-16 h-16 p-4 rounded-full bg-yellow-400`}
                      >
                        <img src={IconImage} alt="" />
                      </div>
                      <div className={`pl-4`}>
                        <p className="text-lg font-semibold">
                          Edit {state?.name}
                        </p>
                        <p className={`text-gray-600 text-sm`}>
                          To edit an account please enter the information.
                        </p>
                      </div>
                    </div>

                    <div className="pt-7">
                      {state && (
                        <form
                          onSubmit={submitHandler}
                          className="grid grid-cols-2 gap-3 md:gap-4 md:grid-cols-3"
                        >
                          <div className="pb-2 text-sm font-semibold text-gray-600 border-b col-span-full">
                            Terminal Information
                          </div>
                          <div className="col-span-full">
                            <div className="grid grid-cols-2 gap-4 pt-3 lg:grid-cols-3">
                              <div className="">
                                <input
                                  type="text"
                                  name={`name`}
                                  id={"name"}
                                  value={trimStart(state.name)}
                                  className={`${styles.form_control}`}
                                  placeholder="Device Name"
                                  onChange={(e) =>
                                    setState({ ...state, name: e.target.value })
                                  }
                                />
                              </div>

                              <div className="">
                                <input
                                  type="text"
                                  name={`location`}
                                  id={"location"}
                                  value={trimStart(state.location)}
                                  className={`${styles.form_control}`}
                                  placeholder="Location"
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      location: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="">
                                <input
                                  type="text"
                                  disabled={true}
                                  value={trimStart(state.username)}
                                  className={`${styles.form_control}`}
                                  placeholder="Username"
                                  style={{ backgroundColor: "#f0f0f0" }}
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      username: e.target.value,
                                    })
                                  }
                                />
                              </div>

                              <div className=" mt-3 col-span-full flex items-center pb-1.5">
                                <div className="text-sm font-semibold text-gray-600">
                                  Update Password
                                </div>

                                <div className={`${styles.switch_box} ml-6`}>
                                  <input
                                    type="checkbox"
                                    id="updatePassword"
                                    defaultChecked={updatePassword}
                                    name="updatePassword"
                                    onChange={(e) => HandelUpdatePassword(e)}
                                    className={` ${styles.input_switch}`}
                                  />
                                  <label
                                    htmlFor="updatePassword"
                                    className={``}
                                  />
                                  <div
                                    className={` ${styles.switch_status_box}`}
                                  ></div>
                                </div>
                              </div>

                              {/*{updatePassword && (*/}
                              {/*    <div className="col-span-full">*/}
                              {/*      <div className="grid grid-cols-2 gap-3 md:gap-4 md:grid-cols-3">*/}
                              {/*        <div className="">*/}
                              {/*          <input*/}
                              {/*              type="password"*/}
                              {/*              name={`password`}*/}
                              {/*              id={"password"}*/}
                              {/*              value={state.password}*/}
                              {/*              className={`${styles.form_control}`}*/}
                              {/*              placeholder="Password"*/}
                              {/*
                              {/*          />*/}
                              {/*          <p className="text-danger" style={{color: "red"}}>*/}
                              {/*          </p>*/}
                              {/*        </div>*/}
                              {/*        <div className="">*/}
                              {/*          <input*/}
                              {/*              type="password"*/}
                              {/*              name={`confirmPassword`}*/}
                              {/*              id={"confirmPassword"}*/}
                              {/*              value={state.confirmPassword}*/}
                              {/*              className={`${styles.form_control}`}*/}

                              {/*              placeholder="Confirm Password"*/}
                              {/*
                              {/*          />*/}
                              {/*          <p className="text-danger" style={{color: "red"}}>*/}
                              {/*          </p>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*)}*/}
                            </div>
                          </div>

                          <div className="pt-4 col-span-full">
                            <button
                              type="submit"
                              className={`w-full bg-green-500 text-white uppercase tracking-wider font-semibold rounded py-3 px-4 text-base hover:bg-green-700 transition-all`}
                            >
                              Update Terminal
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </>
  );
}
