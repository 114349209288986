import React, { useEffect, useState } from "react";
import styles from "../../../Components/Scss/Admin.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import Table from "../../../Components/Global/Table";
import CompanyLayout from "../../../Components/Company/Layouts";
import Filters from "../../../Components/Global/transaction/Filters";
import { HasPermission } from "../../../Components/Global/Permission";
import Unauthorised from "../../Global/Unauthorised";
import _, { capitalize } from "lodash";
import validator from "validator";
import { currencyFormat, roundUp } from "../../../Utils/Common";
import Axios from "../../../Config/AxiosInstance";
import convertToLocalTime from "../../../Utils/timeZoneConverter";

function Users({ permission }) {
  const { selectedStoreIds, token, selectedDateList } = useAuth();
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState(selectedDateList[0]);
  const [to, setTo] = useState(selectedDateList[1]);
  const [datePick, setDatePick] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState();
  const [tableLoading, setTableLoading] = useState(true);
  const [role, setRole] = useState("");
  const [userStatus, setUserStatus] = useState("");

  const navigate = useNavigate();

  const getTransactions = async () => {
    setTableLoading(true);
    try {
      let userType = "store";
      const data = await Axios(token).post(`/transactions`, {
        s: search,
        days: "",
        from: from,
        to: to,
        page: currentPage,
        size: perPage,
        type: userType,
        stores: selectedStoreIds,
        role: role || ["all"],
        userStatus,
      });
      if (data?.data?.response) {
        filterData(data.data.response);
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    } catch (e) {
      setTableLoading(false);
      setUsers([]);
      setTotalRows(null);
    }
  };

  const filterData = (data) => {
    const userRecords = [];
    const responseData = data?.records;
    for (let userItem of responseData) {
      userRecords.push({
        name: userItem?.fullName,
        date: convertToLocalTime(userItem.date).format("pp"),
        userName: !validator.isUUID(userItem?.userName)
          ? userItem?.userName
          : "-",
        userID: userItem?.userID,
        tip: currencyFormat(roundUp(userItem?.tip || 0)),
        refund: roundUp(userItem?.refundAmount || 0),
        amount: currencyFormat(roundUp(userItem?.orderAmount || 0)),
        totalAmount: currencyFormat(roundUp(userItem?.totalAmount || 0)),
        totalRefund: currencyFormat(roundUp(userItem?.refundAmount || 0)),
        store: capitalize(userItem?.store) || "All Stores",
        role: capitalize(userItem?.role),
      });
    }

    setUsers(userRecords);
    setTotalRows(data.totalItems);
  };
  useEffect(() => {
    if (selectedStoreIds && token) {
      getTransactions().then();
    }
  }, [
    token,
    datePick,
    search,
    currentPage,
    selectedStoreIds,
    role,
    perPage,
    userStatus,
  ]);
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Username",
      selector: (row) => row.userName,
    },
    {
      name: "Store Name",
      selector: (row) => _.startCase(_.toLower(row?.store || "")),
    },
    {
      name: "Role",
      selector: (row) => row.role,
    },
    {
      name: "Sales Amount",
      selector: (row) => row?.amount,
      style: {
        paddingTop: "0",
        paddingBottom: "0",
        div: {
          background: "rgba(5, 66, 120, 0.0509803922)",
          color: "#054278",
          fontWeight: "bolder",
          borderRadius: "1.5rem",
          padding: "5px 15px",
        },
      },
      sortable: true,
    },
    {
      name: "Tip",
      selector: (row) => row?.tip,
      sortable: true,
    },
    {
      name: "Sales + Tip",
      selector: (row) => row?.totalAmount,
    },
  ];
  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(
      convertToLocalTime(val[0])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTo(
      convertToLocalTime(val[1])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(currentPage);
  };

  return (
    <>
      {HasPermission(permission) ? (
        <CompanyLayout>
          <div className="flex flex-wrap items-center justify-between mb-4">
            <div className="items-center sm:flex sm:col-span-2">
              <h5
                className={`text-2xl whitespace-nowrap font-semibold mr-4 pb-2 sm:pb-0 text-gray-700`}
              >
                All Users
              </h5>
            </div>

            {HasPermission(["create_people"]) && (
              <div className="flex items-center justify-between">
                <Link
                  to={"/register/user"}
                  className={`px-2 py-2.5 md:px-5 text-xs md:text-sm text-white flex items-center rounded bg-green-500 focus:bg-green-700 hover:bg-green-700 right-0 top-0 border-0 outline-none`}
                >
                  Register User
                </Link>
              </div>
            )}
          </div>
          <div className={`mt-3`}>
            <div className={`grid md:grid-cols-12 grid-cols-1`}>
              {selectedStoreIds && (
                <div className={`xl:col-span-12 md:col-span-12 mt-2 md:mt-5`}>
                  <Filters
                    placeHolder={"Search User"}
                    download={false}
                    downloadBtn={false}
                    date={handleDateChange}
                    onSearchSubmit={(val) => setSearch(val)}
                    onSearchReset={() => setSearch("")}
                    roleDropdown={true}
                    userStatusDropDown={true}
                    stores={selectedStoreIds}
                    onRoleChange={(val) => setRole(val)}
                    onUserStatusChange={(val) => setUserStatus(val)}
                  />
                </div>
              )}
              <div
                className={`xl:col-span-12 md:col-span-12 mt-5 ${styles.table_ui_style}`}
              >
                <Table
                  perPage={perPage}
                  data={users}
                  columns={columns}
                  showPointer={true}
                  totalRows={totalRows}
                  onRowClick={(val) => navigate(`/user/${val.userID}`)}
                  pending={tableLoading}
                  onPageChange={(val) => setCurrentPage(val)}
                  onRowPerPageChange={(currentRowsPerPage, currentPage) =>
                    handleRowsPerPageChange(currentRowsPerPage, currentPage)
                  }
                />
              </div>
            </div>
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </>
  );
}

export default Users;
