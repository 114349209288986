import React, { Fragment } from "react";
// import { useAuth } from "../../../Context/AuthContext";
import { Popover, Transition } from "@headlessui/react";

import { capitalize, isEmpty, toLower } from "lodash";
import GradientChart from "./charts/GradientChart";
import { slugify } from "../../../Utils/Common";

function LinechartCard({
  onMenuClick,
  // startDateTime,
  // endDateTime,
  data,
  name,
  price,
  hasMenu,
  menu,
  height,
  color,
  type,
}) {
  return (
    <div
      className={` ${
        data ? "" : "flex flex-col justify-between"
      } transition-all bg-white border rounded-lg shadow  shadow-slate-50 border-slate-100 hover:shadow-xl hover:shadow-slate-100 min-h-[160px]`}
    >
      <div className="flex justify-between px-4 pt-3">
        <div>
          <p className="flex pb-1 text-xs text-gray-500">
            <span className="opacity-80">{name} </span>
          </p>
          <h2 className="text-2xl font-semibold text-gray-700">
            {price ? price : 0}
          </h2>
        </div>
        {hasMenu && (
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={` ${
                    open ? "text-green-500" : "text-gray-400"
                  }  p-2  hover:text-green-500 bg-gray-50 rounded-lg focus:outline-none`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                    />
                  </svg>
                </Popover.Button>

                <Transition as={Fragment}>
                  <Popover.Panel className="absolute z-10  w-40 px-4 mt-1 transform right-0 sm:px-0 lg:max-w-3xl">
                    <div className="overflow-hidden rounded-lg shadow-lg ">
                      <div className="p-4 bg-white">
                        {hasMenu &&
                          menu &&
                          menu.map((menu, index) => (
                            <Popover.Button
                              value={toLower(menu)}
                              onClick={(e) =>
                                onMenuClick ? onMenuClick(e.target.value) : null
                              }
                              name={toLower(slugify(name))}
                              key={index}
                              style={{ width: "100%", textAlign: "left" }}
                              className="flow-root w-100 text-gray-500 px-2 py-2 rounded-md hover:bg-gray-100 hover:text-green-600"
                            >
                              {capitalize(menu)}
                            </Popover.Button>
                          ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        )}
      </div>
      {data && (
        <GradientChart
          height={height}
          color={color}
          name={name}
          data={data}
          type={type}
          yxisMax={
            !isEmpty(data) && data.length > 0
              ? Math.max(...data) //toNumber(data[data.length - 1][1])
              : 0
          }
          yxisMin={!isEmpty(data) && data.length > 0 ? -Math.min(...data) : 0}
        />
      )}

      {/*<div*/}
      {/*  className={`flex justify-between text-xs text-gray-400 relative border-t border-slate-100 p-2`}*/}
      {/*>*/}
      {/*  /!*<small>{startDate ? startDate : ""}</small>*!/*/}
      {/*  /!*<small>{endDate ? endDate : ""}</small>*!/*/}
      {/*</div>*/}
    </div>
  );
}

export default LinechartCard;
