const getEnvironmentConfig = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return {
        apiUrl: "https://api.gainfunds.com",
        publicUrl: "https://app.gainfunds.com",
        enableServiceWorker: true,
      };
    case "staging":
      return {
        apiUrl: "https://staging-api.gainfunds.com",
        publicUrl: "https://staging-terminal.gainfunds.com",
        enableServiceWorker: true,
      };
    case "development":
      return {
        apiUrl: "https://dev-api.gainfunds.com",
        publicUrl: "https://mirror-dev.gainfunds.com",
        enableServiceWorker: true,
      };
    case "mirror":
      return {
        apiUrl: "https://mirror-api.gainfunds.com",
        publicUrl: "https://mirror-terminal.gainfunds.com",
        enableServiceWorker: true,
      };
    default:
      return {
        apiUrl: "http://localhost:8888",
        publicUrl: "http://localhost:3000",
        enableServiceWorker: true,
      };
  }
};

export const environmentConfig = getEnvironmentConfig();
