import React from "react";
import AppRouter from "./Router/AppRouter";
import "./App.scss";
import UpdateNotification from "./UpdateNotification";

function App() {
  return (
    <>
      <UpdateNotification />
      <AppRouter />
    </>
  );
}

export default App;
