import React, { useRef, useState } from "react";
import style from "../Scss/Admin.module.scss";
import layout from "../Scss/layout.module.scss";

import { trim } from "lodash";

export default function Search({
  OnReset,
  OnInput,
  OnSubmit,
  placeHolder,
  // isDashboard = false,
}) {
  const searchInput = useRef(null);
  const [showResetButton, setShowResetButton] = useState(false);
  const resetHandler = () => {
    if (OnReset) {
      OnReset("");
    }
    if (OnInput) {
      OnInput("");
    }
    searchInput.current.value = "";
    setShowResetButton(false);
  };
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (OnSubmit) {
      if (trim(searchInput.current.value)) {
        OnSubmit(trim(searchInput.current.value));
        setShowResetButton(true);
      }
    }
  };
  const onChangeHandler = ({ target }) => {
    if (OnInput) {
      if (trim(target.value)) {
        OnInput(target.value);
      } else {
        setShowResetButton(false);
        OnInput("");
        OnReset("");
      }
    }
  };
  return (
    <form
      onSubmit={handleSearchSubmit}
      // className="md:mr-0 md:ml-2 w-full mt-2 md:mt-0 md:w-auto"
      className={`${layout.tableFilterSearch}`}
    >
      <label
        className={`flex border border-gray-200  min-w-[200px]`}
        htmlFor="search"
      >
        <input
          name={"search"}
          ref={searchInput}
          onChange={onChangeHandler}
          placeholder={placeHolder}
          type="text"
          className={`${style.search_input} font-normal  text-sm p-2.5 pr-3 text-gray-600 w-full h-10 block rounded  bg-transparent focus:border-green-600 placeholder-gray-500`}
        />
        {showResetButton && (
          <button
            onClick={resetHandler}
            type="reset"
            className={`reset bg-white px-2 color-black`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 h-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={1}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
      </label>
      <button
        className={`px-3 text-white rounded-r font-semibold bg-green-500 focus:bg-green-700 hover:bg-green-700 right-0 top-0 border-0 outline-none`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
    </form>
  );
}
