import React, {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import Loader from '../Components/Global/widget/Loader';

import PublicRoutes from './PublicRoutes';
import CompanyRoutes from './CompanyRoutes';
import TerminalRoutes from './TerminalRoutes';
import GlobalRoutes from './GlobalRoutes';
const Error = lazy(() => import("../Components/Global/widget/404"));

export default function AppRouter() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {PublicRoutes}
        {TerminalRoutes}
        {CompanyRoutes}
        {GlobalRoutes}
        <Route path="*" element={<Error />} />
      </Routes>
    </Suspense>
  );
}
