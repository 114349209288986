import DateRange from "../widget/RangePicker";
import React, { Fragment, useEffect, useState } from "react";
import { useAuth } from "../../../Context/AuthContext";
import Search from "../Search";
import { Link } from "react-router-dom";
import Axios from "../../../Config/AxiosInstance";
import layout from "../../Scss/layout.module.scss";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import convertToLocalTime from "../../../Utils/timeZoneConverter";

export default function Filters({
  toDate,
  fromData,
  date,
  enableDate = true,
  onSearchSubmit,
  paymentMenu = false,
  paymentMenuKey = [],
  onMenuClick,
  onSearchReset,
  onSearchInput,
  downloadBtn,
  onDownload,
  placeHolder,
  enableSearch = true,
  downloadBtnDisable = false,
  roleDropdown = false,
  userStatusDropDown = false,
  onUserStatusChange,
  stores = [],
  onRoleChange,
  isDashboard = false,
  currentMenuItem,
  chargebacksStatuses = [],
  onChargebacksStatusChange,
  refresh = false,
  onRefreshClick,
  isDateSelected,
  SelectDate,
  showEmployeeRole,
}) {
  const { token, userInfo, filteredData } = useAuth();
  const [datePick, setDatePick] = useState(filteredData);
  const [roleName, setRoleName] = useState([]);
  const [role, setRole] = useState([]);
  const [chargebacksStatus, setChargebacksStatus] = useState("");
  const [userStatus, setUserStatus] = useState("all");

  const filterDate = (e) => {
    if (fromData) fromData(convertToLocalTime(e[0]).format("YYYY-MM-DD"));
    if (toDate) toDate(convertToLocalTime(e[1]).format("YYYY-MM-DD"));
    setDatePick(e);
    if (date) {
      date(e);
    }
  };
  const handleSearchSubmit = async (value) => {
    if (onSearchSubmit) onSearchSubmit(value);
  };
  const resetHandler = async () => {
    if (onSearchReset) {
      onSearchReset(true);
    }
  };
  const OnSearchInputHandler = (value) => {
    if (onSearchInput) {
      onSearchInput(value);
    }
  };
  const handleDownload = async (e) => {
    e.preventDefault();
    if (onDownload) onDownload();
  };

  useEffect(() => {
    if (roleDropdown && stores) {
      Axios(token)
        .post(`/stores/roles`, { stores: stores })
        .then((response) => {
          if (response.data.response) {
            const roleArray = [
              {
                id: "all",
                name: "All Roles",
                externalId: "",
                slug: "all",
              },
            ];
            response.data.response.map((items) => {
              roleArray.push({
                id: `${items.slug}`,
                name: items.name,
                externalId: items.id,
                slug: items.slug,
              });
            });
            setRoleName(roleArray);
            setRole([roleArray[0]]);
          }
        });
    }
  }, [roleDropdown, stores]);

  const OnRoleChange = (e) => {
    setRole(e);
    const checkAll = e.filter((item) => item.slug === "all");
    if (e.length > 1 && checkAll.length > 0) {
      setRole([e[e.length - 1]]);
      if (onRoleChange) {
        onRoleChange(e[e.length - 1].id);
      }
    } else if (e.length > 1 && checkAll.length === 0) {
      const roleIds = e.map((item) => item.id);
      if (onRoleChange) {
        onRoleChange(roleIds);
      }
    } else {
      if (onRoleChange) {
        onRoleChange(e[0]?.id);
      }
    }
  };

  const handleChargebacksStatusChange = (e) => {
    setChargebacksStatus(e.target.value);
    if (onChargebacksStatusChange) {
      onChargebacksStatusChange(e.target.value);
    }
  };
  const handleRefresh = () => {
    if (onRefreshClick) {
      onRefreshClick(true);
    }
  };

  const handleUserStatusChange = async (e) => {
    setUserStatus(e.target.value);
    onUserStatusChange(e.target.value);
  };

  return (
    <div className={`${layout.tableFilter}`}>
      <div className={`${layout.tableFilterWrapper} ${enableDate ? " " : ""}`}>
        <>
          {(enableDate || downloadBtn) && (
            <div className={`${layout.tableFilterDate}`}>
              {enableDate && (
                <div>
                  <DateRange
                    createdAt={
                      (userInfo ? filteredData[0] : filteredData[1]) ||
                      new Date()
                    }
                    onChange={filterDate}
                    value={datePick}
                    isDateSelected={isDateSelected}
                    SelectDate={SelectDate}
                  />
                </div>
              )}
              {downloadBtn && (
                <button
                  disabled={downloadBtnDisable}
                  onClick={handleDownload}
                  className={`ml-0 sm:ml-3 px-3 md:px-5 py-2  text-xs md:text-sm text-white disabled:bg-gray-300 flex items-center rounded bg-green-500 focus:bg-green-500 hover:bg-green-500 right-0 top-0 border-0 outline-none`}
                >
                  <span className="md:pr-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                  </span>
                  <span className="hidden sm:flex">Download</span>
                </button>
              )}
            </div>
          )}

          {chargebacksStatuses.length ? (
            <div className={`${layout.tableFilterStatus}`}>
              <select
                name={`chargebacksStatus`}
                id={`chargebacksStatus`}
                value={chargebacksStatus}
                onChange={handleChargebacksStatusChange}
                className={`border-gray-200 appearance-none outline-0 relative w-36 sm:w-48 py-2.5 h-10 px-3  border rounded text-left text-gray-500 cursor-pointer sm:text-sm mr-2 md:mr-0`}
              >
                <option value={""}>Filter By Status</option>
                {chargebacksStatuses?.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.label}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
        </>

        {(paymentMenu ||
          roleDropdown ||
          enableSearch ||
          userStatusDropDown ||
          showEmployeeRole) && (
          <>
            {paymentMenu && (
              <div
                className={`${layout.tableFilterNav}  ${
                  isDashboard
                    ? "flex gap-2 mb-0  lg:ml-0 md:max-w-auto ml-0"
                    : "flex gap-2 mb-0 sm:overflow-x-auto xl:ml-auto lg:ml-0 max-w-[92vw] md:max-w-auto ml-2"
                }`}
              >
                {paymentMenuKey &&
                  paymentMenuKey.map((item) =>
                    item?.link ? (
                      <Link
                        key={item.key}
                        to={item.link}
                        className={` ${
                          currentMenuItem === item.key
                            ? "bg-gray-500 text-white"
                            : "bg-slate-100 text-gray-600"
                        } hover:bg-gray-500 hover:text-white rounded px-3 py-2.5 font-medium transition-all text-xs h-full flex items-center relative`}
                      >
                        {item.count && item.count > 0 ? (
                          <div className="absolute inline-flex items-center justify-center w-3.5 h-3.5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -end-1 "></div>
                        ) : null}

                        <span className="text-xs text-tracking-wide md:text-sm">
                          {item.name}
                        </span>
                      </Link>
                    ) : (
                      <button
                        key={item.key}
                        onClick={() => {
                          onMenuClick(item.key);
                        }}
                        className={` ${
                          currentMenuItem === item.key
                            ? "bg-gray-500 text-white"
                            : "bg-slate-100 text-gray-600"
                        } hover:bg-gray-500 hover:text-white rounded px-3 py-2.5 font-medium  transition-all inline-block text-xs`}
                      >
                        {item.count && item.count > 0 ? (
                          <div className="absolute inline-flex items-center justify-center w-3.5 h-3.5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -end-1 "></div>
                        ) : null}
                        {item.name && (
                          <span className="text-xs md:text-sm text-tracking-wide">
                            {item.name}
                          </span>
                        )}
                      </button>
                    ),
                  )}
              </div>
            )}
            {roleDropdown && (
              <div className={`${layout.tableFilterRole}`}>
                <Listbox value={role} onChange={OnRoleChange} multiple={true}>
                  <div className="relative mt-1 min-w-[200px] max-w-[300px]">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      {role.length > 1
                        ? `Multiple Role Selected`
                        : role?.length === 1
                        ? role[0].name
                        : roleName[0]?.name}
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {roleName?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                            // disabled={person.slug === 'all'}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.name}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            )}

            {userStatusDropDown && (
              <div className={`${layout.tableFilterRole}`}>
                <select
                  name={`status`}
                  id={`status`}
                  value={userStatus}
                  onChange={handleUserStatusChange}
                  className={`border-gray-200 appearance-none outline-0 relative w-36 sm:w-52 py-2.5 h-10 px-3  border rounded text-left text-gray-500 cursor-pointer sm:text-sm mr-2 md:mr-0`}
                >
                  <option value="all">Filter By Status</option>
                  <option value="ban">Banned</option>
                  <option value="non_ban">Active</option>
                </select>
              </div>
            )}

            {enableSearch && (
              <Search
                OnSubmit={handleSearchSubmit}
                OnInput={OnSearchInputHandler}
                OnReset={resetHandler}
                placeHolder={placeHolder}
              />
            )}
            {refresh && (
              <div
                className={`ml-5 mt-4 lg:mt-0 sm:mt-4 md:mt-0 w-7 flex items-center`}
              >
                <button
                  onClick={handleRefresh}
                  className={`text-white font-semibold focus:text-green-700 hover:text-green-700 right-0 top-0 border-0 outline-none`}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_9873_5509)">
                      <path
                        d="M13.3333 7.33334C13.1703 6.16015 12.626 5.07311 11.7844 4.23967C10.9428 3.40623 9.85046 2.87262 8.67574 2.72104C7.50102 2.56947 6.30904 2.80833 5.28343 3.40084C4.25781 3.99335 3.45546 4.90663 2.99996 6.00001M2.66663 3.33334L2.66663 6.00001H5.33329"
                        stroke="#363C46"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.66663 8.66666C2.82967 9.83984 3.37391 10.9269 4.21553 11.7603C5.05715 12.5938 6.14945 13.1274 7.32418 13.279C8.4989 13.4305 9.69088 13.1917 10.7165 12.5992C11.7421 12.0066 12.5445 11.0934 13 9.99999M13.3333 12.6667L13.3333 9.99999L10.6666 9.99999"
                        stroke="#363C46"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_9873_5509">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
