import React, { useEffect, useState } from "react";
import styles from "../../../Components/Scss/Admin.module.scss";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../../Components/Global/widget/Loader";
import { useAuth } from "../../../Context/AuthContext";
import Modal from "../../../Components/Modal/modal";
import CompanyLayout from "../../../Components/Company/Layouts";
import { isEmpty } from "lodash";
import { HasPermission } from "../../../Components/Global/Permission";
import Unauthorised from "../../Global/Unauthorised";
import Axios from "../../../Config/AxiosInstance";

export default function UserPermission({ permission }) {
  const [data, setData] = useState([]);
  const [roleData, setRoleData] = useState({});
  const [name, setName] = useState();
  const [success, setSuccess] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();
  const userLocation = location.pathname.split("/");
  const roleId = userLocation.slice(-1);

  useEffect(() => {
    if (token) {
      Axios(token)
        .get(`/get/permissions/${roleId}`, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          const responseData = response?.data?.response;
          const groupedPermission = Object.values(
            groupArrayOfObjects(responseData, "group"),
          );
          setData(groupedPermission);
          setCheckedState(response?.data?.existing || null);
          setName(response?.data?.name || null);
          setIsLoading(false);
          setRoleData(response?.data?.role || null);
        });
    }
  }, [token]);

  function groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const handleOnChange = (value) => {
    let permissions = checkedState;
    let find = permissions && value ? permissions.indexOf(value) : -1;
    if (find > -1) {
      permissions.splice(find, 1);
      setCheckedState((prevState) => [
        ...prevState.splice(find, 1),
        ...permissions,
      ]);
    } else if (isEmpty(permissions)) {
      setCheckedState([value]);
    } else {
      setCheckedState((prevState) => [...prevState, value]);
    }
  };
  const handleSubmitRequest = (e) => {
    e.preventDefault();
    const data = {
      permissions: checkedState,
      ...roleData,
    };
    if (data?.permissions?.length > 0) {
      setIsLoading(true);
      Axios(token)
        .post(`/assign/permissions/${roleId}`, data)
        .then(() => {
          setIsLoading(false);
          setMessage(`Permission updated!`);
          setType(`success`);
          setSuccess(true);
        })
        .catch((e) => {
          setIsLoading(false);
          setSuccess(true);
          setType(`Error`);
          if (e?.response?.data?.message) {
            setMessage(e?.response?.data?.message);
          } else {
            setMessage(`Invalid request!`);
          }
        });
    } else {
      setType(`Error`);
      setMessage(`Please select at least one permission!`);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      {HasPermission(permission) ? (
        <CompanyLayout>
          {success && (
            <Modal
              isModalOpen={true}
              showOk={true}
              modalCallBack={() => setSuccess(false)}
              type={type}
              message={message}
            />
          )}
          <form onSubmit={handleSubmitRequest} className={``}>
            <div className="grid md:grid-cols-2">
              <p className="text-lg md:text-xl font-semibold">
                Update role or assign permission:{" "}
                <span className={"text-green-700"}>{name ? name : ""}</span>
              </p>

              <div className="flex md:justify-end items-center mt-2 md:mt-0">
                <Link
                  to={"/roles"}
                  className="px-3 py-2 mr-3 text-sm text-gray-700 transition-all bg-gray-200 rounded hover:bg-gray-300"
                >
                  Back
                </Link>
                <button className="px-3 py-2 text-sm text-white transition-all bg-green-500 rounded hover:bg-green-700">
                  Save Changes
                </button>
              </div>
            </div>
            <div className="grid grid-cols-12 mt-4">
              <div className="col-span-12 mb-5">
                <div className={`grid grid-cols-3 gap-3 mt-4`}>
                  <div className={``}>
                    <label className={`font-semibold text-base`} htmlFor="">
                      Role
                    </label>
                    <input
                      type="text"
                      className={`${styles.form_control} my-2 outline-none`}
                      onChange={(e) => {
                        setRoleData({ ...roleData, role: e.target.value });
                      }}
                      name={`role`}
                      value={roleData.role || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 ">
                <p className={`text-xl font-semibold my-5`}>
                  All available permissions:
                </p>
                {data &&
                  data.map((keyName, index) => {
                    return (
                      <div key={index} className="mb-5 rounded-b-lg shadow-sm ">
                        <div className="flex items-center justify-between px-4 pt-3 pb-2 bg-opacity-50 rounded-t-lg bg-slate-100">
                          <p className="text-sm md:text-base font-semibold text-gray-600 capitalize">
                            {keyName[0]?.group}
                          </p>
                        </div>
                        <div className="grid  sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5 px-4 py-5">
                          {keyName.map(function (items, i) {
                            return (
                              <div key={i}>
                                <div className={` ${styles.check_box}`}>
                                  <input
                                    name="permissions"
                                    onChange={() => handleOnChange(items.slug)}
                                    checked={checkedState?.includes(items.slug)}
                                    value={items.slug || null}
                                    type="checkbox"
                                    className="hidden"
                                    id={items.slug}
                                  />
                                  <label htmlFor={items.slug}>
                                    {items.name}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="flex items-center justify-end mt-3">
              <div>
                <Link
                  to={"/roles"}
                  className="px-3 py-2 mr-3 text-sm text-gray-700 transition-all bg-gray-200 rounded hover:bg-gray-300"
                >
                  Back
                </Link>
                <button className="px-3 py-2 text-sm text-white transition-all bg-green-500 rounded hover:bg-green-700">
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </>
  );
}
