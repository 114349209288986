import React, { Fragment, useEffect, useState } from "react";
import styles from "../../Components/Scss/Admin.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/Global/widget/Loader";
import Table from "../../Components/Global/Table";
import { useAuth } from "../../Context/AuthContext";
import CompanyLayout from "../../Components/Company/Layouts";
import { HasPermission } from "../../Components/Global/Permission";
import Unauthorised from "../Global/Unauthorised";
import { capitalize, orderBy, toNumber } from "lodash";
import { currencyFormat, roundUp } from "../../Utils/Common";
import Filters from "../../Components/Global/transaction/Filters";
import download from "downloadjs";
import Axios from "../../Config/AxiosInstance";
import convertToLocalTime from "../../Utils/timeZoneConverter";

const TRANSACTION_COLUMNS = [
  {
    name: "#",
    selector: (row) => row.serialNo,
    width: "50px",
  },
  {
    name: "Date",
    selector: (row) => row.date,
  },
  {
    name: "Employee",
    selector: (row) => row.associate,
    wrap: true,
  },
  {
    name: "User Role",
    selector: (row) => row.userRole,
    wrap: true,
  },
  {
    name: "Customer",
    selector: (row) => row.customer,
    width: "250px",
    wrap: true,
  },
  {
    name: "Order Id",
    selector: (row) => row.orderId,
    wrap: true,
  },
  {
    name: "Amount",
    selector: (row) => row.totalProcessedAmount,
    wrap: true,
    style: {
      paddingTop: "0",
      paddingBottom: "0",
      div: {
        background: "rgba(5, 66, 120, 0.0509803922)",
        color: "#054278",
        fontWeight: "bolder",
        borderRadius: "1.5rem",
        padding: "5px 15px",
        minWidth: "75px",
      },
    },
  },
  {
    name: "Tip",
    selector: (row) => row.tip,
    wrap: true,
  },
  {
    name: "Transaction Type",
    selector: (row) => row.paymentType,
    wrap: true,
  },
];

const REFUND_COLUMNS = [
  {
    name: "Date",
    selector: (row) => row.date,
  },
  {
    name: "Customer",
    selector: (row) => row.customer,
    style: {
      textTransform: "capitalize",
    },
  },
  {
    name: "Proceed By",
    selector: (row) => row.proceedBy,
    style: {
      textTransform: "capitalize",
    },
  },
  {
    name: "Order ID",
    selector: (row) => row.order_id,
  },
  {
    name: "Order Amount",
    selector: (row) => row.amount,
    style: {
      paddingTop: "0",
      paddingBottom: "0",
      button: {
        Width: "200px",
      },
    },
  },
  {
    name: "Refunded Amount",
    selector: (row) => row.refund,
    style: {
      paddingTop: "0",
      paddingBottom: "0",
      div: {
        background: "rgba(239,68,68,0.09)",
        color: "rgb(239,68,68)",
        fontWeight: "bolder",
        borderRadius: "1.5rem",
        padding: "5px 15px",
      },
    },
  },
  {
    name: "Refunded By",
    selector: (row) => row.refundedBy,
    style: {
      textTransform: "capitalize",
    },
  },
  {
    name: "Type",
    selector: (row) => row.type,
    style: {
      textTransform: "capitalize",
    },
  },
  {
    name: "Reason",
    selector: (row) => row.reason,
    style: {
      textTransform: "capitalize",
    },
  },
];

const CHARGEBACKS_COLUMNS = [
  {
    name: "Date Received",
    selector: (row) => convertToLocalTime(row?.createdAt || new Date(), "PPP"),
  },
  {
    name: "Store Name",
    selector: (row) => row?.store_name ?? "-",
  },
  {
    name: "Customer Name",
    selector: (row) => row?.fullName ?? "-",
  },
  {
    name: "Amount Disputed",
    selector: (row) => `$${roundUp(row.disputed_amount || 0)?.toFixed(2)}`,
  },
  {
    name: "Fee",
    selector: (row) => `$${roundUp(row?.chargeback_fee || 0)?.toFixed(2)}`,
  },
  {
    name: "Total Amount Disputed",
    selector: (row) =>
      `$${roundUp(
        roundUp(row.disputed_amount || 0) + roundUp(row?.chargeback_fee || 0),
      )?.toFixed(2)}`,
    style: {
      paddingTop: "0",
      paddingBottom: "0",
      div: {
        background: "rgba(5, 66, 120, 0.0509803922)",
        color: "#054278",
        fontWeight: "bolder",
        borderRadius: "1.5rem",
        padding: "5px 15px",
        minWidth: "75px",
      },
    },
  },
  // {
  //   name: "Reason",
  //   selector: (row) => row.chargeback_category,
  // },
  {
    name: "Status",
    selector: (row) => row?.chargeback_status?.toUpperCase(),
  },
];

export default function WithdrawalDetails() {
  const { selectedStoreIds, token } = useAuth();
  const [payout, setPayout] = useState();
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState();
  const [transactions, setTransactions] = useState([]);
  const [refundList, setRefundList] = useState([]);
  const [chargebacksList, setChargebacksList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfTransactions, setNoOfTransactions] = useState(0);
  const [resData, setResData] = useState(false);
  const location = useLocation();
  const router = useNavigate();
  const payoutId = location.pathname.split("/").slice(-1);
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [search, setSearch] = useState("");
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [datePick, setDatePick] = useState();
  const [tableLoading, setTableLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("transactions");

  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);

  useEffect(() => {
    if (token && payoutId && currentStoreId) {
      setResData(false);
      Axios(token)
        .post(
          `/payouts/${payoutId}`,
          {
            type: "store",
            size: perPage,
            s: search,
            stores: currentStoreId,
            page: currentPage,
            days: "",
            from: from,
            to: to,
          },
          {
            headers: { Authorization: "Bearer " + token },
          },
        )
        .then((response) => {
          if (response.data.response) {
            setPayout(response.data.response);
          }
          setResData(true);
        })
        .catch(() => {
          setResData(false);
        });
    }
  }, [token, search, currentStoreId]);

  useEffect(() => {
    try {
      if (token && payoutId && currentStoreId) {
        HandleTransactionType(currentTab).then();
      }
    } catch (e) {
      setResData(true);
    }
  }, [token, currentStoreId, search, currentPage, datePick, perPage]);

  const getWithdrawalData = (type = "transactions") => {
    setTableLoading(true);
    Axios(token)
      .post(
        `/payouts/${payoutId}/transactions`,
        {
          type: "store",
          size: perPage,
          s: search,
          stores: currentStoreId,
          page: currentPage,
          days: "",
          from: from,
          to: to,
          transactionFor: type,
        },
        {
          headers: { Authorization: "Bearer " + token },
        },
      )
      .then((response) => {
        if (response) {
          const records = response?.data?.response?.records || [];
          setNoOfTransactions(response?.data?.response?.totalItems || 0);
          const recordsArray = [];
          records.map((items, index) => {
            recordsArray.push({
              serialNo: index + 1,
              date: convertToLocalTime(items?.createdAt || new Date()).format(
                "PPP",
              ),
              associate: `${capitalize(
                items?.User?.UserProfile?.first_name,
              )} ${capitalize(items?.User?.UserProfile?.last_name)}`,
              userRole: items?.User?.Roles?.name,
              customer: capitalize(items?.Customer?.fullName),
              orderId: items?.order_id,
              totalProcessedAmount: currencyFormat(toNumber(items?.amount)),
              tip: currencyFormat(toNumber(items?.tip)),
              fee: currencyFormat(toNumber(items?.fee)),
              transactionId: items?.transactions_external_id,
              paymentType: items?.payment_type || "-",
            });
          });
          setTransactions(recordsArray);
          setTotalRows(response.data.response.totalItems);
        }
        setTableLoading(false);
      })
      .catch(() => {
        setTableLoading(false);
      });
  };

  const handleOnRowClick = (val) => {
    if (
      currentTab === "transactions" ||
      currentTab === "cashout" ||
      currentTab === "return"
    ) {
      router(
        `/transaction/${
          val?.transactionId ||
          val?.external_id ||
          val?.Transaction?.external_id
        }`,
      );
    } else {
      router(`/chargeback/${val?.chargeback_external_id}`);
    }
  };

  const handleOnPageChange = async (val) => {
    if (val) {
      setCurrentPage(val);
    }
  };

  const handleSearchSubmit = async (val) => {
    if (val) {
      setSearch(val);
    }
  };

  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(
      convertToLocalTime(val[0])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTo(
      convertToLocalTime(val[1])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const data = await Axios(token).post(
        `/payouts/${payoutId}/transactions/report`,
        {
          stores: currentStoreId,
          from: from,
          to: to,
          currentTab: currentTab,
        },
        {
          responseType: "blob",
          headers: { Authorization: "Bearer " + token },
        },
      );
      setLoading(false);
      download(
        data.data,
        currentTab === "transactions"
          ? `Payout transactions report ${convertToLocalTime(new Date()).format(
              "MMMM Do YYYY",
            )}`
          : currentTab === "return"
          ? `Payout refund transactions report ${convertToLocalTime(
              new Date(),
            ).format("MMMM Do YYYY")}`
          : currentTab === "all"
          ? `Payout All transactions report ${convertToLocalTime(
              new Date(),
            ).format("MMMM Do YYYY")}`
          : `Payout chargebacks transactions report ${convertToLocalTime(
              new Date(),
            ).format("MMMM Do YYYY")}`,
      );
    } catch (e) {
      setLoading(false);
    }
  };

  const HandleTransactionType = async (val) => {
    setCurrentTab(val);
    setCurrentPage(1);
    if (val === "return") {
      try {
        setTableLoading(true);
        const data = await Axios(token).post(`/payouts/${payoutId}/refunds`, {
          s: search,
          size: perPage,
          type: "store",
          stores: currentStoreId,
          page: currentPage,
        });

        filterData(data?.data?.response?.records || []);
        setTotalRows(data?.data?.response?.totalItems || 0);
        setTableLoading(false);
      } catch (e) {
        setRefundList([]);
        setTableLoading(false);
      }
    } else if (val === "chargebacks") {
      try {
        setTableLoading(true);
        const data = await Axios(token).post(
          `/payouts/${payoutId}/chargebacks`,
          {
            s: search,
            size: perPage,
            type: "store",
            stores: currentStoreId,
            page: currentPage,
          },
        );
        setChargebacksList(
          orderBy(data?.data?.response?.records, "createdAt") || [],
        );
        setTotalRows(data?.data?.response?.totalItems || 0);
        setTableLoading(false);
      } catch (e) {
        setChargebacksList([]);
        setTableLoading(false);
      }
    } else if (val === "transactions" || val === "cashout") {
      getWithdrawalData(val);
    }
  };

  const filterData = (data) => {
    let records = [];
    if (data) {
      data.map((items, index) => {
        records.push({
          id: index + 1,
          no: index + 1,
          date: convertToLocalTime(items?.createdAt || new Date()).format(
            "PPP",
          ),
          customer: items.Customer?.fullName?.toLowerCase(),
          order_id: items?.order_id,
          refund: currencyFormat(toNumber(items?.amount)),
          amount: currencyFormat(toNumber(items?.Transaction?.order_amount)),
          reason: items?.reason,
          external_id: items?.Transaction?.transactions_external_id,
          type: items?.type,
          proceedBy: `${items?.proceedBy?.UserProfile?.first_name || ""} ${
            items?.proceedBy?.UserProfile?.last_name || ""
          }`,
          refundedBy: `${items?.User?.UserProfile?.first_name || ""} ${
            items?.User?.UserProfile?.last_name || ""
          }`,
        });
      });
      setRefundList(records);
    }
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setCurrentPage(currentPage);
    setPerPage(currentRowsPerPage);
  };

  let columns = TRANSACTION_COLUMNS;
  if (currentTab === "transactions") columns = TRANSACTION_COLUMNS;
  if (currentTab === "return") columns = REFUND_COLUMNS;
  if (currentTab === "chargebacks") columns = CHARGEBACKS_COLUMNS;

  return (
    <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
      {loading && <Loader />}
      <Fragment>
        {payout && resData ? (
          <>
            {HasPermission(["view_payout_details"]) ? (
              <>
                <div className="flex justify-between pb-5 border-b">
                  <div>
                    <p className="flex items-center text-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="pl-2 font-semibold uppercase">
                        Payout -{" "}
                        {convertToLocalTime(payout?.created_at || new Date())
                          .subtract(1, "day")
                          .format("PP")}
                      </span>
                    </p>
                    <h2 className="mt-2 text-lg md:text-3xl font-bold text-gray-700">
                      {currencyFormat(
                        payout?.payoutLog?.earliestPrevAmount
                          ? payout?.payoutLog?.earliestPrevAmount
                          : payout?.meta.net_withdrawal,
                      )}
                      <span className="pl-3 font-light">USD</span>
                    </h2>
                  </div>
                  {payout?.payoutLog?.latestCurrentAmount && (
                    <div>
                      <p className="flex items-center text-gray-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                        <span className="pl-2 font-semibold uppercase">
                          Modified Payout
                        </span>
                      </p>
                      <h2 className="mt-2 text-lg md:text-3xl font-bold text-gray-700">
                        {currencyFormat(payout?.payoutLog?.latestCurrentAmount)}
                        <span className="pl-3 font-light">USD</span>
                      </h2>
                    </div>
                  )}
                  {/*{payout?.payoutLog?.comment && (*/}
                  {/*  <div>*/}
                  {/*    <p className="flex items-center text-gray-500">*/}
                  {/*      <span className="font-semibold uppercase">Reason</span>*/}
                  {/*    </p>*/}
                  {/*    <span className="font-light text-lg md:text-3xl text-gray-700">*/}
                  {/*      {payout?.payoutLog?.comment}*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>

                <div className="grid grid-cols-2 md:grid-cols-3 gap-5 pt-7">
                  <div className="pl-5 border-l">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      No Of Transactions:
                    </p>
                    <p className="mt-2 text-base font-semibold text-gray-700">
                      {noOfTransactions}
                    </p>
                  </div>

                  <div className="pl-5 border-0 md:border-1">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      Total Refunds:
                    </p>
                    <p className="mt-2 text-base font-semibold text-gray-700">
                      {currencyFormat(toNumber(payout?.meta.refund))}
                    </p>
                  </div>

                  <div className="pl-5 border-l">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      ChargeBacks:
                    </p>
                    <p className="mt-2 text-base font-semibold text-gray-700">
                      $
                      {roundUp(
                        roundUp(payout?.meta?.chargebacks_amount || 0) -
                          roundUp(payout?.meta?.won_chargebacks_amount || 0),
                      )?.toFixed(2)}
                    </p>
                  </div>
                </div>
                <h5 className="pb-2 mt-5 text-xl font-semibold text-gray-700 border-b">
                  Payment Details
                </h5>
                <div className={"grid md:grid-cols-4 gap-2 pt-3 mb-5"}>
                  <div className="flex items-center justify-between px-4 py-2 rounded bg-gray-50">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      Total Days Sales:
                    </p>
                    <p
                      className="text-base font-semibold text-gray-700"
                      style={{ marginTop: "-1px" }}
                    >
                      {currencyFormat(toNumber(payout?.meta.total_days_sales))}
                    </p>
                  </div>

                  <div className="flex items-center justify-between px-4 py-2 rounded bg-gray-50">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      Total Days Tips:
                    </p>
                    <p
                      className="text-base font-semibold text-gray-700"
                      style={{ marginTop: "-1px" }}
                    >
                      {currencyFormat(toNumber(payout?.meta.total_days_tips))}
                    </p>
                  </div>

                  <div className="flex items-center justify-between px-4 py-2 rounded bg-gray-50">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      Total Days Sales + Tips:
                    </p>
                    <p
                      className="text-base font-semibold text-gray-700 "
                      style={{ marginTop: "-1px" }}
                    >
                      {currencyFormat(
                        toNumber(payout?.meta.total_days_sales_plus_tips),
                      )}
                    </p>
                  </div>
                  <div className="flex items-center justify-between px-4 py-2 rounded bg-gray-50">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      Withdrawal Fees:
                    </p>
                    <p
                      className="text-base font-semibold text-gray-700"
                      style={{ marginTop: "-1px" }}
                    >
                      {currencyFormat(toNumber(payout?.meta.withdrawal_fees))}
                    </p>
                  </div>
                </div>
                <div className={"grid md:grid-cols-4 gap-2 pt-3 mb-5"}>
                  {payout?.payoutLog?.type && (
                    <>
                      <div className="flex items-center justify-between px-4 py-2 rounded bg-gray-50">
                        <p className="font-semibold tracking-wide text-gray-500 uppercase">
                          Adjustments:
                        </p>
                        {payout?.payoutLog?.type === "INCREASE" ? (
                          <p
                            className="text-base font-semibold text-gray-700"
                            style={{ marginTop: "-1px" }}
                          >
                            +
                            {currencyFormat(
                              toNumber(
                                payout?.payoutLog?.latestCurrentAmount -
                                  payout?.payoutLog?.earliestPrevAmount,
                              ),
                            )}
                          </p>
                        ) : (
                          <p
                            className="text-base font-semibold text-gray-700"
                            style={{ marginTop: "-1px" }}
                          >
                            -
                            {currencyFormat(
                              toNumber(
                                payout?.payoutLog?.earliestPrevAmount -
                                  payout?.payoutLog?.latestCurrentAmount,
                              ),
                            )}
                          </p>
                        )}
                        {/*<div className={"flex flex-col w-full"}>*/}
                        {/*  <div className={"flex flex-row justify-between"}>*/}
                        {/*    <p className="font-semibold tracking-wide text-gray-500 uppercase">*/}
                        {/*      Adjustments:*/}
                        {/*    </p>*/}
                        {/*    */}
                        {/*  </div>*/}
                        {/*  <div className={"flex flex-row justify-between"}>*/}

                        {/*  </div>*/}
                        {/*</div>*/}
                      </div>
                      <div className="flex items-center justify-between px-4 py-2 rounded bg-gray-50 col-span-3">
                        <p className="font-semibold tracking-wide text-gray-500 uppercase">
                          Reason:
                        </p>
                        <p
                          className="text-base font-semibold text-gray-700"
                          style={{ marginTop: "-1px" }}
                        >
                          {payout?.payoutLog?.comment}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <Unauthorised />
            )}
          </>
        ) : (
          <div>
            <div className="flex justify-between pb-5 border-b animate-pulse">
              <div>
                <p className="w-40 h-4 bg-gray-200 rounded "></p>
                <h2 className="w-24 mt-3 font-bold bg-gray-200 rounded h-7 "></h2>
              </div>

              <div className="">
                <p className="w-24 h-4 bg-gray-200 rounded"></p>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-5 pt-7 animate-pulse">
              <div>
                <p className="w-24 h-4 bg-gray-200 rounded"></p>
                <p className="w-48 h-4 mt-2 bg-gray-200 rounded"></p>
              </div>

              {Array.from(Array(3), (e, i) => {
                return (
                  <div key={i} className="pl-5 border-l">
                    <p className="w-24 h-4 bg-gray-200 rounded"></p>
                    <p className="w-48 h-4 mt-2 bg-gray-200 rounded"></p>
                  </div>
                );
              })}
            </div>

            <div className="pb-2 mt-5 text-xl font-semibold text-gray-700 border-b">
              Payment Details
            </div>

            <div className="grid grid-cols-4 gap-2 pt-3 animate-pulse">
              {Array.from(Array(5), (e, i) => {
                return (
                  <div
                    key={i}
                    className="flex items-center justify-between px-4 py-3 rounded bg-gray-50"
                  >
                    <p className="w-16 h-4 bg-gray-200 rounded"></p>
                    <p className="w-24 h-4 bg-gray-200 rounded"></p>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <h5
          className={`text-xl whitespace-nowrap font-semibold pb-2 mb-5 border-b text-gray-700`}
        >
          All Transaction
        </h5>

        <div>
          <Filters
            isDashboard
            enableDate={false}
            enableSearch
            placeHolder={"Search Customer"}
            download
            onDownload={handleDownload}
            downloadBtn
            date={handleDateChange}
            onSearchSubmit={handleSearchSubmit}
            onSearchReset={() => setSearch("")}
            downloadBtnDisable={!transactions.length}
            paymentMenu
            paymentMenuKey={[
              { name: "Transactions", link: null, key: "transactions" },
              { name: "Returns", link: null, key: "return" },
              { name: "Chargebacks", link: null, key: "chargebacks" },
            ]}
            currentMenuItem={currentTab}
            defaultMenu="transactions"
            onMenuClick={HandleTransactionType}
            payoutPage
          />
        </div>
        <div className={`mt-5 ${styles.table_ui_style}`}>
          <Table
            data={
              currentTab === "chargebacks"
                ? chargebacksList
                : currentTab === "return"
                ? refundList
                : transactions
            }
            pending={tableLoading}
            columns={columns}
            showPointer={true}
            onPageChange={handleOnPageChange}
            onRowClick={handleOnRowClick}
            showPagination={true}
            perPage={perPage}
            totalRows={totalRows}
            onRowPerPageChange={(currentRowsPerPage, currentPage) =>
              handleRowsPerPageChange(currentRowsPerPage, currentPage)
            }
          />
        </div>
      </Fragment>
    </CompanyLayout>
  );
}
