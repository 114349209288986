import React, { Fragment, useEffect, useState } from "react";
import styles from "../../Components/Scss/Admin.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/Global/widget/Loader";
import Table from "../../Components/Global/Table";
import { useAuth } from "../../Context/AuthContext";
import CompanyLayout from "../../Components/Company/Layouts";
import { HasPermission } from "../../Components/Global/Permission";
import Unauthorised from "../Global/Unauthorised";
import { toNumber } from "lodash";
import { currencyFormat, roundUp } from "../../Utils/Common";
import Filters from "../../Components/Global/transaction/Filters";
import download from "downloadjs";
import Axios from "../../Config/AxiosInstance";
import convertToLocalTime from "../../Utils/timeZoneConverter";

export default function OverPaidDetails() {
  const { selectedStoreIds, token } = useAuth();
  const [payout, setPayout] = useState();
  const [chargebacksList, setChargebacksList] = useState([]);
  const [resData, setResData] = useState(false);
  const location = useLocation();
  const router = useNavigate();
  const payoutId = location.pathname.split("/").slice(-1);
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [tableLoading, setTableLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);

  useEffect(() => {
    if (token && payoutId && currentStoreId) {
      setResData(false);
      Axios(token)
        .post(
          `/payouts/${payoutId}`,
          {
            type: "debit",
            stores: currentStoreId,
            days: "",
          },
          {
            headers: { Authorization: "Bearer " + token },
          },
        )
        .then((response) => {
          if (response?.data?.response) {
            setPayout(response.data.response);
            filterDate(response?.data?.response?.chargebacks);
            setTableLoading(false);
          }
          setResData(true);
        })
        .catch(() => {
          setResData(false);
        });
    }
  }, [token, currentStoreId]);

  const filterDate = (data) => {
    const makeData = [];
    for (const value of data) {
      const formatData = {
        createdAt: value?.activityDate,
        store_name: value?.storeName,
        fullName:
          value?.MasterChargeback?.Customer?.firstName +
          " " +
          value?.MasterChargeback?.Customer?.lastName,
        disputed_amount: value?.chargebackAmount,
        chargeback_status: value?.status,
        relatedPayoutID: value?.previousPayoutId,
        relatedPayoutCreatedDate: value?.previousWithdrawalCreatedAt,
        external_id: value?.MasterChargeback?.chargeback_external_id,
        type: value?.type === "unique" ? "Original Payout" : "Over Paid Payout",
      };
      makeData.push(formatData);
    }
    setChargebacksList(makeData);
  };

  const CHARGEBACKS_COLUMNS = [
    {
      name: "Date Received",
      // selector: (row) => convertToLocalTime.utc(row?.createdAt).format("LLL"),
      cell: (row) => [
        <button
          key={row.external_id + 1}
          className={`text-blue-950 font-bold px-3 py-1 hover:font-bold hover:text-green-500 leading-5 rounded-full mr-3`}
          onClick={() => router(`/chargeback/${row.external_id}`)}
          id={"" + row.external_id + 1}
        >
          {convertToLocalTime(row?.createdAt).format("PPP")}
        </button>,
      ],
    },
    {
      name: "Store Name",
      selector: (row) => row?.store_name ?? "-",
    },

    {
      name: "Total Amount Disputed",
      selector: (row) => `$${roundUp(roundUp(row.disputed_amount || 0))}`,
      style: {
        paddingTop: "0",
        paddingBottom: "0",
        div: {
          background: "rgba(5, 66, 120, 0.0509803922)",
          color: "#054278",
          fontWeight: "bolder",
          borderRadius: "1.5rem",
          padding: "5px 15px",
          minWidth: "75px",
        },
      },
    },
    {
      name: "Previous Payout Reference",
      style: {
        paddingTop: "0",
        paddingBottom: "0",
        div: {
          background: "rgba(5, 66, 120, 0.0509803922)",
          color: "#054278",
          fontWeight: "bolder",
          borderRadius: "1.5rem",
          padding: "5px 15px",
          minWidth: "75px",
        },
      },
      // selector: (row) => row?.relatedPayoutID ?? "-",
      cell: (row) => [
        <button
          key={row.relatedPayoutID + 1}
          className={`px-3 py-1 hover:font-bold hover:text-green-500 leading-5 rounded-full mr-3 text-blue-950 font-bold`}
          onClick={() => router(`/withdrawal/${row.relatedPayoutID}`)}
          id={"" + row.relatedPayoutID + 1}
        >
          {convertToLocalTime(row?.relatedPayoutCreatedDate).format("PPP")}
        </button>,
      ],
    },
    {
      name: "Type",
      selector: (row) => row?.type ?? "-",
    },
  ];

  const handleDownload = async () => {
    try {
      setLoading(true);
      const data = await Axios(token).post(
        "/download/overpaid/report",
        {
          type: "debit",
          // size: perPage,
          stores: currentStoreId,
          // page: currentPage,
          days: "",
        },
        {
          responseType: "blob",
        },
      );
      setLoading(false);
      download(
        data.data,
        `Overpaid reports ${convertToLocalTime(new Date()).format(
          "MMMM Do YYYY",
        )}`,
      );
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
      {loading && <Loader />}
      <Fragment>
        {payout && resData ? (
          <>
            {HasPermission(["view_payout_details"]) ? (
              <>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-5 pt-7">
                  <div className="pl-5 border-l">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      No Of Over Paid Chargebacks:
                    </p>
                    <p className="mt-2 text-base font-semibold text-gray-700">
                      {payout?.meta?.totalTransactions}
                    </p>
                  </div>

                  <div className="pl-5 border-0 md:border-1">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      Total Refunds:
                    </p>
                    <p className="mt-2 text-base font-semibold text-gray-700">
                      {currencyFormat(toNumber(payout?.meta?.refund))}
                    </p>
                  </div>

                  <div className="pl-5 border-l">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      Over Paid Amount:
                    </p>
                    <p className="mt-2 text-base font-semibold text-gray-700">
                      ${roundUp(payout?.meta?.totalAmount || 0)}
                    </p>
                  </div>
                  <div className="pl-5 border-l">
                    <p className="font-semibold tracking-wide text-gray-500 uppercase">
                      Cash-out:
                    </p>
                    <p className="mt-2 text-base font-semibold text-gray-700">
                      {currencyFormat(roundUp(payout?.meta?.cashout || 0))}
                    </p>
                  </div>
                </div>
                <div className={"grid md:grid-cols-4 gap-2 pt-3 mb-5"}>
                  {payout?.payoutLog?.type && (
                    <>
                      <div className="flex items-center justify-between px-4 py-2 rounded bg-gray-50">
                        <p className="font-semibold tracking-wide text-gray-500 uppercase">
                          Adjustments:
                        </p>
                        {payout?.payoutLog?.type === "INCREASE" ? (
                          <p
                            className="text-base font-semibold text-gray-700"
                            style={{ marginTop: "-1px" }}
                          >
                            +
                            {currencyFormat(
                              toNumber(
                                payout?.payoutLog?.latestCurrentAmount -
                                  payout?.payoutLog?.earliestPrevAmount,
                              ),
                            )}
                          </p>
                        ) : (
                          <p
                            className="text-base font-semibold text-gray-700"
                            style={{ marginTop: "-1px" }}
                          >
                            -
                            {currencyFormat(
                              toNumber(
                                payout?.payoutLog?.earliestPrevAmount -
                                  payout?.payoutLog?.latestCurrentAmount,
                              ),
                            )}
                          </p>
                        )}
                        {/*<div className={"flex flex-col w-full"}>*/}
                        {/*  <div className={"flex flex-row justify-between"}>*/}
                        {/*    <p className="font-semibold tracking-wide text-gray-500 uppercase">*/}
                        {/*      Adjustments:*/}
                        {/*    </p>*/}
                        {/*    */}
                        {/*  </div>*/}
                        {/*  <div className={"flex flex-row justify-between"}>*/}

                        {/*  </div>*/}
                        {/*</div>*/}
                      </div>
                      <div className="flex items-center justify-between px-4 py-2 rounded bg-gray-50 col-span-3">
                        <p className="font-semibold tracking-wide text-gray-500 uppercase">
                          Reason:
                        </p>
                        <p
                          className="text-base font-semibold text-gray-700"
                          style={{ marginTop: "-1px" }}
                        >
                          {payout?.payoutLog?.comment}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <Unauthorised />
            )}
          </>
        ) : (
          <div>
            <div className="flex justify-between pb-5 border-b animate-pulse">
              <div>
                <p className="w-40 h-4 bg-gray-200 rounded "></p>
                <h2 className="w-24 mt-3 font-bold bg-gray-200 rounded h-7 "></h2>
              </div>

              <div className="">
                <p className="w-24 h-4 bg-gray-200 rounded"></p>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-5 pt-7 animate-pulse">
              <div>
                <p className="w-24 h-4 bg-gray-200 rounded"></p>
                <p className="w-48 h-4 mt-2 bg-gray-200 rounded"></p>
              </div>

              {Array.from(Array(3), (e, i) => {
                return (
                  <div key={i} className="pl-5 border-l">
                    <p className="w-24 h-4 bg-gray-200 rounded"></p>
                    <p className="w-48 h-4 mt-2 bg-gray-200 rounded"></p>
                  </div>
                );
              })}
            </div>

            <div className="pb-2 mt-5 text-xl font-semibold text-gray-700 border-b">
              Payment Details
            </div>

            <div className="grid grid-cols-4 gap-2 pt-3 animate-pulse">
              {Array.from(Array(5), (e, i) => {
                return (
                  <div
                    key={i}
                    className="flex items-center justify-between px-4 py-3 rounded bg-gray-50"
                  >
                    <p className="w-16 h-4 bg-gray-200 rounded"></p>
                    <p className="w-24 h-4 bg-gray-200 rounded"></p>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <h5
          className={`text-xl whitespace-nowrap font-semibold pb-2 mb-5 border-b text-gray-700`}
        >
          All Over Paid References
        </h5>

        <div>
          <Filters
            isDashboard={false}
            enableDate={false}
            enableSearch={false}
            // placeHolder={"Search Customer"}
            download
            onDownload={handleDownload}
            downloadBtn
            // date={handleDateChange}
            // onSearchSubmit={handleSearchSubmit}
            // onSearchReset={() => setSearch("")}
            // downloadBtnDisable={!transactions.length}
            // paymentMenu
            // paymentMenuKey={[
            //   { name: "Transactions", link: null, key: "transactions" },
            //   { name: "Cash-out", link: null, key: "cashout" },
            //   { name: "Returns", link: null, key: "return" },
            //   { name: "Chargebacks", link: null, key: "chargebacks" },
            // ]}
            // currentMenuItem={currentTab}
            // defaultMenu="transactions"
            // onMenuClick={HandleTransactionType}
            payoutPage
          />
        </div>
        <div className={`mt-5 ${styles.table_ui_style}`}>
          <Table
            data={chargebacksList}
            pending={tableLoading}
            columns={CHARGEBACKS_COLUMNS}
            showPointer={false}
            // onPageChange={handleOnPageChange}
            // onRowClick={handleOnRowClick}
            showPagination={false}
            // perPage={perPage}
            // totalRows={totalRows}
            // onRowPerPageChange={(currentRowsPerPage, currentPage) =>
            //   handleRowsPerPageChange(currentRowsPerPage, currentPage)
            // }
          />
        </div>
      </Fragment>
    </CompanyLayout>
  );
}
