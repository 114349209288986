import React, { useEffect, useState } from "react";
import Unauthorised from "../../Global/Unauthorised";
import { HasPermission } from "../../../Components/Global/Permission";
import Loader from "../../../Components/Global/widget/Loader";
import CompanyLayout from "../../../Components/Company/Layouts";
import { useAuth } from "../../../Context/AuthContext";
import styles from "../../../Components/Scss/Admin.module.scss";
import Table from "../../../Components/Global/Table";
import { useNavigate } from "react-router-dom";
import Axios from "../../../Config/AxiosInstance";

const COLUMNS = [
  {
    name: "#",
    selector: (_, index) => index + 1,
    width: "50px",
  },
  {
    name: "Store Name",
    selector: (row) => row?.Store?.store_name,
    sortable: false,
  },
  {
    name: "Start",
    selector: (row) => row?.start_date,
    sortable: true,
    sortField: "start_date",
  },
  {
    name: "End",
    selector: (row) => row?.end_date,
    sortable: true,
    sortField: "end_date",
  },
];

const DEFAULT_SORT = {
  field: "start_date",
  direction: "desc",
};

export default function BankStatement({ permission }) {
  const { token, selectedStoreIds } = useAuth();
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState(DEFAULT_SORT);
  const navigate = useNavigate();

  useEffect(() => {
    setTableLoading(false);
    if (token && selectedStoreIds) {
      getBankStatementList().then();
    }
  }, [selectedStoreIds, currentPage, sort]);

  const getBankStatementList = async () => {
    setTableLoading(true);
    const bankStatements = await Axios(token).post(`/bank-statement`, {
      storeId: selectedStoreIds,
      page: currentPage,
      size: perPage,
      type: "store",
      sort,
    });
    if (
      bankStatements &&
      bankStatements.data &&
      bankStatements.data.response &&
      bankStatements.data.response.records
    ) {
      setData(bankStatements.data.response.records);
      setTotalRows(bankStatements.data.response.totalItems);
      setTableLoading(false);
    }
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(currentPage);
  };

  const handleOnPageChange = async (val) => {
    if (val) {
      setCurrentPage(val);
    }
  };

  const handleSort = async (column, sortDirection) => {
    setSort("");
    let colName = "";
    if (column === "Start") {
      console.log("start_date");
      colName = "start_date";
    } else if (column === "End") {
      console.log("end_date");
      colName = "end_date";
    }
    setSort({
      field: colName,
      direction: sortDirection,
    });
  };

  const handleRowClick = (val) => {
    navigate(
      `/bank-statement/${val.Store.store_external_id}/${val.bank_statement_external_id}`,
    );
  };

  return (
    <CompanyLayout>
      {tableLoading && <Loader />}
      {HasPermission(permission) ? (
        <>
          <div className="flex items-center justify-between">
            <h5 className={`text-xl md:text-2xl font-semibold flex-1 mr-5`}>
              Statement
            </h5>
          </div>
          <div className={` mt-5`}>
            <div className={``}>
              <div className={`${styles.table_ui_style} mt-5`}>
                <Table
                  pending={tableLoading}
                  columns={COLUMNS}
                  data={data}
                  perPage={perPage}
                  totalRows={totalRows}
                  showPointer={true}
                  onPageChange={handleOnPageChange}
                  onRowClick={handleRowClick}
                  defaultSortFieldId={2}
                  defaultSortAsc={DEFAULT_SORT.direction === "asc"}
                  sortServer
                  handleSort={handleSort}
                  sortIcon={
                    <svg
                      width="9"
                      height="12"
                      viewBox="0 0 9 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.5 1.5L4.5 10.5M4.5 10.5L8 7M4.5 10.5L1 7"
                        stroke="#064278"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  }
                  onRowPerPageChange={(currentRowsPerPage, currentPage) =>
                    handleRowsPerPageChange(currentRowsPerPage, currentPage)
                  }
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Unauthorised />
      )}
    </CompanyLayout>
  );
}
