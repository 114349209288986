import React from "react";
import Styles from "../../Scss/Admin.module.scss";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="block py-7">
      <div className="flex justify-center">
        <ul
          className={`${Styles.footer_menu} bg-slate-100 bg-opacity-50 px-4 md:px-7 py-1 md:py-3 rounded-full`}
        >
          <li>
            Powered by{" "}
            <a
              href="#"
              target={`_blank`}
              style={{
                textDecoration: "none",
                cursor: "default",
                pointerEvents: "none",
              }}
            >
              Gain Federal Credit Union
            </a>
          </li>
          <li>
            <Link to={"/terms"}>Terms</Link>
          </li>
          <li>
            <Link to={"/privacy"}>Privacy Policy</Link>
          </li>
          <li>
            <Link to={"/esign"}>E-Sign Disclosure</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
