import { useAuth } from "../../Context/AuthContext";
import { concat, intersection, isEmpty } from "lodash";

export const HasPermission = (permission, allPermission = []) => {
  const { userInfo } = useAuth();
  let mergePermissions = permission;
  let role = userInfo && userInfo.role ? userInfo.role : null;
  let userPermission =
    userInfo && userInfo.permissions
      ? userInfo.permissions
      : allPermission
      ? allPermission
      : [];
  if (
    role === "company" ||
    role === "company_user_1" ||
    role === "company_user"
  ) {
    return true;
  } else if (!isEmpty(mergePermissions) && !isEmpty(userPermission)) {
    const checkIsExist = intersection(userPermission, mergePermissions);
    return !isEmpty(checkIsExist);
  } else {
    return false;
  }
};
export const HasInTerminalPermission = (permission, allPermission = []) => {
  const { inTerminalPermissions, inTerminalInfo } = useAuth();
  let mergePermissions = permission;

  let userPermission = inTerminalPermissions
    ? inTerminalPermissions
    : allPermission
    ? allPermission
    : [];

  if (
    inTerminalInfo?.role === "company" ||
    inTerminalInfo?.role === "company_user_1"
  ) {
    return true;
  } else if (!isEmpty(mergePermissions) && !isEmpty(userPermission)) {
    const checkIsExist = intersection(userPermission, mergePermissions);
    return !isEmpty(checkIsExist);
  } else {
    return false;
  }
};
export const ViewPermission = (additionalPermission = []) => {
  return concat(
    [
      "company",
      "store",
      "everything_except_download",
      "everything_except_delete",
      "everything_except_update",
      "everything_view_only",
    ],
    additionalPermission,
  );
};
