import { endOfDay, isValid, parse, parseISO, startOfDay, sub } from "date-fns";
import { format, toDate, toZonedTime } from "date-fns-tz";

const dateFormats = [
  "yyyy-MM-dd",
  "MM/dd/yyyy",
  "dd/MM/yyyy",
  "MM-dd-yyyy",
  "dd-MM-yyyy",
  "yyyy/MM/dd",
  "yyyy.MM.dd",
  "dd.MM.yyyy",
  "MM.dd.yyyy",
  "MMMM do, yyyy",
  "MMMM d, yyyy",
  "MMM d, yyyy",
  "MMM do, yyyy",
  "MMMM dd, yyyy",
  "MM/dd/yy",
  "dd/MM/yy",
  "MM-dd-yy",
  "dd-MM-yy",
  "yyyy-M-d",
  "d-M-yyyy",
  "d/MM/yyyy",
  "MM/d/yyyy",
  "do MMMM yyyy",
  "dd MMM yyyy",
  "MMM d, yyyy",
  "MMM dd, yyyy",
  "PPPP",
  "PPPPpppp",
  "PPP",
  "PP",
  "P",
  "p",
  "MMM dd, yyyy h:mm a",
  "MMMM dd, yyyy h:mm a",
  "MMM dd, yyyy h:mm aaaa",
  "MMMM dd, yyyy h:mm aaaa",
];

/**
 * Convert a UTC date string to the user's local time zone and format it.
 *
 * @param {Date|string} date - The UTC date string or Date object to convert.
 * @param {string} [dateFormat] - The format for the output date string.
 * @returns {string} - The formatted date string.
 */

class LocalTimeConverter {
  constructor(date = new Date(), isUTC = false) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.date = this.parseDate(date, isUTC);
    if (!isValid(this.date)) {
      throw new Error(`Invalid date: ${date}`);
    }
  }

  static utc(date = new Date()) {
    return new LocalTimeConverter(date, true);
  }

  parseDate(date, isUTC) {
    if (typeof date === "string") {
      let parsedDate = parseISO(date);
      if (!isValid(parsedDate)) {
        for (let formatString of dateFormats) {
          parsedDate = parse(date, formatString, new Date());
          if (isValid(parsedDate)) {
            break;
          }
        }
      }
      date = parsedDate;
    }
    return isUTC ? toDate(date) : toZonedTime(date, this.timeZone);
  }

  startOf(unit) {
    if (unit === "day") {
      this.date = startOfDay(this.date);
    }
    // Add more units if needed
    return this;
  }

  endOf(unit) {
    if (unit === "day") {
      this.date = endOfDay(this.date);
    }
    // Add more units if needed
    return this;
  }

  subtract(amount, unit) {
    this.date = sub(this.date, { [unit]: amount });
    return this;
  }

  format(dateFormat = "yyyy-MM-dd") {
    const correctedFormat = dateFormat
      .replace(/YYYY/g, "yyyy")
      .replace(/DD/g, "dd");
    return format(this.date, correctedFormat, { timeZone: this.timeZone });
  }
}

const convertToLocalTime = (date = new Date(), dateFormat = null) => {
  const converter = new LocalTimeConverter(date);
  return dateFormat ? converter.format(dateFormat) : converter;
};

export default convertToLocalTime;
