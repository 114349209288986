import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import Loader from "../Global/widget/Loader";
import Axios from "../../Config/AxiosInstance";

export default function ViewRoleModal({ onCloseViewModal, id }) {
  const { token } = useAuth();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const modalIsClose = () => {
    onCloseViewModal(false);
  };
  useEffect(() => {
    Axios(token)
      .get(`/roles/view/permission/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        if (response && response?.data?.name) {
          setLoading(true);
          setData(response.data.name);
        } else {
          setLoading(false);
        }
      });
  }, [token]);
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="relative mx-auto my-6 w-11/12 md:w-2/4 lg:w-2/6 xl:w-96">
          <button
            onClick={modalIsClose}
            className="absolute z-10 text-gray-400 top-3 right-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-2xl outline-none pb-7 focus:outline-none">
            <div className="relative px-6 pt-5">
              <div className="flex items-center justify-between border-b pb-2">
                <div>
                  <h5 className={`text-lg font-semibold text-gray-700`}>
                    Assigned Permission
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="mt-3 items-center px-6 border-solid border-blueGray-200 rounded-b max-h-[365px]
                         overflow-y-scroll scrollbar scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white"
            >
              {data ? (
                <div>
                  {data?.map(function (items, i) {
                    return (
                      <div
                        key={i}
                        className={`rounded bg-gray-100 px-3 py-1 mt-1 mr-1 inline-block capitalize`}
                      >
                        {" "}
                        {items.replace(/_/g, " ")}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <>
                  {loading && <Loader />}
                  <div className="py-5 text-base text-center text-gray-500">
                    No Permission yet
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 backdrop-blur-sm bg-white/30" />
    </>
  );
}
