import React, { useEffect, useState } from "react";
import UserRegistration from "../Users/UserRegistration";
import CompanyLayout from "../../../Components/Company/Layouts";
import { useAuth } from "../../../Context/AuthContext";
import Unauthorised from "../../Global/Unauthorised";
import { HasPermission } from "../../../Components/Global/Permission";

export default function Registration({ permission }) {
  const { selectedStoreIds } = useAuth();
  const [currentStoreId, setCurrentStoreId] = useState("");
  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);
  return (
    <>
      {HasPermission(permission) ? (
        <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
          <div className="md:max-w-[75vw] mt-4 mx-auto">
            <div className="md:pl-8 xl:col-span-10 lg:col-span-9 md:col-span-8">
              <UserRegistration selectedStoreId={currentStoreId} />
            </div>
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </>
  );
}
