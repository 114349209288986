import React, { useEffect, useState } from "react";
import { useAuth } from "../Context/AuthContext";
import Error from "../Components/Global/widget/404";
import Unauthorised from "../Page/Global/Unauthorised";
import Loader from "../Components/Global/widget/Loader";

function PrivetRoute({ children, role, permission }) {
  let { isAuthenticated, userInfo, loading } = useAuth();
  const [isExist, setIsExist] = useState(false);
  useState(false);
  let existance;
  useEffect(() => {
    existance = localStorage.getItem("Exist");
  }, []);
  useEffect(() => {
    if (permission || role) {
      permission.forEach((elm) => {
        let exists = userInfo.permissions.includes(elm);
        if (exists || elm === "global" || existance) {
          setIsExist(true);
          localStorage.setItem("Exist", isExist);
        }
      });
    }
  }, [permission, role]);

  if (loading) {
    return <Loader />;
  }
  if (
    isAuthenticated &&
    userInfo &&
    role &&
    (!role.includes(userInfo.role) || role !== "global") &&
    !isExist
  ) {
    return <Error />;
  } else if (
    (isAuthenticated &&
      userInfo &&
      !isExist &&
      role &&
      (role.includes(userInfo.role) || role !== "global")) ||
    (isAuthenticated && userInfo && !isExist)
  ) {
    return <Unauthorised />;
  } else if (!isAuthenticated) {
    return <Error />;
  }
  return children;
}

export default PrivetRoute;
