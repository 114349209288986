import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PrivetRoute from './ProtectedRoute';

const UpdateUserInformation = lazy(() => import('../Page/Global/UpdateUserInformation'));

const GlobalRoutes = [
  <Route
    path="/update/password"
    element={
      <PrivetRoute role="global" permission={['global']}>
        <UpdateUserInformation />
      </PrivetRoute>
    }
    key="update-password"
  />,
];

export default GlobalRoutes;
