const PERMITTED_ROLES = {
  'manager': [
    'dispatcher',
    'associate',
    'driver'
  ],
  'manager-access': [
    'dispatcher',
    'associate',
    'driver',
    'driver-smoakland',
  ]
};

export const getPermittedRoles = (userRole) => PERMITTED_ROLES[userRole];

export const isPermittedRole = (permittedRoles, role) => (
  permittedRoles ? permittedRoles.includes(role) : true
);

export const canUserOperateWithRole = (userRole, role) => {
  const permittedRoles = getPermittedRoles(userRole);

  return isPermittedRole(permittedRoles, role);
};