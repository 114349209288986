import React, { useState } from "react";
import styles from "../Scss/Admin.module.scss";

import { useAuth } from "../../Context/AuthContext";
import Axios from "../../Config/AxiosInstance";

export default function DuplicateRoleModal({
  onClose,
  data,
  duplicateData,
  storeId,
}) {
  const { isAllStoreSelected } = useAuth();
  const [error, setError] = useState(false);
  const [role, setRole] = useState({
    isGlobal: duplicateData?.isGlobal,
    role: duplicateData?.role_name,
  });
  const { token } = useAuth();
  const modalIsClose = () => {
    onClose(false);
  };
  const handleCheckBox = ({ target }) => {
    if (target.checked) {
      setRole({ ...role, isGlobal: true });
    } else {
      setRole({ ...role, isGlobal: false });
    }
  };
  const handleSubmit = (e) => {
    duplicateData.role = role.role;
    duplicateData.stores = storeId;
    e.preventDefault();
    Axios(token)
      .post(`/create/copy/role`, duplicateData, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        if (res) {
          data(res.data.response);
          onClose(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setError(err.response.data.message);
      });
  };
  return (
    <div>
      {/*{loading && <Loader />}*/}
      <div className="fixed inset-0 z-[2147483649] flex items-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="relative mx-auto my-6 w-11/12 md:w-2/4 lg:w-2/6 xl:w-96">
          <button
            onClick={modalIsClose}
            className="absolute z-10 text-gray-400 top-3 right-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-2xl outline-none focus:outline-none">
            <div className="relative px-6 pt-6">
              <div className="flex items-center justify-between border-b pb-2">
                <div>
                  <h5 className={`text-lg font-semibold text-gray-700`}>
                    Duplicate Role
                  </h5>
                </div>
              </div>
            </div>
            <div className="items-center px-6 pb-8 mt-5 border-solid rounded-b border-blueGray-200">
              <form
                className={"w-100"}
                id="create-role-form"
                onSubmit={handleSubmit}
              >
                <div className="relative">
                  <input
                    type="text"
                    placeholder={`Enter role name`}
                    className={`${styles.form_control} pr-20`}
                    name={"role"}
                    value={role.role}
                    onChange={(e) => {
                      setRole({ ...role, role: e.target.value });
                      setError("");
                    }}
                  />
                </div>
                {!isAllStoreSelected && (
                  <div className={` ${styles.check_box} mt-4`}>
                    <input
                      name="permissions"
                      type="checkbox"
                      className="hidden"
                      id={`createGlobalRole`}
                      placeholder={"Accountant, Manager, Trainers"}
                      onChange={handleCheckBox}
                      value={role?.isGlobal}
                      defaultChecked={role?.isGlobal}
                    />
                    <label htmlFor={`createGlobalRole`}>
                      Create this role for all the stores that associate with
                      same company
                    </label>
                  </div>
                )}

                <button
                  className={
                    "bg-green-500 text-white rounded mt-5 w-100 w-full px-5 py-3 uppercase tracking-wider"
                  }
                >
                  Duplicate
                </button>
              </form>
              {error && <p className={`text-red-500 my-3`}>{error}</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 backdrop-blur-sm bg-white/30" />
    </div>
  );
}
