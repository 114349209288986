import React from "react";
import { Route } from "react-router-dom";
import Dashboard from "../Page/Company/dashboard";
import Withdrawals from "../Page/Company/Transactions/withdrawals";
import Users from "../Page/Company/Users/Users";
import Customers from "../Page/Company/Customer/Customers";
import AllTerminals from "../Page/Company/Users/AllTerminals";
import Registration from "../Page/Company/Registration";
import UserPermission from "../Page/Company/Permission/userPermission";
import Roles from "../Page/Company/Roles/Roles";
import TransactionDetails from "../Page/Company/TransactionDetails";
import ChargebackDetails from "../Page/Company/ChargebackDetails";
import WithdrawalDetails from "../Page/Company/WithdrawalDetails";
import OverPaidDetails from "../Page/Company/OverPaidDetails";
import CustomerDetails from "../Page/Company/CustomerDetails";
import TerminalDetails from "../Page/Company/TerminalDetails";
import Refunds from "../Page/Company/Transactions/Refunds";
import Chargeback from "../Page/Company/Transactions/Chargeback";
import PrivetRoute from "./ProtectedRoute";
import UserDetails from "../Page/Company/UserDetails";
import TerminalEdit from "../Page/Company/Users/TerminalEdit";
import EditUser from "../Page/Company/Users/UserEdit";
import PasswordChange from "../Components/Company/Settings/PasswordChange";
import CustomReporting from "../Page/Company/Reporting/CustomReporting";
import CompanyContactless from "../Page/Company/Transactions/CompanyContactless";
import Terminal from "../Page/Company/Registration/Terminal";
import BankStatement from "../Page/Company/BankStatement/BankStatement"; // import BankStatementView from "../Page/Company/BankStatement/BankStatementView";
import BankStatementView from "../Page/Company/BankStatement/BankStatementView";
const CompanyRoutes = [
      <Route
        path={`/dashboard`}
        element={
          <PrivetRoute
            permission={["show_analytics", "show_dashboard_transactions"]}
          >
            <Dashboard />
          </PrivetRoute>
        }
        key="dashboard"
      />,
      <Route
        path={`/contactless`}
        key="contactless"
        element={
          <PrivetRoute
            permission={[
              "view_payment_dashboard",
              "view_all_store_transactions",
              "view_dashboard",
            ]}
          >
            <CompanyContactless
              permission={[
                "view_payment_dashboard",
                "view_all_store_transactions",
                "view_dashboard",
              ]}
            />
          </PrivetRoute>
        }
      />,
      <Route
        key={"withdrawals"}
        path="/withdrawals"
        element={
          <PrivetRoute permission={["view_payouts"]}>
            <Withdrawals permission={["view_payouts"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"withdrawalDetails"}
        path="/withdrawal/:id"
        element={
          <PrivetRoute permission={["view_payout_details"]}>
            <WithdrawalDetails permission={["view_payout_details"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"overpaidDetails"}
        path="/overpaid/:id"
        element={
          <PrivetRoute permission={["view_payout_details"]}>
            <OverPaidDetails permission={["view_payout_details"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"refunds"}
        path={`/refunds`}
        element={
          <PrivetRoute permission={["view_refund"]}>
            {" "}
            <Refunds permission={["view_refund"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"chargebacks"}
        path={`/chargebacks`}
        element={
          <PrivetRoute permission={["view_chargeback"]}>
            {" "}
            <Chargeback permission={["view_chargeback"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"chargebackDetails"}
        path={`/chargeback/:id`}
        element={
          <PrivetRoute permission={["view_chargeback"]}>
            <ChargebackDetails permission={["view_chargeback"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"transactionDetails"}
        path={`/transaction/:id`}
        element={
          <PrivetRoute
            permission={[
              "view_payment_dashboard",
              "view_all_store_transactions",
              "view_dashboard",
            ]}
          >
            <TransactionDetails
              permission={[
                "view_payment_dashboard",
                "view_all_store_transactions",
                "view_dashboard",
              ]}
            />
          </PrivetRoute>
        }
      />,
      <Route
        key={"users"}
        path={`/users`}
        element={
          <PrivetRoute permission={["view_people"]}>
            {" "}
            <Users permission={["view_people"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"userDetails"}
        path={`/user/:id`}
        element={
          <PrivetRoute permission={["view_people"]}>
            <UserDetails permission={["view_people"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"register"}
        path={`/register/user`}
        element={
          <PrivetRoute permission={["create_people"]}>
            {" "}
            <Registration permission={["create_people"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"editUser"}
        path={`/user/:id/edit`}
        element={
          <PrivetRoute permission={["update_people"]}>
            {" "}
            <EditUser permission={["update_people"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"terminal"}
        path="/register/terminal"
        element={
          <PrivetRoute permission={["create_terminal"]}>
            {" "}
            <Terminal permission={["create_terminal"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"terminalEdit"}
        path={`/terminal/:id/edit`}
        element={
          <PrivetRoute permission={["create_terminal"]}>
            {" "}
            <TerminalEdit permission={["create_terminal"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"roles"}
        path={`/roles`}
        element={
          <PrivetRoute permission={["view_role"]}>
            {" "}
            <Roles permission={["view_role"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"customers"}
        path={`/customers`}
        element={
          <PrivetRoute permission={["view_patrons", "view_customer"]}>
            {" "}
            <Customers permission={["view_patrons", "view_customer"]} />{" "}
          </PrivetRoute>
        }
      />,
      <Route
        key={"customerDetails"}
        path={`/customer/:id`}
        element={
          <PrivetRoute permission={["view_patron_details"]}>
            {" "}
            <CustomerDetails permission={["view_patron_details"]} />{" "}
          </PrivetRoute>
        }
      />,
      <Route
        key={"permission"}
        path={`/permission/:id`}
        element={
          <PrivetRoute permission={["create_role", "view_role", "update_role"]}>
            {" "}
            <UserPermission
              permission={["create_role", "view_role", "update_role"]}
            />{" "}
          </PrivetRoute>
        }
      />,
      <Route
        key={"allTerminals"}
        path={`/all-terminals`}
        element={
          <PrivetRoute permission={["view_terminal"]}>
            <AllTerminals permission={["view_terminal"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"terminalDetails"}
        path={`/terminal/:id`}
        element={
          <PrivetRoute permission={["view_terminal"]}>
            {" "}
            <TerminalDetails permission={["view_terminal"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"userDetails"}
        path={`/associate/:id`}
        element={
          <PrivetRoute permission={["view_people"]}>
            {" "}
            <UserDetails permission={["view_people"]} />
          </PrivetRoute>
        }
      />,
      <Route path={`/change-password`} element={<PasswordChange />} key={`changePassword`} />,
      <Route
        key={"reporting"}
        path="/reporting"
        element={
          <PrivetRoute permission={["view_people"]}>
            {" "}
            <CustomReporting permission={["view_people"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"bank-statement"}
        path="/bank-statement"
        element={
          <PrivetRoute permission={["view_people"]}>
            {" "}
            <BankStatement permission={["view_people"]} />
          </PrivetRoute>
        }
      />,
      <Route
        key={"bank-statement-view"}
        path="/bank-statement/:store_id/:id"
        element={
          <PrivetRoute permission={["view_people"]}>
            {" "}
            <BankStatementView permission={["view_people"]} />
          </PrivetRoute>
        }
      />
]

export default CompanyRoutes;
