import React, { useState } from "react";
import styles from "../Scss/Admin.module.scss";
import { useAuth } from "../../Context/AuthContext";
import { isEmpty } from "lodash";
import Axios from "../../Config/AxiosInstance";
import { useNavigate } from "react-router-dom";

export default function CreateRoleModal({
  isNew,
  onClose,
  data,
  storeId,
  editData,
  isAllSelected,
}) {
  const [error, setError] = useState(false);
  const [role, setRole] = useState({
    isGlobal: true, //isNew != true ? editData.isGlobal : false,
    role: !isNew ? editData.role_name : "",
    reporting: !isNew ? editData.reporting : "",
  });
  const route = useNavigate();
  const { token } = useAuth();
  const modalIsClose = () => {
    onClose(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNew && !isEmpty(role.role)) {
      Axios(token)
        .post(
          "/create/role",
          {
            role: role.role,
            isGlobal: !!(isAllSelected || role.isGlobal),
            stores: storeId,
            reporting: role.reporting,
          },
          {
            headers: { Authorization: "Bearer " + token },
          },
        )
        .then((res) => {
          let roleData = {
            id: res.data.response.id,
            external_id: res.data.response.external_id,
            date: res.data.response.createdAt,
            role: res.data.response.name,
          };
          if (data) {
            data(roleData);
            route(`/permission/${roleData.external_id}`);
          }
          if (onClose) onClose(false);
        })
        .catch((err) => {
          console.log(err.message);
          setError(err.response.data.message);
        });
    } else {
      let roleData = {
        role: role.role,
        role_id: editData.external_id,
        isGlobal: !!(isAllSelected || role.isGlobal),
        stores: storeId,
        reporting: role.reporting,
      };
      Axios(token)
        .post("/update/role", roleData)
        .then((res) => {
          if (res.data.response === "Success") {
            if (data) {
              route(`/permission/${roleData.external_id}`);
              data(roleData);
              onClose(false);
            }
          }
        })
        .catch((err) => {
          console.log(e.message);
          setError(err.response.data.message);
        });
    }
  };
  return (
    <div>
      <div className="fixed inset-0 z-[2147483649] flex items-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="relative mx-auto my-6 w-11/12 md:w-2/4 lg:w-2/6 xl:w-96">
          <button
            onClick={modalIsClose}
            className="absolute z-10 text-gray-400 top-3 right-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-2xl outline-none focus:outline-none">
            <div className="relative px-6 pt-6">
              <div className="flex items-center justify-between border-b">
                <div>
                  <h5 className={`text-lg font-semibold text-gray-700`}>
                    {!isNew ? "Update Role" : "Create Role"}
                  </h5>
                </div>
              </div>
            </div>
            <div className="items-center px-6 pb-8 mt-5 border-solid rounded-b border-blueGray-200">
              <form
                className={"w-100"}
                id="create-role-form"
                onSubmit={handleSubmit}
              >
                <div className="relative">
                  <input
                    type="text"
                    placeholder={`Enter role name`}
                    className={`${styles.form_control} pr-20`}
                    name={"role"}
                    value={role.role}
                    onChange={(e) => {
                      setRole({ ...role, role: e.target.value });
                      setError("");
                    }}
                  />
                </div>
                <select
                  name="reporting"
                  onChange={(e) =>
                    setRole({ ...role, reporting: e.target.value })
                  }
                  id="roleForReporting"
                  className={`${styles.form_control} my-2 outline-none`}
                >
                  <option value="">Reporting</option>
                  <option value="associate">Employee</option>
                  <option value="dispatcher">Dispatcher</option>
                  <option value="drivers">Drivers</option>
                  <option value="">All</option>
                </select>

                <button
                  className={
                    "bg-green-500 text-white rounded mt-5 w-100 w-full px-5 py-3 uppercase tracking-wider"
                  }
                >
                  {!isNew ? "Update" : "Create"}
                </button>
              </form>
              {error && <p className={`text-red-500 my-3`}>{error}</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 backdrop-blur-sm bg-white/30" />
    </div>
  );
}
