import Pusher from "pusher-js";
import getPusherKey from "./getPusherKey";
const pusherSub = (channelString, event, cb1, cb2) => {
  const appKey = getPusherKey();
  Pusher.logToConsole = false;
  const pusher = new Pusher(appKey, {
    cluster: "ap2",
  });
  const channel = pusher?.subscribe(channelString);
  channel?.bind(event, function (data) {
    cb1();
    if (cb2) {
      cb2(data);
    }
  });
};

export default pusherSub;
