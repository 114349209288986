import { getAPIUrl } from "../Utils/Common";
import axios from "axios";

let URL = getAPIUrl();
axios.defaults.baseURL = URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

const Axios = (token = null) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axios.create({
    baseURL: URL,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      timeout: 1000,
      "Accept-Timezone": userTimeZone,
    },
    withCredentials: true,
  });
};

export default Axios;
