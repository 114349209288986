import Axios from "../Config/AxiosInstance";
import {getPermittedRoles, isPermittedRole} from "./userPermittedRoles";

export const getRoles = async (token, userRole, data) => {
  const response = await Axios(token).post(`/stores/roles`, data);
  const roles = response.data.response;

  if (!roles) {
    return null;
  }

  const permittedRoles = getPermittedRoles(userRole);

  return roles.filter((role) => isPermittedRole(permittedRoles, role.slug));
};
