import React, { Fragment, useEffect, useState } from "react";
import styles from "../../Components/Scss/Admin.module.scss";
import IconImage from "../../Public/admin-images/form-icon-image.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isEmpty, toNumber } from "lodash";
import CompanyLayout from "../../Components/Company/Layouts";
import { useAuth } from "../../Context/AuthContext";
import NoData from "../../Components/Global/widget/NoData";
import { HasPermission } from "../../Components/Global/Permission";
import Unauthorised from "../Global/Unauthorised";
import { currencyFormat } from "../../Utils/Common";
import Modal from "../../Components/Modal/modal";
import Loader from "../../Components/Global/widget/Loader";
import ChargebackCommentModal from "../../Components/Modal/ChargebackCommentModal/ChargebackCommentModal";
import convertToLocalTime from "../../Utils/timeZoneConverter";
import Axios from "../../Config/AxiosInstance";

export default function ChargebackDetails({ permission }) {
  const route = useNavigate();
  const { selectedStoreIds, token } = useAuth();
  const { pathname } = useLocation();
  const chargebackId = pathname.split("/").slice(-1)[0];

  const [data, setData] = useState();
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [chargebacks, setChargebacks] = useState([]);
  const getChargebackDetails = async () => {
    try {
      setLoading(true);
      const chargeback = await Axios(token).get(`/chargeback/${chargebackId}`, {
        headers: { Authorization: "Bearer " + token },
      });

      const chargebackResponse = chargeback.data?.response || {};
      const chargebackMainId = chargebackResponse?.details?.id;
      setChargebacks(chargebackResponse?.chargebacks || []);
      const evidence = await Axios(token).get(
        `/chargeback/getEvidence/${chargebackMainId}`,
        {
          headers: { Authorization: "Bearer " + token },
        },
      );

      setData({ ...chargebackResponse, evidences: evidence.data });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };

  const closeCommentModal = async () => {
    setIsCommentModalOpen(false);
    await getChargebackDetails();
  };

  const openCommentModal = () => {
    setIsCommentModalOpen(true);
  };

  const handleSelectStore = (val) => setCurrentStoreId(val);

  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);

  useEffect(() => {
    if (currentStoreId) {
      getChargebackDetails().then();
    }
  }, [currentStoreId, token]);

  const openTransactionDetails = (id) => {
    route(`/transaction/${id}`);
  };

  const getStatus = () => {
    const isAssumed =
      Boolean(data?.details.assumed) && data?.details.status === "lost";

    let status = data?.details?.chargeback_status?.toUpperCase();
    if (isAssumed) {
      status = "Assumed by LedgerGreen";
    } else if (data?.details.status === "expired") {
      status = "LOST";
    }

    const datestamp = isAssumed
      ? data?.details?.assumed
      : data?.details?.createdAt;
    const date = datestamp ? convertToLocalTime(datestamp).format("PP") : "";

    return `${status} - ${date}`;
  };

  return (
    <CompanyLayout onStoreChange={handleSelectStore}>
      {isCommentModalOpen && (
        <ChargebackCommentModal
          onClose={closeCommentModal}
          chargeback={data?.details}
        />
      )}

      {isErrorModalOpen && (
        <Modal
          CloseOnClick={() => setIsErrorModalOpen(false)}
          isModalOpen={isErrorModalOpen}
          showCancel={true}
          modalCallBack={() => setIsErrorModalOpen(false)}
          type={"Error"}
          message={error}
          zIndex={50}
        />
      )}
      {loading && <Loader />}
      <div>
        {data ? (
          <div className="grid grid-cols-1 md:grid-cols-12">
            {HasPermission(permission) ? (
              <>
                {!loading ? (
                  <div
                    className={` grid md:grid-cols-4 gap-x-7 xl:col-span-12 md:col-span-12`}
                  >
                    <div className="col-span-2">
                      <div className="items-center md:pt-8 pb-4 md:pb-8 md:py-5 mb-3 bg-white px-3 rounded-lg shadow-lg shadow-slate-100 bg-gradient">
                        <div className={`flex justify-between mt-1 md:mt-4`}>
                          <div className={`flex items-center`}>
                            <div
                              className={`lg:w-20 lg:h-20 w-11 md:w-14 h-11 md:h-14 lg:p-5 p-3 rounded-full bg-green-500`}
                            >
                              <img src={IconImage} alt="" />
                            </div>
                            <div className="pl-2 md:pl-5 ml-2 md:ml-5 border-l">
                              <p className="text-lg font-bold lg:text-3xl ">
                                {currencyFormat(
                                  toNumber(data?.details?.disputed_amount),
                                )}
                              </p>
                              <p className="text-sm font-semibold text-left text-grey-500">
                                {getStatus()}
                              </p>
                            </div>
                          </div>

                          {data?.details?.customer_name ? (
                            <div className="">
                              <div className="relative flex items-center pt-4 text-sm text-gray-400 mt-5">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                  />
                                </svg>

                                <Link
                                  to={`/customer/${data?.details?.customer_external_id}`}
                                >
                                  <span className="pl-2 text-sm font-semibold text-right capitalize text-green-500 mr-5">
                                    {data?.details?.customer_name?.toUpperCase() ||
                                      "-"}
                                  </span>
                                </Link>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {data?.evidences?.length > 0 && (
                        <div className="items-center mt-5 md:pt-8 pb-4 md:pb-8 md:py-5 mb-3 bg-white px-3 rounded-lg shadow-lg shadow-slate-100">
                          <div className="flex items-center justify-between pb-3 border-b text-lg font-semibold">
                            Evidence
                          </div>
                          <table className="w-full mt-3">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Comment</th>
                                <th>Files</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.evidences?.map((evidence) => (
                                <tr key={evidence.date}>
                                  <td>
                                    <div className="w-full flex justify-center mb-3">
                                      {convertToLocalTime(evidence.date).format(
                                        "PPP",
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="w-full flex justify-center mb-3">
                                      {evidence.comment}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="w-full mb-3">
                                      {evidence.files?.map((file) => (
                                        <div
                                          key={file.name}
                                          className="w-full flex justify-center"
                                        >
                                          <a
                                            href={file.url}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {file.name}
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}

                      <p className="pt-3 pb-1 text-lg font-semibold">
                        Timeline
                      </p>
                      <div className="border-t">
                        <ul className={styles.timeline_step}>
                          {!isEmpty(chargebacks) &&
                            chargebacks?.map((item, i) => (
                              <Fragment key={i}>
                                {[
                                  "evidence_required",
                                  "accepted",
                                  "lost",
                                  "expired",
                                  "evidence_under_review",
                                ].includes(item?.chargeback_status) ? (
                                  <li key={i} className="flex pt-3 pb-2">
                                    <>
                                      <div
                                        className={` text-red-500 p-1 mt-1 w-7 h-7 shadow rounded-full bg-white`}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="w-5 h-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                          />
                                        </svg>
                                      </div>
                                      <div className="ml-2 mr-6">
                                        <span className="text-sm font-semibold text-uppercase">
                                          Chargeback -{" "}
                                          {item?.chargeback_status?.toUpperCase()}
                                        </span>
                                        <span className="block text-gray-500 text-sm">
                                          {convertToLocalTime(
                                            item?.activity_date,
                                          ).format("PPP")}
                                        </span>
                                      </div>
                                    </>
                                  </li>
                                ) : (
                                  <li key={i} className="flex pt-3 pb-2">
                                    <>
                                      <div
                                        className={` text-green-500 p-1 mt-1 w-7 h-7 shadow rounded-full bg-white`}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="w-5 h-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                          />
                                        </svg>
                                      </div>
                                      <div className="ml-2 mr-6">
                                        <span className="text-sm font-semibold text-uppercase">
                                          Chargeback -{" "}
                                          {item?.chargeback_status?.toUpperCase()}
                                        </span>
                                        <span className="block text-gray-500 text-sm">
                                          {convertToLocalTime(
                                            item?.activity_date,
                                          ).format("PPP")}
                                        </span>
                                      </div>
                                    </>
                                  </li>
                                )}
                              </Fragment>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="p-3 md:p-6 py-2 md:py-4 rounded-lg bg-slate-100 bg-opacity-40">
                        <div className="flex items-center justify-between">
                          <p className="mb-3 text-lg font-semibold">
                            Chargeback Information
                          </p>
                          {data?.details?.status === "evidence_required" && (
                            <div className="flex items-center justify-between">
                              <button
                                onClick={openCommentModal}
                                className="px-3 mr-5 mb-3 text-sm font-semibold uppercase text-white py-3 bg-green-500 rounded bold-style hover:bg-green-700 duration-500 ease-in-out"
                              >
                                Add evidence
                              </button>
                            </div>
                          )}
                        </div>

                        <div className="p-2 md:px-0 text-gray-600 bg-white rounded">
                          <div className="grid items-center grid-cols-2 gap-5 md:px-5 py-1 text-sm ">
                            <div className="text-xs font-semibold tracking-wider text-gray-500 uppercase">
                              Store Name:
                            </div>
                            <div className="text-sm font-semibold text-right capitalize text-green-500">
                              {data?.details?.store_name ?? "-"}
                            </div>
                          </div>

                          {data?.details?.order_id ? (
                            <div className="grid items-center grid-cols-2 gap-5 md:px-5 py-1 text-sm ">
                              <div className="text-xs font-semibold tracking-wider text-gray-500 uppercase">
                                TRANSACTION ID:
                              </div>
                              <div className="text-sm font-semibold text-right capitalize text-green-500">
                                <button
                                  className={`font-medium tableBtn break-normal px-4 py-1 leading-5`}
                                  onClick={() =>
                                    openTransactionDetails(
                                      data?.details?.transaction_external_id ??
                                        "-",
                                    )
                                  }
                                >
                                  {data?.details?.order_id ?? "-"}
                                </button>
                              </div>
                            </div>
                          ) : null}

                          <div className="grid items-center grid-cols-2 gap-5 md:px-5 py-1 text-sm">
                            <div className="text-xs font-semibold tracking-wider text-gray-500 uppercase">
                              TOTAL PROCESSED AMOUNT:
                            </div>
                            <div className="text-sm font-semibold text-right">
                              {currencyFormat(
                                toNumber(data?.details?.order_amount),
                              )}
                            </div>
                          </div>

                          {data?.details?.card_type ? (
                            <div className="grid items-center grid-cols-2 gap-5 md:px-5 py-1 text-sm">
                              <div className="text-xs font-semibold tracking-wider text-gray-500 uppercase">
                                CARD TYPE:
                              </div>
                              <div className="text-sm font-semibold text-right text-green-500">
                                {data?.details?.card_type ?? "-"}
                              </div>
                            </div>
                          ) : null}

                          <div className="grid items-center grid-cols-2 gap-5 md:px-5 py-1 text-sm">
                            <div className="text-xs font-semibold tracking-wider text-gray-500 uppercase">
                              OUTCOME:
                            </div>
                            <div className="text-sm font-semibold text-right text-red-500">
                              {currencyFormat(
                                toNumber(data?.details?.disputed_amount),
                              )}
                            </div>
                          </div>

                          <div className="grid items-center grid-cols-2 gap-5 md:px-5 py-1 text-sm">
                            <div className="text-xs font-semibold tracking-wider text-gray-500 uppercase">
                              REASON:
                            </div>
                            <div className="text-sm font-semibold text-right text-green-500">
                              {data?.details?.chargeback_category?.toUpperCase()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={` grid md:grid-cols-4 gap-x-7 xl:col-span-12 md:col-span-12 animate-pulse`}
                  >
                    <div className="col-span-2 md:mt-5">
                      <div className="items-center px-8 py-5 mb-3 bg-white rounded-lg shadow-lg shadow-slate-100">
                        <div className="flex items-center justify-between pb-3 border-b">
                          <p className="w-1/4 h-4 mb-3 rounded bg-slate-200"></p>
                          <div className="px-10 py-4 mb-3 rounded bg-slate-200"></div>
                        </div>
                        <div
                          className={`flex justify-between items-center mt-4`}
                        >
                          <div className={`flex items-center`}>
                            <div
                              className={`lg:w-20 lg:h-20 w-14 h-14 lg:p-5 p-3 rounded-full bg-slate-200`}
                            ></div>
                            <div className="pl-2 md:pl-5 ml-2 md:ml-5 border-l">
                              <span className="inline-block h-4 rounded w-36 bg-slate-200"></span>{" "}
                              <br />
                              <span className="inline-block w-10 h-2 mt-1 rounded bg-slate-200"></span>
                            </div>
                          </div>

                          <div className="">
                            <span className="inline-block h-3 rounded w-36 bg-slate-200"></span>{" "}
                            <br />
                            <span className="inline-block w-10 h-2 mt-1 rounded bg-slate-200"></span>
                          </div>
                        </div>
                      </div>
                      <p className="inline-block h-3 mt-3 mb-2 rounded w-36 bg-slate-200"></p>
                      <div className="border-t">
                        <ul className={styles.timeline_step}>
                          <li className="flex flex-row items-center pt-4 pb-4">
                            <div className="w-8 h-8 p-1 rounded-full bg-slate-200"></div>

                            <div className="ml-2 mr-6">
                              <span className="inline-block h-3 rounded w-36 bg-slate-200"></span>{" "}
                              <br />
                              <span className="inline-block w-10 h-2 mt-1 rounded bg-slate-200"></span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="p-6 py-4 mt-5 rounded-lg bg-slate-100 bg-opacity-40 animate-pulse">
                        <div className="flex items-center justify-between">
                          <p className="w-2/4 h-4 mb-3 rounded bg-slate-300"></p>
                          <div className="px-10 py-4 mb-3 rounded bg-slate-300"></div>
                        </div>

                        <div className="p-5 text-gray-600 bg-white rounded">
                          {Array.from(Array(5), (e, i) => {
                            return (
                              <div className="space-y-3 mt-2" key={i}>
                                <div className="grid grid-cols-3 gap-4">
                                  <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div className="h-2 bg-slate-200 rounded"></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Unauthorised />
            )}
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </CompanyLayout>
  );
}
