import React, { useContext, useState } from "react";

const StandardCustomerContext = React.createContext([]);

export function useCustomer() {
  return useContext(StandardCustomerContext);
}

export function StandardCustomerContextProvider({ children }) {
  const [isOtpSent, setIsOtpSent] = useState(null);
  const [StoreInfo, setStoreInfo] = useState(null);
  const [IsPaid, setIsPaid] = useState(null);
  const [ContactLess, setContactLess] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [paymentType, setPaymentType] = useState("standard");
  const [activities, setActivities] = useState(null);
  const [saveCard, setSaveCard] = useState(null);
  const [pending, setPending] = useState(null);
  const [parentTranId, setParentTranId] = useState(null);
  const [signature, setSignature] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII",
  );
  const value = {
    isOtpSent,
    setIsOtpSent,
    ContactLess,
    setContactLess,
    StoreInfo,
    setStoreInfo,
    IsPaid,
    setIsPaid,
    customer,
    setCustomer,
    paymentType,
    setPaymentType,
    activities,
    setActivities,
    saveCard,
    setSaveCard,
    signature,
    setSignature,
    pending,
    setPending,
    parentTranId,
    setParentTranId,
  };
  return (
    <StandardCustomerContext.Provider value={value}>
      {children}
    </StandardCustomerContext.Provider>
  );
}
