import styles from "../../Scss/Admin.module.scss";
import Table from "../Table";
import React, { useEffect, useMemo, useState } from "react";
import _, { capitalize, isEmpty, sumBy, toNumber, toString } from "lodash";
import Filters from "./Filters";
import { useNavigate } from "react-router-dom";
import download from "downloadjs";
import { currencyFormat } from "../../../Utils/Common";
import Loader from "../widget/Loader";
import Axios from "../../../Config/AxiosInstance";
import pusherSub from "../../../Utils/Pusher";
import convertToLocalTime from "../../../Utils/timeZoneConverter";

const chargebacksStatuses = [
  { id: "lost", label: "Lost" },
  { id: "own", label: "Own" },
  { id: "pending", label: "Pending" },
];

export default function Transaction({
  selectedStoreId,
  userInfo,
  token,
  selectedDateList,
  onFilterChange,
}) {
  const route = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [transactionFor, setTransactionFor] = useState("all");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState();
  const [to, setTo] = useState(selectedDateList[0]);
  const [from, setFrom] = useState(selectedDateList[1]);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [currentMenuItem, setCurrentMenuItem] = useState("payments");
  const [chargebacksStatus, setChargebacksStatus] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState("");
  const [prevSortCol, setPrevSortCol] = useState("");
  const [sortCount, setSortCount] = useState(0);
  const [blinkingOrderID, setBlinkingOrderID] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const conditionalRowStyles = [
    {
      when: (row) => row.otherTransactions,
      style: {
        backgroundColor: "rgba(239,68,68,0.09)",
      },
    },
    {
      when: (row) => row.orderId === blinkingOrderID,
      style: {
        animation: "blinking 2s linear",
      },
    },
  ];
  const blinkingStyle = `
  @keyframes blinking {
    0% { background-color: rgba(0, 255, 0, 0.09); }
    50% { background-color: transparent; }
    100% { background-color: rgba(0, 255, 0, 0.09); }
  }
`;

  const paymentsTableColumns = [
    {
      name: "Date",
      selector: (row) => row.date,
      width: "150px",
      right: false,
      sortable: true,
    },
    {
      name: "Employee",
      cell: (row) => [
        <button
          key={row.associate_external_id + 1}
          className={`text-gray-500 px-3 py-1 hover:font-bold hover:text-green-500 leading-5 rounded-full mr-3`}
          onClick={() => viewSalesAssociate(row)}
          id={"" + row.associate_external_id + 1}
        >
          {row.associate}
        </button>,
      ],
      grow: 2,
      sortable: true,
    },
    {
      name: "Customer",
      grow: 2,
      cell: (row) => [
        <button
          key={row.customer_external_id}
          className={`text-gray-500 px-3 py-1 hover:font-bold hover:text-green-500 leading-5 rounded-full capitalize mr-3`}
          onClick={() => viewCustomer(row.customer_external_id)}
          id={row.customer_external_id + 1}
        >
          {row.customerName}
        </button>,
      ],
      sortable: true,
    },
    {
      name: "Terminal Name",
      selector: (row) => row.terminalName,
      grow: 2,
    },
    {
      name: "Sales Amount",
      cell: (row) => [
        <button
          key={row.external_id}
          className={`font-medium tableBtn break-normal rounded-full px-4 py-1 leading-5 mr-3`}
          onClick={() => rowClickHandler(row.external_id)}
          id={row.external_id + 1}
        >
          {row.salesAmount}
        </button>,
      ],
      ignoreRowClick: true,
      button: true,
      sortable: true,
    },
    {
      name: "Tips",
      selector: (row) => row.tip,
    },
    {
      name: "Sales + Tips",
      selector: (row) => row.salesTips,
    },

    {
      name: "Type",
      selector: (row) => _.capitalize(row.transactionType),
    },
  ];

  const sdkTableColumns = [
    {
      name: "Date",
      selector: (row) => row.date,
      width: "150px",
      right: false,
      sortable: true,
    },
    {
      name: "Customer",
      grow: 2,
      cell: (row) => [
        <button
          key={row.customer_external_id}
          className={`text-gray-500 px-3 py-1 hover:font-bold hover:text-green-500 leading-5 rounded-full capitalize mr-3`}
          onClick={() => viewCustomer(row.customer_external_id)}
          id={row.customer_external_id + 1}
        >
          {row.customerName}
        </button>,
      ],
      sortable: true,
    },
    {
      name: "Order ID",
      selector: (row) => row.orderId,
      grow: 2,
    },
    {
      name: "Terminal Name",
      selector: (row) => row.terminalName,
      grow: 2,
    },
    {
      name: "Sales Amount",
      cell: (row) => [
        <button
          key={row.external_id}
          className={`font-medium tableBtn break-normal rounded-full px-4 py-1 leading-5 mr-3`}
          onClick={() => rowClickHandler(row.external_id)}
          id={row.external_id + 1}
        >
          {row.salesAmount}
        </button>,
      ],
      ignoreRowClick: true,
      button: true,
      sortable: true,
    },
    {
      name: "Cashout Amount",
      selector: (row) => row?.cashoutAmount,
    },
    {
      name: "Tips",
      selector: (row) => row.tip,
    },
    {
      name: "Sales + Tips",
      selector: (row) => row.salesTips,
    },
    {
      name: "Total Processed",
      cell: (row) => [
        <button
          key={row.external_id}
          className={` tableBtn break-normal font-medium rounded-full px-4 py-1 leading-5 mr-3`}
          onClick={() => rowClickHandler(row.external_id)}
          id={row.external_id + 1}
        >
          {row.totalAmount}
        </button>,
      ],
      ignoreRowClick: true,
      button: true,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.transactionType,
    },
  ];

  const cashoutTableColumns = [
    {
      name: "Date",
      selector: (row) => row.date,
      width: "150px",
      right: false,
      sortable: true,
    },
    {
      name: "Employee",
      cell: (row) => [
        <button
          key={row.associate_external_id + 1}
          className={`text-gray-500 px-3 py-1 hover:font-bold hover:text-green-500 leading-5 rounded-full mr-3`}
          onClick={() => viewSalesAssociate(row)}
          id={"" + row.associate_external_id + 1}
        >
          {row.associate}
        </button>,
      ],
      grow: 2,
      sortable: true,
    },
    {
      name: "Customer",
      grow: 2,
      cell: (row) => [
        <button
          key={row.customer_external_id}
          className={`text-gray-500 px-3 py-1 hover:font-bold hover:text-green-500 leading-5 rounded-full capitalize mr-3`}
          onClick={() => viewCustomer(row.customer_external_id)}
          id={row.customer_external_id + 1}
        >
          {row.customerName}
        </button>,
      ],
      sortable: true,
    },
    {
      name: "Transaction ID",
      selector: (row) => row.orderId,
      grow: 2,
    },
    {
      name: "Terminal Name",
      selector: (row) => row.terminalName,
      grow: 2,
    },
    {
      name: "Total Cashout Amount",
      cell: (row) => [
        <button
          key={row.external_id}
          className={` tableBtn break-normal font-medium rounded-full px-4 py-1 leading-5 mr-3`}
          onClick={() => rowClickHandler(row.external_id)}
          id={row.external_id + 1}
        >
          {row.totalAmount}
        </button>,
      ],
      ignoreRowClick: true,
      button: true,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.transactionType,
    },
  ];

  const viewSalesAssociate = (val) => {
    if (val) {
      route(`/user/${val.associate_external_id}`);
    }
  };

  const viewCustomer = (val) => {
    route(`/customer/${val}`);
  };

  const filterData = (data) => {
    let transactionArray = [];
    if (data) {
      const records = data.records;
      if (records) {
        records.map((filter, index) => {
          const transaction = filter;
          const totalReturns = transaction?.OtherTransactions
            ? sumBy(transaction?.other_transactions, (o) => {
                return o.status === "succeeded" ? o.amount : 0;
              })
            : 0;
          transactionArray.push({
            serialNo: index + 1,
            date: convertToLocalTime(transaction?.createdAt, "PPp"),
            transactionType: transaction.payment_type,
            customerName: capitalize(
              transaction.payment_type === "sdk"
                ? transaction?.sdk_customer
                : transaction?.customer_fullname,
            ),
            store: capitalize(transaction?.store_name),
            associate: `${
              filter.user_firstname ? capitalize(filter.user_firstname) : ""
            } ${filter.user_lastname ? capitalize(filter.user_lastname) : ""}`,
            terminalName: transaction?.terminal_name || "No Terminal",
            orderId: transaction.order_id,
            external_id: transaction.transactions_external_id,
            external_order_id: transaction.external_order_id || "-",
            customerFees: transaction?.convenience_charge,
            salesAmount: `${currencyFormat(toNumber(transaction?.amount))}`,
            cashoutAmount:
              `${currencyFormat(toNumber(transaction?.cashout_amount))}` ||
              "$0.00",
            tip: `${currencyFormat(toNumber(transaction?.tip))}`,
            salesTips: `${currencyFormat(
              toNumber(
                toNumber(transaction?.amount) + toNumber(transaction?.tip),
              ),
            )}`,
            salesFees: `${currencyFormat(
              toNumber(
                toNumber(transaction?.amount) +
                  toNumber(transaction?.convenience_charge),
              ),
            )}`,
            totalAmount: `${currencyFormat(
              toNumber(transaction?.order_amount),
            )}`,
            associate_external_id: filter.user_externalid,
            isGlobalAssociate: true,
            customer_external_id: transaction?.customer_externalid,
            otherTransactions:
              !isEmpty(transaction.other_transactions) &&
              transaction.other_transactions.filter(
                (e) => e.status === "succeeded",
              ).length > 0,
            totalReturns: totalReturns,
            balance: `${currencyFormat(
              toNumber(transaction?.order_amount) - toNumber(totalReturns),
            )}`,
          });
        });
      }
      setTransactions(transactionArray);
      setTotalRows(data?.totalItems);
    }
  };

  const getTransactions = async () => {
    try {
      setLoading(true);
      const data = await Axios(token).post(`/dashboard/transactions`, {
        stores: selectedStoreId,
        s: search,
        days: "",
        from,
        to,
        transactionFor: transactionFor,
        page: currentPage,
        size: perPage,
        type: "store",
        sort,
        sortCount,
      });
      setTransactions([]);
      setTotalRows(null);
      if (data?.data?.response) filterData(data.data.response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    pusherSub(
      toString(userInfo?.company_id),
      "transaction",
      () => {
        // cb1 logic
        getTransactions().then();
      },
      (data) => {
        setBlinkingOrderID(data?.key);
      },
    );
  }, []);

  useEffect(() => {
    if (token && selectedStoreId) {
      switch (currentMenuItem) {
        case "payments":
          getTransactions().then();
          break;
        case "cashout":
          getTransactions().then();
          break;
        case "sdk":
          getSdkTransactions().then();
          break;
      }
    }
  }, [
    token,
    search,
    currentPage,
    selectedStoreId,
    currentMenuItem,
    from,
    to,
    chargebacksStatus,
    perPage,
    sort,
    sortCount,
  ]);

  const getSdkTransactions = async () => {
    try {
      setLoading(true);
      const data = await Axios(token).post(`/dashboard/sdktransactions`, {
        stores: selectedStoreId,
        s: search,
        days: "",
        from,
        to,
        transactionFor: transactionFor,
        page: currentPage,
        size: perPage,
        type: "store",
        sort,
        sortCount,
      });
      setTransactions([]);
      setTotalRows(null);
      if (data?.data?.response) filterData(data.data.response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const downloadTransactionsReport = async () => {
    try {
      setDownloadLoading(true);
      const data = await Axios(token).post(
        "/download/transaction/report",
        {
          stores: selectedStoreId,
          s: search,
          days: "",
          from,
          to,
          transactionFor,
          page: currentPage,
          size: perPage,
          type: "store",
        },
        {
          responseType: "blob",
        },
      );
      setDownloadLoading(false);
      download(
        data.data,
        `${
          transactionFor === "cashout" ? "Cashout " : ""
        }Payments transactions reports ${convertToLocalTime(
          new Date(),
          `MMMM Do YYYY`,
        )}`,
      );
    } catch (e) {
      setDownloadLoading(false);
    }
  };

  const handleDownload = async () => {
    await downloadTransactionsReport();
  };

  const handleSearchSubmit = async (val) => {
    if (val) {
      setSearch(val);
    }
  };

  const rowClickHandler = (val) => {
    route(`/transaction/${val}`);
  };

  const handleOnPageChange = async (val) => {
    setCurrentPage(val);
  };

  const handleDateChange = async (val) => {
    setFrom(convertToLocalTime(val[0], "YYYY-MM-DD"));
    setTo(convertToLocalTime(val[1], "YYYY-MM-DD"));
    if (onFilterChange) {
      onFilterChange(val);
    }
  };

  const paymentMenuItems = useMemo(() => {
    const items = [
      { name: "Payments", link: "", key: "payments" },
      { name: "Contactless", link: "/contactless", key: "contactless" },
      // { name: "Cashout", link: "", key: "cashout" },
      // { name: "E-com", link: "", key: "sdk" },
    ];
    if (userInfo.role !== "driver-smoakland") {
      items.push({ name: "Returns", link: "/refunds", key: "refunds" });
      items.push({
        name: "Chargebacks",
        link: "/chargebacks",
        key: "chargebacks",
        count: 2,
      });
    }
    return items;
  });

  const handleMenuClick = (key) => {
    setCurrentMenuItem(key);
    if (key === "payments") {
      setTransactionFor("all");
    } else if (key === "cashout") {
      setTransactionFor("cashout");
    }
  };

  const handleChargebacksStatusChange = (status) => {
    setChargebacksStatus(status);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(currentPage);
  };

  const handleColumnSort = (column, sortDirection) => {
    setSort("");
    let colName = "";
    if (column === "Date") {
      colName = "createdAt";
    } else if (column === "Sales Amount") {
      colName = "amount";
    } else if (column === "Employee") {
      colName = "user_firstname";
    } else if (column === "Customer") {
      colName = "customer_fullname";
    } else if (column === "Total Processed") {
      colName = "order_amount";
    }
    if (prevSortCol === colName) {
      if (sortCount < 1) {
        setSortCount(sortCount + 1);
        setSort({ name: colName, order: sortDirection });
      } else {
        setSort("");
        setSortCount(0);
      }
    } else {
      setSortCount(0);
      setSort({ name: colName, order: sortDirection });
    }
    setPrevSortCol(colName);
  };

  return (
    <>
      {downloadLoading && <Loader />}
      {/*<style>{blinkingStyle}</style>*/}
      <Filters
        isDashboard={true}
        isContactlessBtn={true}
        isUsers={true}
        download={false}
        downloadBtn={userInfo.role !== "driver-smoakland"}
        placeHolder={"Customer Name"}
        createdAt={new Date()}
        onDownload={handleDownload}
        paymentMenu={true}
        paymentMenuKey={paymentMenuItems}
        date={handleDateChange}
        onMenuClick={handleMenuClick}
        onSearchSubmit={handleSearchSubmit}
        onSearchReset={() => setSearch("")}
        downloadBtnDisable={
          !transactions.length &&
          ["payments", "cashout", "sdk"].includes(currentMenuItem)
        }
        currentMenuItem={currentMenuItem}
        chargebacksStatuses={
          currentMenuItem === "chargebacks" ? chargebacksStatuses : []
        }
        onChargebacksStatusChange={handleChargebacksStatusChange}
      />
      <div className={`${styles.table_ui_style} mt-4`}>
        {["payments", "cashout", "sdk"].includes(currentMenuItem) ? (
          <>
            <style>{blinkingStyle}</style>
            <Table
              data={transactions}
              columns={
                currentMenuItem === "cashout"
                  ? cashoutTableColumns
                  : currentMenuItem === "sdk"
                  ? sdkTableColumns
                  : paymentsTableColumns
              }
              perPage={perPage}
              totalRows={totalRows}
              pending={loading}
              showPointer={false}
              conditionalRowStyles={conditionalRowStyles}
              onPageChange={handleOnPageChange}
              handleSort={handleColumnSort}
              responsive={true}
              onRowPerPageChange={(currentRowsPerPage, currentPage) =>
                handleRowsPerPageChange(currentRowsPerPage, currentPage)
              }
            />
          </>
        ) : null}
      </div>
    </>
  );
}
