import React, { Fragment, useEffect, useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Listbox } from "@headlessui/react";
import { useAuth } from "../../../Context/AuthContext";
import convertToLocalTime from "../../../Utils/timeZoneConverter";

export default function DateRange({ createdAt, onChange }) {
  const {
    userInfo,
    setFilteredData,
    filteredData,
    selectedDateList,
    setSelectedDateList,
  } = useAuth();
  const [value, setValue] = useState([
    convertToLocalTime(selectedDateList[0]).format("yyyy-MM-dd"),
    convertToLocalTime(selectedDateList[1]).format("yyyy-MM-dd"),
  ]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [allTypes, setAllTypes] = useState();

  useEffect(() => {
    let data = [
      { name: "Today", value: 0 },
      { name: "Yesterday", value: 1 },
      { name: "Last 7 day", value: 7 },
      { name: "Last 4 weeks", value: 28 },
      { name: "Last 3 months", value: 90 },
      { name: "Last 12 months", value: 360 },
    ];
    setSelectedDate(filteredData || data[0]);
    setAllTypes(data);
  }, [createdAt, setSelectedDateList]);

  useEffect(() => {
    let data = [];
    if (setSelectedDateList) {
      setValue([
        convertToLocalTime(selectedDateList[0]).format("yyyy-MM-dd"),
        convertToLocalTime(selectedDateList[1]).format("yyyy-MM-dd"),
      ]);
      data = selectedDateList;
    } else if (createdAt) {
      let endData = convertToLocalTime(createdAt).format("yyyy-MM-dd");
      setValue([endData, new Date()]);
      data = [endData, new Date()];
    }
    if (setSelectedDateList || createdAt) {
      onChange(data);
    }
  }, [setSelectedDateList]);

  const handleOnListChange = (e) => {
    setSelectedDate(e);
    let calculateFromDate = convertToLocalTime(new Date())
      .subtract(e.value, "days")
      .format("yyyy-MM-dd");
    let endDate = convertToLocalTime(new Date()).format("yyyy-MM-dd");
    const newDateRange = [calculateFromDate, endDate];
    setValue(newDateRange);
    setSelectedDateList(newDateRange);
    setFilteredData(e);
    onChange(newDateRange);
  };

  const handleDateChange = (value) => {
    console.log(convertToLocalTime(value[0]).format("yyyy-MM-dd"));
    if (value?.length > 0) {
      const filterDate = [
        value?.length > 0
          ? convertToLocalTime(value[0]).format("yyyy-MM-dd")
          : convertToLocalTime(new Date()).format("yyyy-MM-dd"),
        value?.length > 1
          ? convertToLocalTime(value[1]).format("yyyy-MM-dd")
          : convertToLocalTime(new Date()).format("yyyy-MM-dd"),
      ];
      setValue(filterDate);
      setSelectedDateList(filterDate);
      onChange(filterDate);
    }
  };

  return (
    <>
      {createdAt && (
        <>
          <div>
            <Listbox value={selectedDate} onChange={handleOnListChange}>
              <div className="relative h-full">
                <Listbox.Button className="relative text-xs w-20 md:w-24 h-full py-1.5 pl-2 pr-5 text-left border border-r-0 bg-white rounded-l-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                  <span className="block text-xs text-gray-500 truncate">
                    {selectedDate?.name}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </span>
                </Listbox.Button>
                <Listbox.Options className="absolute z-10 px-2 py-2 overflow-auto text-xs bg-white rounded-lg shadow-lg w-36 focus:outline-none">
                  {allTypes?.map((date, dateIdx) => (
                    <Listbox.Option
                      key={dateIdx}
                      className={({ active }) =>
                        `${
                          active
                            ? "text-indigo-900 bg-indigo-100"
                            : "text-gray-500"
                        } cursor-default select-none relative py-1 leading-4 rounded pl-5 pr-2`
                      }
                      value={date}
                    >
                      {({ selectedDate, active }) => (
                        <>
                          <span
                            className={`${
                              selectedDate ? "font-medium" : "font-normal"
                            } block truncate`}
                          >
                            {date.name}
                          </span>
                          {selectedDate ? (
                            <span
                              className={`${
                                active ? "text-indigo-500" : "text-indigo-500"
                              } absolute inset-y-0 left-0 flex items-center pl-2`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
          {value && (
            <div className="relative text-xs">
              <DateRangePicker
                format="MMM dd,yyyy"
                className={`daterange_picker relative z-10 rounded-r-lg flex-2`}
                onChange={handleDateChange}
                minDate={
                  new Date(userInfo.createdAt ? userInfo.createdAt : createdAt)
                }
                maxDate={new Date()}
                showLeadingZeros={true}
              />
              <div
                className={`flex flex-nowrap text-gray-500 absolute top-3 left-7`}
              >
                <div className={`break-normal`}>
                  {convertToLocalTime(value[0]).format("PP")}
                </div>
                <div className="px-2">-</div>
                <div className={`break-normal`}>
                  {convertToLocalTime(value[1]).format("PP")}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
