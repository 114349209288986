import React from "react";
import { Link, useLocation } from "react-router-dom";
import Styles from "../../Scss/Admin.module.scss";
import { useAuth } from "../../../Context/AuthContext";
import { Menu, Transition } from "@headlessui/react";
import { HasPermission, ViewPermission } from "../../Global/Permission";

function NavBar() {
  const location = useLocation();
  const getLocation = location.pathname.split("/");
  const currentPath = getLocation[1];
  const { userInfo } = useAuth();
  const type = "";
  return (
    userInfo && (
      <nav className="items-center xl:space-x-3 xl:flex">
        {HasPermission(
          ViewPermission([
            "view_payment_dashboard",
            "view_all_store_transactions",
          ]),
        ) && (
          <div className="relative">
            <Link
              to={`${type}/dashboard`}
              className={`${Styles.nav_link} ${
                currentPath === "dashboard"
                  ? `text-green-500 bg-green-50`
                  : "text-gray-500 bg-transparent"
              }`}
            >
              <span className={`block text-sm font-semibold `}>
                Transactions
              </span>
            </Link>
          </div>
        )}

        {HasPermission(ViewPermission(["view_payouts"])) && (
          <div className="relative">
            <Link
              to={`${type}/withdrawals`}
              className={`${Styles.nav_link} ${
                currentPath === "withdrawals"
                  ? `text-green-500 bg-green-50`
                  : "text-gray-500 bg-transparent"
              }`}
            >
              <span className={`block text-sm font-semibold `}>Payouts</span>
            </Link>
          </div>
        )}

        {HasPermission(ViewPermission(["view_people", "view_terminal"])) && (
          <Menu as="div" className="relative text-left xl:inline-block">
            <Menu.Button
              className={`${Styles.nav_link} text-sm w-full flex items-center text-gray-500`}
            >
              Reporting
              <span className="pt-1 pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={3}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </Menu.Button>

            <Transition>
              <Menu.Items className="left-0 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md xl:mt-2 xl:shadow-lg xl:absolute focus:outline-none">
                <div className="px-4 xl:px-2 xl:py-2">
                  {/*{HasPermission(ViewPermission("view_terminal")) && (*/}
                  {/*  <Link*/}
                  {/*    to={`${type}/terminals`}*/}
                  {/*    className={`${Styles.nav_link} ${*/}
                  {/*      currentPath === "terminals"*/}
                  {/*        ? `text-green-500`*/}
                  {/*        : "text-gray-500"*/}
                  {/*    }`}*/}
                  {/*  >*/}
                  {/*    <span className={`block text-sm font-semibold `}>*/}
                  {/*      Terminals*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*)}*/}
                  {/*{HasPermission(ViewPermission("view_people")) && (*/}
                  {/*  <Link*/}
                  {/*    to={`${type}/associates`}*/}
                  {/*    className={`${Styles.nav_link} ${*/}
                  {/*      currentPath === "associates"*/}
                  {/*        ? `text-green-500`*/}
                  {/*        : "text-gray-500"*/}
                  {/*    }`}*/}
                  {/*  >*/}
                  {/*    <span className={`block text-sm font-semibold `}>*/}
                  {/*      Associate*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*)}*/}
                  {/*{HasPermission(ViewPermission("view_people")) && (*/}
                  {/*  <Link*/}
                  {/*    to={`${type}/drivers`}*/}
                  {/*    className={`${Styles.nav_link} ${*/}
                  {/*      currentPath === "drivers"*/}
                  {/*        ? `text-green-500`*/}
                  {/*        : "text-gray-500"*/}
                  {/*    }`}*/}
                  {/*  >*/}
                  {/*    <span className={`block text-sm font-semibold `}>*/}
                  {/*      Drivers*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*)}*/}
                  {/*{HasPermission(ViewPermission("view_people")) && (*/}
                  {/*  <Link*/}
                  {/*    to={`${type}/dispatchers`}*/}
                  {/*    className={`${Styles.nav_link} ${*/}
                  {/*      currentPath === "dispatchers"*/}
                  {/*        ? `text-green-500`*/}
                  {/*        : "text-gray-500"*/}
                  {/*    }`}*/}
                  {/*  >*/}
                  {/*    <span className={`block text-sm font-semibold `}>*/}
                  {/*      Dispatchers*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*)}*/}
                  {HasPermission(ViewPermission("view_people")) && (
                    <Link
                      to={`${type}/reporting`}
                      className={`${Styles.nav_link} ${
                        currentPath === "reporting"
                          ? `text-green-500`
                          : "text-gray-500"
                      }`}
                    >
                      <span className={`block text-sm font-semibold `}>
                        Employees
                      </span>
                    </Link>
                  )}
                  {/*{HasPermission(ViewPermission("view_people")) && (*/}
                  {/*  <Link*/}
                  {/*    to={`${type}/bank-statement`}*/}
                  {/*    className={`${Styles.nav_link} ${*/}
                  {/*      currentPath === "bank-statement"*/}
                  {/*        ? `text-green-500`*/}
                  {/*        : "text-gray-500"*/}
                  {/*    }`}*/}
                  {/*  >*/}
                  {/*    <span className={`block text-sm font-semibold `}>*/}
                  {/*      Statement*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*)}*/}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}

        {HasPermission(
          ViewPermission([
            "view_people",
            "view_role",
            "create_people",
            "create_terminal",
          ]),
        ) && (
          <Menu as="div" className="relative text-left xl:inline-block">
            <div>
              <Menu.Button
                className={`${Styles.nav_link} text-sm flex items-center text-gray-500 w-full`}
              >
                Users
                <span className="pt-1 pl-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </Menu.Button>
            </div>
            <Transition>
              <Menu.Items className="left-0 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md xl:mt-2 xl:shadow-lg xl:absolute focus:outline-none">
                <div className="px-4 xl:px-2 xl:py-2 ">
                  {HasPermission(ViewPermission("create_people")) && (
                    <Link
                      to={`${type}/register/user`}
                      className={`${Styles.nav_link} ${
                        getLocation[2] === "user"
                          ? `text-green-500`
                          : "text-gray-500"
                      }`}
                    >
                      <span className={`block text-sm font-semibold `}>
                        Register User
                      </span>
                    </Link>
                  )}

                  {HasPermission(ViewPermission(["view_people"])) && (
                    <Link
                      to={`${type}/users`}
                      className={`${Styles.nav_link} ${
                        currentPath === "users"
                          ? `text-green-500`
                          : "text-gray-500"
                      }`}
                    >
                      <span className={`block text-sm font-semibold`}>
                        Users
                      </span>
                    </Link>
                  )}
                  {HasPermission(ViewPermission("view_role")) && (
                    <Link
                      to={`${type}/roles`}
                      className={`${Styles.nav_link} ${
                        getLocation[2] === "terminal"
                          ? `text-green-500`
                          : "text-gray-500"
                      }`}
                    >
                      <span className={`block text-sm font-semibold `}>
                        Roles
                      </span>
                    </Link>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}

        {HasPermission(ViewPermission(["view_terminal"])) && (
          <div className="relative">
            <Link
              to={`${type}/all-terminals`}
              className={`${Styles.nav_link} ${
                currentPath === "all-terminals"
                  ? `text-green-500 bg-green-50`
                  : "text-gray-500 bg-transparent"
              }`}
            >
              <span className={`block text-sm font-semibold `}>Terminals</span>
            </Link>
          </div>
        )}
        {HasPermission(ViewPermission(["view_patrons"])) && (
          <div className="relative">
            <Link
              to={`${type}/customers`}
              className={`${Styles.nav_link} ${
                currentPath === "customers"
                  ? `text-green-500 bg-green-50`
                  : "text-gray-500"
              }`}
            >
              <span className={`block text-sm font-semibold `}>Customers</span>
            </Link>
          </div>
        )}
      </nav>
    )
  );
}

export default NavBar;
