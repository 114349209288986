import React, { useEffect, useState } from "react";
import styles from "../../Components/Scss/Admin.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Table from "../../Components/Global/Table";
import CompanyLayout from "../../Components/Company/Layouts";
import { useAuth } from "../../Context/AuthContext";
import NoData from "../../Components/Global/widget/NoData";
import Unauthorised from "../Global/Unauthorised";
import { HasPermission } from "../../Components/Global/Permission";
import { inputValidation } from "../../Utils/Validatior";
import { currencyFormat, roundUp } from "../../Utils/Common";
import Filters from "../../Components/Global/transaction/Filters";
import download from "downloadjs";
import Loader from "../../Components/Global/widget/Loader";
import { canUserOperateWithRole } from "../../api/userPermittedRoles";
import Axios from "../../Config/AxiosInstance";
import { toNumber } from "lodash";
import convertToLocalTime from "../../Utils/timeZoneConverter";

export default function UserDetails({ permission }) {
  const { userInfo, token, selectedStoreIds } = useAuth();
  const [hiddenChart, setHiddenChart] = useState(true);
  const [tips, setTips] = useState("00");
  const [refund, setRefund] = useState("00");
  const route = useNavigate();
  const [search, setSearch] = useState("");
  const [data, setData] = useState(true);
  const [noOfTransactions, setNoOfTransactions] = useState();
  const [salesAmount, setSalesAmount] = useState();
  const [salesPlusTips, setSalesPlusTips] = useState();
  const transactionFor = null;
  const [associateName, setAssociateName] = useState();
  const [userRole, setUserRole] = useState();
  const [transactions, setTransactions] = useState([]);
  const [resApiData, setResApiData] = useState(false);
  const location = useLocation();
  const getUserId = location.pathname.split("/").slice(-1);
  const navigate = useNavigate();
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [datePick, setDatePick] = useState();
  const [isBan, setIsBan] = useState(false);
  const [totalRows, setTotalRows] = useState();

  const perPage = 20;

  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);

  const conditionalRowStyles = [
    {
      when: (row) => toNumber(row.refund) > 0,
      style: {
        backgroundColor: "rgba(239,68,68,0.09)",
      },
    },
  ];

  useEffect(() => {
    if (token && currentStoreId) {
      setTransactions([]);
      Axios(token)
        .post(`/user/details/transaction/list/${getUserId}`, {
          stores: currentStoreId,
          s: search,
          days: "",
          from: from,
          to: to,
          transactionFor: transactionFor,
          page: currentPage,
          size: perPage,
          type: "store",
        })
        .then((response) => {
          let records = [];
          if (response.data.response.records) {
            const record = response.data.response.records;
            record.map((items, index) => {
              const _ = require("lodash");
              records.push({
                serialNo: index + 1,
                orderId: items.order_id,
                date: convertToLocalTime(items.createdAt).format("PPp"),
                orderAmount: roundUp(items?.order_amount || 0),
                salesAmount: roundUp(items?.amount || 0),
                tips: roundUp(items?.tip || 0),
                refundTip: roundUp(items?.totalRefundedTip || 0),
                salesAmountTips:
                  roundUp(items?.tip || 0) + roundUp(items?.amount || 0),
                totalAmountFees:
                  roundUp(items?.amount || 0) + roundUp(items?.fee || 0),
                customerName: _.capitalize(items?.CustomerName),
                customer_external_id: items?.customer_external_id,
                external_id: items?.transactions_external_id,
                refund: roundUp(items?.RefundAmount || 0),
              });
            });
            setTransactions(records);
            setTotalRows(response.data.response.totalItems);
          }
        });
    }
  }, [token, datePick, search, currentPage, currentStoreId]);

  useEffect(() => {
    setResApiData(false);
    Axios(token)
      .post(`/user/details/${getUserId}`, {
        stores: currentStoreId,
        s: search,
        days: "",
        from: from,
        to: to,
        transactionFor: transactionFor,
        type: "store",
      })
      .then((response) => {
        if (response?.data?.response) {
          setData(true);
          setResApiData(true);
          let resData = response.data.response;
          const salesAmount = roundUp(resData?.metabox?.salesAmount || 0);

          const totalRefund = roundUp(resData?.metabox?.totalrefund || 0);
          const totalTips = roundUp(resData?.metabox?.totaltips || 0);

          setIsBan(resData?.response?.is_ban);
          setRefund(roundUp(totalRefund));
          setTips(roundUp(totalTips));
          setNoOfTransactions(resData.metabox.noOfTransactions);

          setSalesAmount(roundUp(salesAmount));
          setSalesPlusTips(roundUp(salesAmount + totalTips));

          if (resData.response.Roles) {
            setUserRole(resData.response.Roles.name);
          }

          if (
            resData?.response?.UserProfile?.first_name ||
            resData?.response?.UserProfile?.last_name
          ) {
            setAssociateName(
              `${resData?.response?.UserProfile?.first_name} ${resData?.response?.UserProfile?.last_name}`,
            );
          } else {
            setAssociateName(resData?.response?.username);
          }

          if (resData?.metabox?.total_order_amount === 0) {
            setData(false);
            setResApiData(true);
          } else {
            setData(true);
            setResApiData(true);
          }
        }
      })
      .catch(() => {
        setData(false);
      });
  }, [token, datePick, search, currentStoreId]);

  const viewCustomer = (val) => {
    route(`/customer/${val}`);
  };
  const columns = [
    {
      name: "#",
      selector: (row) => row.serialNo,
      width: "50px",
    },
    {
      name: "Order ID",
      selector: (row) => row.orderId,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Customer Name",
      width: "200px",
      cell: (row) => [
        <button
          key={row.customer_external_id}
          className={`text-gray-500 px-3 py-1 hover:text-green-500 leading-5 hover:underline capitalize mr-3`}
          onClick={() => viewCustomer(row.customer_external_id)}
          id={row.customer_external_id + 1}
        >
          {row.customerName}
        </button>,
      ],
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "Sales Amount",
      cell: (row) => [
        <button
          key={row.external_id}
          style={{
            background: "rgba(5, 66, 120, 0.0509803922)",
            color: "rgb(34 197 94 )",
          }}
          className={`font-medium rounded-full px-4 py-1 leading-5 mr-3`}
          onClick={() => onRowClick(row)}
          id={row.external_id + 1}
        >
          {currencyFormat(row.salesAmount)}
        </button>,
      ],
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "Tip",
      selector: (row) => currencyFormat(row.tips),
      wrap: true,
    },
    {
      name: " Sales Amount + Tips",
      selector: (row) => currencyFormat(row.salesAmountTips),
      wrap: true,
    },
  ];
  const onRowClick = (val) => {
    navigate(`/transaction/${val.external_id}`);
  };
  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(
      convertToLocalTime(val[0])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTo(
      convertToLocalTime(val[1])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTransactions([]);
  };
  const handleSearchSubmit = async (val) => {
    if (val) {
      setSearch(val);
    }
    setTransactions([]);
  };
  const quarries = {
    stores: currentStoreId,
    from: from,
    to: to,
    userId: getUserId,
  };
  const handleDownload = async () => {
    try {
      setLoading(true);
      const data = await Axios(token).post(
        "/download/transaction/report/user/details",
        quarries,
        {
          responseType: "blob",
        },
      );
      setLoading(false);
      download(
        data.data,
        `User transactions reports ${convertToLocalTime(new Date()).format(
          "MMMM Do YYYY",
        )}`,
      );
    } catch (e) {
      setLoading(false);
    }
  };
  const HandelHiddenChart = (e) => {
    const hiddenChartVal = e.target.checked;
    setHiddenChart(hiddenChartVal);
  };
  const HandelChangeBan = (e) => {
    const banValue = e.target.checked;
    setIsBan(banValue);
    const value = {
      external_id: getUserId,
      user_type: "user",
      is_ban: banValue,
    };
    Axios(token)
      .post("/user/ban", value)
      .then(function () {})
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {HasPermission(permission) ? (
        <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
          {loading && <Loader />}
          <div>
            <div>
              {resApiData ? (
                <div className="px-3 md:px-5 pt-4 bg-slate-100 rounded-xl">
                  <div className="flex flex-wrap items-center justify-between pb-4">
                    <div>
                      <p className="text-xl md:text-3xl font-bold text-gray-700 capitalize w-full">
                        {associateName ? inputValidation(associateName) : ""}
                      </p>
                      <p className="mt-2 mb-1 text-sm font-semibold text-gray-500 ">
                        Registration Date & Time:{" "}
                        <span className="text-gray-700 ">
                          {convertToLocalTime(userInfo.createdAt).format("PPp")}
                        </span>
                      </p>
                    </div>

                    <div className="flex flex-wrap">
                      <div className="flex items-center mr-3">
                        <p className="pr-2 text-gray-600">
                          {hiddenChart ? "Disable" : "Enable"} Chart
                        </p>

                        <div className={`${styles.switch_box}`}>
                          <input
                            type="checkbox"
                            id="HiddenChartId"
                            defaultChecked={hiddenChart}
                            name="HiddenChartId"
                            onChange={(e) => HandelHiddenChart(e)}
                            className={` ${styles.input_switch}`}
                          />
                          <label htmlFor="HiddenChartId" className={``} />
                          <div className={` ${styles.switch_status_box}`}></div>
                        </div>
                      </div>
                      {HasPermission(["update_people"]) && (
                        <>
                          <Link
                            to={`/user/${getUserId}/edit`}
                            className={`inline-flex px-2 md:px-3 py-1.5 font-semibold md:py-2.5 text-sm leading-5 text-white transition-all bg-green-500 rounded hover:bg-green-700 hover:text-white ${
                              !canUserOperateWithRole(
                                userInfo.role,
                                userRole,
                              ) && "pointer-events-none bg-gray-300"
                            }`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={1.5}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                            <span className="inline-block pl-2">Edit User</span>
                          </Link>

                          <div className="flex items-center ml-4">
                            <p className="pr-2 text-gray-600">Ban</p>

                            <div className={`${styles.switch_box}`}>
                              <input
                                type="checkbox"
                                id="IsBan"
                                checked={isBan}
                                name="HiddenChartId"
                                onChange={(e) => HandelChangeBan(e)}
                                className={` ${styles.input_switch}`}
                              />
                              <label htmlFor="IsBan" className={``} />
                              <div
                                className={` ${styles.switch_status_box}`}
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="">
                    {hiddenChart && (
                      <div className="grid grid-cols-2 md:grid-cols-5 gap-3 pt-4 pb-4 border-t">
                        <div className="flex items-center p-3 bg-white rounded">
                          <div className="ml-3">
                            <p className="text-xs tracking-wide text-gray-500">
                              Sales Amount:
                            </p>
                            <p className="mt-1 text-base font-semibold text-gray-700">
                              {currencyFormat(salesAmount || 0)}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center p-3 bg-white rounded">
                          <div className="ml-3">
                            <p className="text-xs tracking-wide text-gray-500">
                              Tips:
                            </p>
                            <p className="mt-1 text-base font-semibold text-gray-700">
                              {tips ? tips : 0}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center p-3 bg-white rounded">
                          <div className="ml-3">
                            <p className="text-xs tracking-wide text-gray-500">
                              Sales Amount + Tips:
                            </p>
                            <p className="mt-1 text-base font-semibold text-gray-700">
                              {currencyFormat(salesPlusTips || 0)}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center p-3 bg-white rounded">
                          <div className="ml-3">
                            <p className="text-xs tracking-wide text-gray-500">
                              #Transactions:
                            </p>
                            <p className="mt-1 text-base font-semibold text-gray-700">
                              {noOfTransactions || 0}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center p-3 bg-white rounded">
                          <div className="ml-3">
                            <p className="text-xs tracking-wide text-gray-500">
                              Total Refund:
                            </p>
                            <p className="mt-1 text-base font-semibold text-gray-700">
                              {currencyFormat(refund || 0)}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="px-5 pt-6 bg-slate-100 rounded-xl animate-pulse">
                  <div className="flex items-center justify-between pb-4 border-b">
                    <div>
                      <p className="h-6 rounded bg-slate-300 w-36 "></p>
                      <p className="h-3 mt-2 rounded bg-slate-300 w-60"></p>
                    </div>
                    <div>
                      <div className="inline-flex px-10 py-4 rounded bg-slate-300"></div>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-3 pt-4 pb-4 border-t">
                    {Array.from(Array(6), (e, i) => {
                      return (
                        <div
                          key={i}
                          className="flex items-center p-3 rounded bg-white/75"
                        >
                          {/* <div className="w-12 h-12 p-2 bg-slate-200 rounded-xl"></div> */}
                          <div className="ml-3">
                            <p className="w-20 h-2 rounded bg-slate-200 "></p>
                            <p className="w-10 h-4 mt-2 rounded bg-slate-200"></p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <h5 className="mt-4 md:mt-8 mb-4 text-xl font-semibold text-gray-700">
                All Transaction
              </h5>

              <div>
                <Filters
                  date={handleDateChange}
                  downloadBtnDisable={!transactions.length}
                  onSearchSubmit={handleSearchSubmit}
                  placeHolder={"Search Customer"}
                  onSearchReset={() => setSearch("")}
                  downloadBtn={true}
                  onDownload={handleDownload}
                />
              </div>
              <div className={`${styles.table_ui_style} mt-5`}>
                {data ? (
                  <Table
                    data={transactions}
                    columns={columns}
                    showPointer={true}
                    onRowClick={onRowClick}
                    totalRows={totalRows}
                    onPageChange={(val) => setCurrentPage(val)}
                    conditionalRowStyles={conditionalRowStyles}
                  />
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </>
  );
}
