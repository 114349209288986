export const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#f1f5f9",
      color: "#535d6a",
      borderBottomWidth: "0",
      textTransform: "capitalize",
    },
  },
  head: {
    style: {
      position: "sticky",
      top: "0",
      zIndex: "999",
      borderRadius: "5px",
      overflow: "hidden",
      borderBottomWidth: "0",
    },
  },
  headCells: {
    style: {
      justifyContent: "center",
    },
  },
  rows: {
    style: {
      backgroundColor: "#fff",
      "&:not(:last-of-type)": {
        borderBottomWidth: "0",
      },
      "&:hover:not(:disabled)": {
        backgroundColor: "#f8fafc",
      },
    },
  },
  cells: {
    style: {
      color: "#817c8a",
      textAlign: "center",
      justifyContent: "space-evenly",
    },
  },
  pagination: {
    style: {
      paddingRight: "0",
    },
    pageButtonsStyle: {
      backgroundColor: "#eff1f9",
      borderRadius: "5px",
      marginLeft: "5px",
      height: "35px",
      width: "35px",
      padding: "5px",
      color: "#535662",
      "&:focus": {
        backgroundColor: "#eff1f9",
      },
      "&:hover:not(:disabled)": {
        backgroundColor: "#16a34a",
        color: "#ffffff",
        fill: "#ffffff",
      },
    },
  },
};
