import React, { useEffect, useState } from "react";

export default function StoreModal({
  show,
  data,
  onSelectStore,
  onStoreModalClose,
}) {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    setShowModal(show);
  }, [show]);
  const modalIsClose = (e) => {
    e.preventDefault();
    setShowModal(false);
    onStoreModalClose();
  };
  const onStoreClick = (id) => {
    onSelectStore(id);
  };
  return (
    showModal && (
      <>
        <div>
          <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div
              className={`relative mx-auto flex flex-col justify-center  lg:w-1/2 w-5/6 ${
                data.lenght > 10 ? "top-5" : ""
              }`}
            >
              <div className="relative flex flex-col w-full p-4 mb-5 bg-white border-0 rounded-lg shadow-2xl outline-none focus:outline-none overflow-auto">
                <div className="flex justify-between pb-2 mb-2 border-b">
                  <p className="text-base text-gray-600">All Accounts</p>
                  <button onClick={modalIsClose}>
                    <p className="text-gray-500 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </p>
                  </button>
                </div>
                <div className="">
                  <div
                    className={`${
                      data.length >= 2
                        ? "grid grid-cols-3 "
                        : "flex justify-center"
                    } gap-3`}
                  >
                    <div
                      key={0}
                      className={`rounded px-4 py-2 my-1 bg-slate-50 text-base cursor-pointer  col-span-3 md:col-span-1`}
                      onClick={() => onStoreClick("all")}
                    >
                      <p>All Accounts</p>
                    </div>
                    {data.map((value) => (
                      <div
                        key={value.id}
                        className={`rounded px-4 py-2 my-1 bg-slate-50 text-base cursor-pointer  col-span-3 md:col-span-1`}
                        onClick={() => onStoreClick(value.id)}
                      >
                        <p>{value.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 backdrop-blur-sm bg-white/30" />
        </div>
      </>
    )
  );
}
