import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import { makeDashboardUrl } from "../../../Utils/Common";
import { HasPermission, ViewPermission } from "../Permission";
import NavBar from "../../Company/Layouts/NavBar";
import {logout} from "../logout";

function ProfileDropdown(props) {
  const { userInfo } = useAuth();
  const isHeaderAssociate = props.data;

  return (
    <div className="">
      {userInfo && (
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={` ${
                  open ? "border-green-500" : ""
                } border-slate-300 bg-slate-50 text-gray-500 rounded-full focus:outline-none -mr-1`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-10 h-10"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clipRule="evenodd"
                  />
                </svg>
              </Popover.Button>

              <Transition as={Fragment}>
                <Popover.Panel className="absolute z-[99] mt-1 transform w-52 md:w-72 sm:w-56 h-screen lg:h-auto -right-[11px] sm:px-0 lg:max-w-3xl -top-2 ">
                  <div className="overflow-hidden rounded-lg shadow-lg h-full">
                    <div className="bg-white h-full">
                      <div className="px-3 m-1 py-2 bg-gray-100 rounded">
                        <Link
                          className={`flex`}
                          to={makeDashboardUrl(
                            userInfo.role,
                            userInfo.terminal
                              ? userInfo.terminal.terminalId
                              : null,
                            userInfo.permissions,
                            userInfo.user ? userInfo.user.ids : null,
                            userInfo
                          )}
                        >
                            <span className="text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </span>
                          <p className="text-sm text-green-600 font-semibold  ml-2">
                            {userInfo.name}
                          </p>
                        </Link>
                      </div>
                      <div
                        className={`${isHeaderAssociate ? "rounded" : ""} px-2`}
                      >
                        <Link
                          to={makeDashboardUrl(
                            userInfo.role,
                            userInfo.terminal
                              ? userInfo.terminal.terminalId
                              : null,
                            userInfo.permissions,
                            userInfo.user ? userInfo.user.ids : null,
                            userInfo
                          )}
                          className="flex items-center p-2 text-gray-500 rounded hover:text-green-600 hover:bg-gray-100"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </span>
                          <span className="pl-3 text-sm font-semibold">
                            Dashboard
                          </span>
                        </Link>
                        {HasPermission(
                          ViewPermission(["reset_people_password"])
                        ) && (
                          <Link
                            to={"/settings"}
                            className="flex items-center p-2 text-gray-500 rounded sm:hidden hover:text-green-600 hover:bg-gray-100"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </span>
                            <span className="pl-3 text-sm font-semibold">
                              {" "}
                              Settings
                            </span>
                          </Link>
                        )}

                        {userInfo.role !== "terminal" && (
                          <Link
                            to={`${
                              userInfo?.isFirstTime ||
                              userInfo?.resetPin ||
                              userInfo?.resetPassword
                                ? "/update/password"
                                : "/change-password"
                            }`}
                            className="flex items-center p-2 text-gray-500 rounded hover:text-green-600 hover:bg-gray-100"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </span>
                            <span className="pl-3 text-sm font-semibold">
                              Change Password
                            </span>
                          </Link>
                        )}
                      </div>
                      <div className="block xl:hidden">
                        <NavBar />
                      </div>
                      <button
                        onClick={logout}
                        className="flex items-center w-full py-2 px-4 text-gray-500 rounded hover:text-green-600 hover:bg-gray-100"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                          </svg>
                        </span>
                        <span className="pl-3 text-sm font-semibold">
                          {" "}
                          Logout{" "}
                        </span>
                      </button>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}
    </div>
  );
}

export default ProfileDropdown;
