import styles from "../../Scss/Admin.module.scss";
import Header from "./Header";
import React from "react";
import Footer from "./Footer";
import { useAuth } from "../../../Context/AuthContext";
import UnAuthenticated from "../../Global/widget/UnAuthenticated";

export default function CompanyLayout({
  children,
  onStoreChange,
  isthisTranstionDetailsPage,
}) {
  const { authorization } = useAuth();
  const handleOnStoreChange = (value) => {
    if (onStoreChange) {
      onStoreChange(value);
    }
  };

  return (
    <>
      <style>{`
        body, html {font-family: 'Open Sans', sans-serif;}
      `}</style>
      {!authorization ? (
        <UnAuthenticated />
      ) : (
        <div className={`${styles.lightGrey}`}>
          <div className="flex h-screen overflow-y-hidden">
            <div
              className={` ${styles.bg_style} flex flex-col flex-1 h-full overflow-hidden`}
            >
              <Header
                onStoreChange={handleOnStoreChange}
                isthisTranstionDetailsPage={isthisTranstionDetailsPage}
              />
              <main
                className={` ${styles.adminMain} flex-1 max-h-full py-6 px-3 lg:px-10 overflow-hidden overflow-y-scroll  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white`}
              >
                <div className="flex flex-col justify-between h-full md:pb-6 lg:items-center">
                  <div className="w-full">{children}</div>
                  <div className="w-full">
                    <Footer />
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
