import React from "react";

export const FormField = ({ label, error, hint, children }) => (
  <div className='mb-3'>
    <p className="pr-2 text-sm text-gray-600">{label}</p>
    <div className='mb-2'>
      {children}
    </div>
    {hint && <p className='text-gray-600 text-xs italic mb-2'>{hint}</p>}
    {error && <p className='text-red-500 text-xs italic'>{error}</p>}
  </div>
);
