import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "../Config/AxiosInstance";
import Loader from "../Components/Global/widget/Loader";
import { logout } from "../Components/Global/logout";
import convertToLocalTime from "../Utils/timeZoneConverter";

const AuthContext = createContext([]);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authorization, setAuthorization] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [store, setStore] = useState();
  const [isAssociateAuthenticated, setIsAssociateAuthenticated] = useState();
  const [currentAssociatePin, setCurrentAssociatePin] = useState();
  const [terminalInfo, setTerminalInfo] = useState({});
  const [terminalId, setTerminalId] = useState();
  const [storeId, setStoreId] = useState();
  const [companyId, setCompanyId] = useState();
  const [token, setToken] = useState("");
  const [createIsFormDisable, setCreateIsFormDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAllStoreSelected, setIsAllStoreSelected] = useState(false);
  const [isStoreChanged, setIsStoreChanged] = useState(false);
  const [previousSelectedStoreId, setPreviousSelectedStoreId] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedStoreIds, setSelectedStoreIds] = useState();
  const [inTerminalPermissions, setInTerminalPermissions] = useState([]);
  const [inTerminalInfo, setInTerminalInfo] = useState();
  const [mobilestorelogo, setMobilestorelogo] = useState();
  const [refundableToCard, setRefundableToCard] = useState();
  const [isCompanyUser, setIsCompanyUser] = useState(false);
  const [filteredData, setFilteredData] = useState({ name: "Today", value: 0 });
  const [selectedDateList, setSelectedDateList] = useState([
    convertToLocalTime(new Date()).format("YYYY-MM-DD"),
    convertToLocalTime(new Date()).format("YYYY-MM-DD"),
  ]);

  const location = useLocation();
  const navigate = useNavigate();
  const splitPath = location.pathname.split("/");

  useEffect(() => {
    if (!splitPath.includes("shadow")) {
      handleRefresh().catch((error) => {
        console.error("Unexpected error during refresh:", error);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (username, password, url) => {
    const data = { username, password };
    return new Promise((resolve, reject) => {
      Axios()
        .post(url, data)
        .then(async (res) => {
          if (res.data?.response?.token) {
            try {
              const data = await storeUserInfo(res.data.response.token);
              setLoading(false);
              resolve(data);
            } catch (errors) {
              handleLoginError(errors, reject);
            }
          }
        })
        .catch((errors) => {
          handleLoginError(errors, reject);
        });
    });
  };

  const handleLoginError = (errors, reject) => {
    setIsAuthenticated(false);
    const errorMessage = errors.response?.data?.message || errors.message;
    reject(errorMessage);
  };

  const storeUserInfo = async (token) => {
    return new Promise((resolve, reject) => {
      Axios(token)
        .get("/auth/verify/token")
        .then(async (res) => {
          if (res.data?.response) {
            try {
              const responseData = res.data.response;
              if (!responseData?.token) {
                handleStoreUserInfoError("Token not found", reject);
              }
              responseData.permissions = await getPermission(
                responseData.token,
              );
              responseData.stores = await getAdditionalInfo(responseData.token);

              if (responseData.role === "company") {
                setCompanyId(responseData.id);
              } else if (["driver", "dispatcher"].includes(responseData.role)) {
                setTerminalId(responseData.user.ids);
              }

              if (responseData.terminal) {
                setTerminalInfo({ terminalId: responseData.terminal });
                setTerminalId(responseData.terminal.terminalId);
              }

              setInTerminalPermissions(responseData.permissions);
              setUserInfo(responseData);
              setIsAuthenticated(true);
              setToken(responseData.token);
              setLoading(false);
              resolve(responseData);
            } catch (error) {
              handleStoreUserInfoError(error, reject);
            }
          }
        })
        .catch((error) => {
          handleStoreUserInfoError(error, reject);
        });
    });
  };

  const handleStoreUserInfoError = (error, reject) => {
    setUserInfo(null);
    setIsAuthenticated(false);
    setToken("");
    setLoading(false);
    reject(error);
  };

  const getPermission = async (token) => {
    return new Promise((resolve, reject) => {
      Axios(token)
        .get(`/auth/check/permission`)
        .then((res) => {
          if (res?.data?.status) {
            resolve(res.data.permission);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  };

  const getAdditionalInfo = async (token) => {
    return new Promise((resolve, reject) => {
      Axios(token)
        .get(`/auth/additional/info`)
        .then((res) => {
          if (res?.data?.status) {
            resolve(res.data.stores);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const handleRefresh = async () => {
    try {
      setLoading(true);
      const res = await Axios().get("/auth/verify/refresh");
      if (res?.data?.response?.token) {
        localStorage.removeItem("logoutTime");
        const data = await storeUserInfo(res.data.response.token);
        handlePostRefreshNavigation(data);
        setLoading(false);
      } else {
        handleLogout();
      }
    } catch (e) {
      handleLogout();
    } finally {
      setLoading(false);
    }
  };

  const handlePostRefreshNavigation = (data) => {
    if (data?.isFirstTime || data?.resetPin || data?.resetPassword) {
      navigate("/update/password");
    } else if (
      data &&
      !["terminal", "driver", "dispatcher"].includes(data.role) &&
      splitPath &&
      splitPath.includes("terminal") &&
      (splitPath.includes("standard") ||
        splitPath.includes("transactions") ||
        splitPath.includes("activities") ||
        splitPath.includes("contactless"))
    ) {
      navigate("/dashboard");
    }
  };

  const handleLogout = async () => {
    if (
      !splitPath.includes("contactless") &&
      !splitPath.includes("shadow") &&
      !splitPath.includes("receipt") &&
      !splitPath.includes("privacy") &&
      !splitPath.includes("terms") &&
      !splitPath.includes("blink") &&
      !splitPath.includes("temp-form")
    ) {
      if (!localStorage.getItem("logoutTime")) {
        localStorage.setItem("logoutTime", Date.now().toString());
        await logout();
      }
      setIsAuthenticated(null);
      setToken("");
    }
    setLoading(false);
  };

  const value = {
    storeUserInfo,
    login,
    authorization,
    setAuthorization,
    isAuthenticated,
    userInfo,
    isAssociateAuthenticated,
    setIsAssociateAuthenticated,
    currentAssociatePin,
    setCurrentAssociatePin,
    terminalInfo,
    setTerminalInfo,
    storeId,
    setStoreId,
    setToken,
    token,
    loading,
    setLoading,
    terminalId,
    setTerminalId,
    setUserInfo,
    companyId,
    setCompanyId,
    isAllStoreSelected,
    setIsAllStoreSelected,
    isStoreChanged,
    setIsStoreChanged,
    previousSelectedStoreId,
    setPreviousSelectedStoreId,
    selected,
    setSelected,
    selectedStoreIds,
    setSelectedStoreIds,
    store,
    setStore,
    createIsFormDisable,
    setCreateIsFormDisable,
    inTerminalPermissions,
    setInTerminalPermissions,
    inTerminalInfo,
    setInTerminalInfo,
    mobilestorelogo,
    setMobilestorelogo,
    refundableToCard,
    setRefundableToCard,
    filteredData,
    setFilteredData,
    setIsCompanyUser,
    isCompanyUser,
    selectedDateList,
    setSelectedDateList,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Loader /> : children}
    </AuthContext.Provider>
  );
}
