import React, {useState} from "react";
import Loader from "../Global/widget/Loader";
import {useAuth} from "../../Context/AuthContext";
import Axios from "../../Config/AxiosInstance";
import {capitalize} from "lodash";
import styles from "../Scss/Admin.module.scss";

// import Dropdown from "../Global/Dropdown";

export default function DropdownModal({
  existingDriver,
  onClose,
  drivers,
  data,
  onSubmit,
  type, isContactless
}) {
  const [selected, setSelected] = useState(existingDriver);
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();
  const modalIsClose = () => {
    onClose(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      driver_id: selected?.id || selected,
      type: type,
      isContactless: isContactless
    };
    try {
      if (data?.id) {
        setLoading(true);
        await Axios(token).post(`/update/driver/${data.id}`, body);
        if (onSubmit) {
          onSubmit(true);
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e.response);
      setLoading(false);
    }
  };
  return (
    
    <div>
      {loading && <Loader zIndex={`2147483659`} />}
      <div className="fixed inset-0 z-[123]   overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div
          className={`absolute bottom-1/2 mx-auto left-0 right-0 translate-y-1/2 w-11/12 md:w-2/4 lg:w-2/6 xl:w-96`}
        >
          <div className="relative mx-auto  ">
            <button
              onClick={modalIsClose}
              className="absolute z-10 text-gray-400 top-3 right-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-2xl outline-none focus:outline-none">
              <div className="relative px-6 pt-6">
                <div className="flex items-center justify-between border-b">
                  <div>
                    <h5 className={`text-lg font-semibold text-gray-700`}>
                      Select {capitalize(type)}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="items-center px-6 pb-8 mt-5 border-solid rounded-b border-blueGray-200">
                <div className={"w-100"} id="create-role-form">
                  <select
                    name="reporting"
                    value={selected || ""}
                    onChange={(e) => setSelected(e.target.value)}
                    id="roleForReporting"
                    className={`${styles.form_control} my-2 outline-none`}
                  >
                    <option value="">-- Select {capitalize(type)} --</option>
                    {drivers &&
                      drivers.map((item, i) => (
                        <option key={i} value={item.id}>
                          {item.username}
                        </option>
                      ))}
                  </select>
                  {/*<Dropdown defaultValue={existingDriver} lists={drivers} onChange={(val) => setSelected(val)} />*/}
                  <button
                    className={
                      "bg-green-500 text-white rounded mt-5 w-100 w-full px-5 py-3 uppercase tracking-wider"
                    }
                    onClick={handleSubmit}
                  >
                    Update {capitalize(type)}
                  </button>
                </div>
                {/*{error && <p className={`text-red-500 my-3`}>{error}</p>}*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 backdrop-blur-sm bg-white/30" />
    </div>
  );
}
