import styles from "../../../Components/Scss/Admin.module.scss";
import React, { Fragment, useEffect, useState } from "react";
import IconImage from "../../../Public/admin-images/form-icon-image.png";
import { useAuth } from "../../../Context/AuthContext";
import Multiselect from "multiselect-react-dropdown";
import Loader from "../../../Components/Global/widget/Loader";
import { isEmpty } from "lodash";
import Modal from "../../../Components/Modal/modal";
import { inputValidation } from "../../../Utils/Validatior";
import Axios from "../../../Config/AxiosInstance";
import TempPINPasswordModal from "../../../Components/Modal/TempPINPasswordModal";
import { getRoles } from "../../../api";

function UserRegistration({ selectedStoreId }) {
  const { token, isAllStoreSelected, userInfo } = useAuth();
  const [roleName, setRoleName] = useState([]);
  const [parentRole, setParentRole] = useState([]);
  const [role, setRole] = useState("");
  const [parentKey, setParentKey] = useState("");
  const [data, setData] = useState({
    status: true,
    id: "insert.external_id",
    name: "insert.username",
    temp: 12345678,
    tempp: "1234",
    isUser: true,
  });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    username: "",
    store:
      Array.isArray(selectedStoreId) && selectedStoreId?.length === 0
        ? selectedStoreId
        : selectedStoreId || [],
    password: "",
    confirmPassword: "",
    disabled: false,
    buttonText: "Add User",
    success: false,
    pin: "",
    confirmPin: "",
    default_store: "",
    deviceName: "",
    location: "",
  });
  const {
    firstName,
    lastName,
    username,
    store,
    password,
    buttonText,
    pin,
    deviceName,
    location,
  } = state;

  useEffect(() => {
    if (token && selectedStoreId) {
      getRoles(token, userInfo.role, {
        stores: selectedStoreId,
        isAllStoreSelected: isAllStoreSelected,
      }).then((roles) => {
        if (!roles) {
          return;
        }

        const rolesData = roles.map((role, index) => ({
          id: index + 1,
          name: role.name,
          externalId: role.id,
          slug: role.slug,
        }));

        setRoleName(rolesData);
      });

      Axios(token)
        .post(`/all/stores`, {
          stores: selectedStoreId,
          isAllStoreSelected: isAllStoreSelected,
        })
        .then((response) => {
          if (response.data.response) {
            const storeArray = [];
            response.data.response.map((items) => {
              storeArray.push({
                name: items?.store_name,
                externalId: items.store_external_id,
              });
            });
            setOptions(storeArray);
          }
        });
    }
  }, [token, selectedStoreId]);

  const OnRoleChange = (e) => {
    const data = e.target.value;
    Axios(token)
      .post("/check/role", { role: data })
      .then((checkRole) => {
        setRole(checkRole.data.role);
        setParentRole(checkRole.data);
        setParentKey(checkRole.data.parentKey);
      });
  };

  const onSelect = (selectedList) => {
    let storeExId = [];
    if (!isEmpty(selectedList)) {
      selectedList.map((items) => {
        storeExId.push(items.externalId);
      });
    }
    setState({ ...state, store: storeExId });
  };
  const onRemove = (selectedList) => {
    let storeExId = [];
    if (!isEmpty(selectedList)) {
      selectedList.map((items) => {
        storeExId.push(items.externalId);
      });
    }
    setState({ ...state, store: storeExId });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    let error = false;

    if (isEmpty(parentKey)) {
      setError("Select a role first");
      setIsModalOpen(true);
      return;
    }
    if (
      options.length > 1 &&
      isEmpty(store) &&
      selectedStoreId.length > 1 &&
      (parentKey === "terminal" || parentKey === "associate")
    ) {
      setError("Please select a store");
      error = true;
    }
    if (isEmpty(inputValidation(firstName)) && parentKey !== "terminal") {
      setError("First name is Required!");
      error = true;
    }
    if (isEmpty(inputValidation(lastName)) && parentKey !== "terminal") {
      setError("Last name is Required!");
      error = true;
    }
    if (isEmpty(inputValidation(deviceName)) && parentKey === "terminal") {
      setError("Device name is Required!");
      error = true;
    }
    if (isEmpty(inputValidation(location)) && parentKey === "terminal") {
      setError("Location is Required!");
      error = true;
    }
    if (isEmpty(inputValidation(username)) && parentKey !== "associate") {
      setError("Username is required.");
      error = true;
    }

    if (error) {
      setIsModalOpen(true);
      return;
    }

    let data = {
      first_name: inputValidation(firstName),
      last_name: inputValidation(lastName),
      default_store: isEmpty(store) ? selectedStoreId : store,
      role: role,
      store: isEmpty(store) ? selectedStoreId : store,
      password: password,
      parentKey: inputValidation(parentKey),
    };
    if (parentKey !== "terminal") {
      data.pin = pin;
    }
    if (parentKey === "terminal") {
      data.location = location;
      data.deviceName = deviceName;
    }
    if (parentKey !== "terminal" && parentKey !== "associate") {
      data.username = inputValidation(username);
    }
    setLoading(true);

    Axios(token)
      .post("/register/user", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status) {
          setData(response.data);
          setConfirmationModal(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
          setIsModalOpen(true);
        }
      });
  };
  const onInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && <Loader />}
      {isModalOpen && (
        <Modal
          isModalOpen={isModalOpen}
          type={"Error"}
          message={error}
          showOk={true}
          modalCallBack={() => setIsModalOpen(false)}
        />
      )}
      {confirmationModal && data && (
        <TempPINPasswordModal data={data} isModalOpen={confirmationModal} />
      )}
      <div className="relative md:pb-6 lg:items-center lg:flex-row">
        <div className={`${styles.form_card} md:px-5  md:pt-5`}>
          <div className="">
            <div className={` flex items-center`}>
              <div
                className={`w-10 md:w-16 h-10 md:h-16 p-2 md:p-4 rounded-full bg-yellow-400`}
              >
                <img src={IconImage} alt="" />
              </div>
              <div className={`pl-4 flex-1`}>
                <p className="text-lg font-semibold">User Registration</p>
                <p className={`text-gray-600 text-sm`}>
                  To Add an account please enter the information.
                </p>
              </div>
            </div>
            <div className="pt-8 md:pt-16">
              <form
                onSubmit={submitHandler}
                method={"post"}
                className="grid gap-3 md:gap-4 md:grid-cols-3 customFrom"
              >
                {/*<div className="pb-2 mb4 text-sm font-semibold text-gray-600 border-b col-span-full">*/}
                {/*  Personal Information*/}
                {/*</div>*/}
                <div>
                  <label className={` pb-2 w-full block`}>Select Role</label>
                  <select
                    name={`role`}
                    id={`role`}
                    value={role}
                    onChange={OnRoleChange}
                    className={`${styles.form_control} outline-none`}
                  >
                    <option value={0}>Select Role</option>
                    {roleName?.map(function (items, i) {
                      return (
                        <option
                          value={`${items.externalId}:${items.slug}`}
                          key={i}
                        >
                          {items.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {parentRole &&
                  parentRole?.field?.map((field, index) => (
                    <Fragment key={index}>
                      {field.name === "store" ? (
                        <>
                          {parentKey !== "company_user_1" &&
                            parentKey !== "company_user_1" &&
                            options.length >= 2 &&
                            isAllStoreSelected && (
                              <div className={`col-span-2 `} key={index}>
                                <label className={` pb-2 w-full block`}>
                                  Select Accounts
                                </label>
                                <Multiselect
                                  className={`${styles.storeMultiselect}`}
                                  options={options}
                                  onSelect={onSelect}
                                  onRemove={onRemove}
                                  showCheckbox={field.isMultiple}
                                  displayValue="name"
                                  name="store"
                                  singleSelect={!field.isMultiple}
                                />
                                <p
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  {/*<ErrorMessage name={`store`}/>*/}
                                </p>
                              </div>
                            )}
                        </>
                      ) : (
                        <div className="customFiled" key={index}>
                          <label className={`pb-2 w-full block`}>
                            {field.title}
                          </label>
                          <div className="customWrapper">
                            <input
                              type={field.inputType}
                              name={field.name}
                              id={field.id}
                              maxLength={field?.maxLength}
                              className={`${styles.form_control} mb-3`}
                              onChange={onInputChange}
                            />
                            <div className="customToggle"></div>
                          </div>
                          <p className="text-danger" style={{ color: "red" }}>
                            {/*<ErrorMessage name={`store`}/>*/}
                          </p>
                        </div>
                      )}
                    </Fragment>
                  ))}
                <div className="pt-0 md:pt-4 col-span-full">
                  <button
                    type="submit"
                    className={`w-full bg-green-500 text-white uppercase tracking-wider  rounded py-2 sm:py-3 px-4 text-xs md:text-base
                     hover:bg-green-700 transition-all disabled:bg-green-400`}
                  >
                    {buttonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserRegistration;
