import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const HomePage = lazy(() => import('../Page/Public/HomePage'));
const Login = lazy(() => import('../Page/Public/Login'));
const Privacy = lazy(() => import('../Page/Public/Privacy'));
const Terms = lazy(() => import('../Page/Public/Terms'));
const Esign = lazy(() => import('../Page/Public/E-sign'));
const Unauthorised = lazy(() => import('../Page/Global/Unauthorised'));
const ShadowLogin = lazy(() => import('../Page/Admin/ShadowLogin'));

const PublicRoutes = [
  <Route exact path="/" element={<HomePage />} key="home" />,
  <Route path="/login" element={<Login />} key="login" />,
  <Route path="/privacy" element={<Privacy />} key="privacy" />,
  <Route path="/terms" element={<Terms />} key="terms" />,
  <Route path="/esign" element={<Esign />} key="esign" />,
  <Route path="/unauthorised" element={<Unauthorised />} key="unauthorised" />,
  <Route path="/shadow/:companyId" element={<ShadowLogin />} key="shadow-login" />,
];

export default PublicRoutes;
