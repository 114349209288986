import React, { useEffect, useState } from "react";
import styles from "../../Components/Scss/Admin.module.scss";
import Table from "../../Components/Global/Table";
import CompanyLayout from "../../Components/Company/Layouts";
import { useAuth } from "../../Context/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { capitalize, isEmpty, toNumber } from "lodash";
import Filters from "../../Components/Global/transaction/Filters";
import Unauthorised from "../Global/Unauthorised";
import { HasPermission } from "../../Components/Global/Permission";
import { inputValidation } from "../../Utils/Validatior";
import { currencyFormat, roundUp } from "../../Utils/Common";
import Loader from "../../Components/Global/widget/Loader";
import download from "downloadjs";
import NoData from "../../Components/Global/widget/NoData";
import convertToLocalTime from "../../Utils/timeZoneConverter";
import Axios from "../../Config/AxiosInstance";

export default function TerminalDetails({ permission }) {
  const [hiddenChart, setHiddenChart] = useState(true);
  const { selectedStoreIds, token, selectedDateList } = useAuth();
  const [data, setData] = useState(true);
  const [tips, setTips] = useState();
  const [allTransactionData, setAllTransactionData] = useState([]);
  const [datePick, setDatePick] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [noOfTransactions, setNoOfTransactions] = useState();
  const [salesAmount, setSalesAmount] = useState();
  // const [salesPlusFees, setSalesPlusFees] = useState();
  const [salesPlusTips, setSalesPlusTips] = useState();
  const [terminaLocation, setTerminaLocation] = useState();
  const [terminalName, setTerminalName] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const location = useParams();
  const getTerminalId = location?.id;
  const [to, setTo] = useState();
  const [from, setFrom] = useState();
  const navigate = useNavigate();
  const [resApiData, setResApiData] = useState(false);
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [search, setSearch] = useState("");
  const perPage = 10;
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [createdAt, setCreatedAt] = useState();
  const [refund, setRefund] = useState([]);

  useEffect(() => {
    setIsDateSelected(false);
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);
  useEffect(() => {
    if (token && currentStoreId) {
      setResApiData(false);
      Axios(token)
        .post(
          `/terminal/details/${getTerminalId}`,
          {
            s: search,
            days: "",
            from: from || selectedDateList[0],
            to: to || selectedDateList[1],
            type: "store",
            stores: currentStoreId,
          },
          {
            headers: { Authorization: "Bearer " + token },
          },
        )
        .then((response) => {
          if (response?.data?.response) {
            setData(true);
            setResApiData(true);
            let resData = response.data.response;
            const tips = roundUp(resData.metabox.totaltips);
            const salesAmount = roundUp(resData?.metabox?.salesAmount || 0);

            setTips(tips);
            setNoOfTransactions(resData.metabox.noOfTransactions);
            setSalesAmount(salesAmount);
            setSalesPlusTips(roundUp(salesAmount + tips));
            setTerminalName(resData.response.name);
            setTerminaLocation(resData.response.location);
            setCreatedAt(resData.response.createdAt);
            setRefund(resData?.metabox?.totalrefund);
          } else if (response?.data?.message === "No Data Found") {
            setData(false);
            setResApiData(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setData(false);
          setResApiData(true);
        });
    }
  }, [token, datePick, search, currentStoreId]);

  useEffect(() => {
    if (token && currentStoreId) {
      setTableLoading(true);
      //ref.current.continuousStart();
      let userType = "store";
      Axios(token)
        .post(
          `/terminal/details/transaction/list/${getTerminalId}`,
          {
            s: search,
            days: "",
            from: from || selectedDateList[0],
            to: to || selectedDateList[1],
            page: currentPage,
            size: perPage,
            type: userType,
            stores: currentStoreId,
          },
          {
            headers: { Authorization: "Bearer " + token },
          },
        )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.response &&
            response.data.response.records
          ) {
            filterData(response.data.response);
          }
          setTableLoading(false);
          //ref.current.complete();
        })
        .catch(() => {
          setTableLoading(false);
          //ref.current.complete();
        });
    }
  }, [token, datePick, search, currentStoreId, currentPage]);

  const conditionalRowStyles = [
    {
      when: (row) => row.OtherTransactions,
      style: {
        backgroundColor: "rgba(239,68,68,0.09)",
      },
    },
  ];
  const filterData = (data) => {
    let records = [];
    data.records.map((items, index) => {
      records.push({
        serialNo: index + 1,
        orderId: items.order_id,
        date: convertToLocalTime(items.createdAt).format("PPP"),
        customerName: capitalize(items.Customer?.fullName),
        salesAmount: currencyFormat(toNumber(items.amount)),
        tips: items.tip
          ? currencyFormat(toNumber(items.tip))
          : currencyFormat(0),
        salesAmountTips: currencyFormat(
          toNumber(items.tip) + toNumber(items.amount),
        ),
        totalAmountFees: currencyFormat(
          toNumber(items.fee) + toNumber(items.amount),
        ),
        totalProcessed: currencyFormat(toNumber(items.order_amount)),
        external_id: items.transactions_external_id,
        OtherTransactions:
          !isEmpty(items?.OtherTransactions) &&
          Array.isArray(items?.OtherTransactions) &&
          items?.OtherTransactions.filter((e) => e.status === "succeeded")
            .length > 0
            ? true
            : !isEmpty(items?.OtherTransactions) &&
              items?.OtherTransactions?.status === "succeeded",
      });
    });
    setTotalRows(data.totalItems);
    setAllTransactionData(records);
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.serialNo,
      width: "50px",
    },
    {
      name: "Order ID",
      selector: (row) => row.orderId,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
    },
    {
      name: "Sales Amount",
      cell: (row) => [
        <button
          key={row.external_id}
          className={`font-medium tableBtn break-normal rounded-full px-4 py-1 leading-5 mr-3`}
          onClick={() => onRowClick(row)}
          id={row.external_id + 1}
        >
          {row.salesAmount}
        </button>,
      ],
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "Tips",
      selector: (row) => row.tips,
      wrap: true,
    },
    {
      name: "Sales Amount + Tips",
      selector: (row) => row.salesAmountTips,
      wrap: true,
    },
    // {
    //   name: "Total Amount + Fees",
    //   selector: (row) => row.totalAmountFees,
    //   wrap: true,
    // },
    {
      name: "Total Processed",
      cell: (row) => [
        <button
          key={row.external_id}
          className={`font-medium tableBtn break-normal rounded-full px-4 py-1 leading-5 mr-3`}
          onClick={() => onRowClick(row)}
          id={row.external_id + 1}
        >
          {row.totalProcessed}
        </button>,
      ],
      ignoreRowClick: true,
      button: true,
    },
  ];
  const onRowClick = (val) => {
    navigate(`/transaction/${val.external_id}`);
  };
  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(
      convertToLocalTime(val[0])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTo(
      convertToLocalTime(val[1])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
  };
  const handleOnPageChange = async (val) => {
    if (val) {
      setCurrentPage(val);
    }
  };
  const handleSearchSubmit = async (val) => {
    if (val) {
      setSearch(val);
    }
  };
  const handleSearchInput = (value) => {
    if (isEmpty(value)) {
      setSearch("");
    }
  };
  const HandelHiddenChart = (e) => {
    const hiddenChartVal = e.target.checked;
    setHiddenChart(hiddenChartVal);
  };
  const quarries = {
    stores: currentStoreId,
    terminal_id: getTerminalId,
    from: from || selectedDateList[0],
    to: to || selectedDateList[1],
  };
  const handleDownload = async () => {
    try {
      setLoading(true);
      const data = await Axios(token).post(
        "/download/transaction/report/terminal",
        quarries,
        {
          responseType: "blob",
          headers: { Authorization: "Bearer " + token },
        },
      );
      setLoading(false);
      download(
        data.data,
        `Terminal transactions reports ${convertToLocalTime(new Date()).format(
          "MMMM Do YYYY",
        )}`,
      );
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {HasPermission(permission) ? (
        <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
          <div>
            {data ? (
              <div>
                {resApiData ? (
                  <div className="px-5 pt-4 bg-slate-100 rounded-xl">
                    <div className="flex flex-wrap items-center justify-between pb-4">
                      <div>
                        <p className="text-2xl md:text-3xl font-bold text-gray-700">
                          {terminalName ? inputValidation(terminalName) : ""}
                        </p>
                        <p className="mt-2 mb-1 text-sm font-semibold text-gray-500 ">
                          Registration Date & Time:{" "}
                          <span className="pr-3 mr-3 text-gray-700 border-r border-gray-400">
                            {convertToLocalTime(createdAt).format("PPP")}
                          </span>{" "}
                          Location:{" "}
                          <span className="text-gray-700">
                            {terminaLocation}
                          </span>
                        </p>
                      </div>
                      <div className="flex flex-wrap">
                        <div className="flex items-center mr-3">
                          <p className="pr-2 text-gray-600">
                            {hiddenChart ? "Disable" : "Enable"} Chart
                          </p>

                          <div className={`${styles.switch_box}`}>
                            <input
                              type="checkbox"
                              id="HiddenChartId"
                              defaultChecked={hiddenChart}
                              name="HiddenChartId"
                              onChange={(e) => HandelHiddenChart(e)}
                              className={` ${styles.input_switch}`}
                            />
                            <label
                              htmlFor="HiddenChartId"
                              className={`!mb-0`}
                            />
                            <div
                              className={` ${styles.switch_status_box}`}
                            ></div>
                          </div>
                        </div>
                        {HasPermission(["create_terminal"]) && (
                          <>
                            <Link
                              to={`/terminal/${getTerminalId}/edit`}
                              className="inline-flex px-1.5 md:px-3 mr-3 py-1.5 md:py-2.5 text-xs md:text-sm leading-5 text-white transition-all bg-green-500 rounded hover:bg-green-700 hover:text-white"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={1.5}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                />
                              </svg>
                              <span className="inline-block pl-2">
                                Edit Terminal
                              </span>
                            </Link>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="">
                      {hiddenChart && (
                        <div className="grid grid-cols-2 md:grid-cols-5 gap-3 pt-4 pb-4 border-t">
                          <div className="flex items-center p-3 bg-white rounded">
                            <div className="ml-3">
                              <p className="text-xs tracking-wide text-gray-500">
                                Sales Amount:
                              </p>
                              <p className="mt-1 text-base font-semibold text-gray-700">
                                {currencyFormat(salesAmount)}
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center p-3 bg-white rounded">
                            <div className="ml-3">
                              <p className="text-xs tracking-wide text-gray-500">
                                Tips:
                              </p>
                              <p className="mt-1 text-base font-semibold text-gray-700">
                                {currencyFormat(tips)}
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center p-3 bg-white rounded">
                            <div className="ml-3">
                              <p className="text-xs tracking-wide text-gray-500">
                                Sales Amount + Tips:
                              </p>
                              <p className="mt-1 text-base font-semibold text-gray-700">
                                {currencyFormat(salesPlusTips)}
                              </p>
                            </div>
                          </div>

                          <div className="flex items-center p-3 bg-white rounded">
                            <div className="ml-3">
                              <p className="text-xs tracking-wide text-gray-500">
                                Number of Transactions:
                              </p>
                              <p className="mt-1 text-base font-semibold text-gray-700">
                                {" "}
                                {noOfTransactions}
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center p-3 bg-white rounded">
                            <div className="ml-3">
                              <p className="text-xs tracking-wide text-gray-500">
                                Refunds:
                              </p>
                              <p className="mt-1 text-base font-semibold text-gray-700">
                                {" "}
                                {currencyFormat(refund)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="px-5 pt-4 bg-slate-100 rounded-xl animate-pulse">
                    <div className="flex items-center justify-between pb-4 border-b">
                      <div>
                        <p className="h-6 rounded bg-slate-300 w-36 "></p>
                        <p className="h-3 mt-2 rounded bg-slate-300 w-60"></p>
                      </div>
                      <div>
                        <div className="inline-flex px-10 py-4 rounded bg-slate-300"></div>
                      </div>
                    </div>

                    <div className="grid grid-cols-4 gap-3 pt-4 pb-4 border-t">
                      {Array.from(Array(6), (e, i) => {
                        return (
                          <div
                            key={i}
                            className="flex items-center p-3 rounded bg-white/75"
                          >
                            <div className="ml-3">
                              <p className="w-20 h-2 rounded bg-slate-200"></p>
                              <p className="w-10 h-4 mt-2 rounded bg-slate-200"></p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className=" pt-7">
                  <h5 className="mt-4 md:mt-8 mb-4 text-xl font-semibold text-gray-700">
                    All Transaction
                  </h5>
                  <Filters
                    date={handleDateChange}
                    downloadBtnDisable={!allTransactionData.length}
                    onSearchInput={handleSearchInput}
                    onDownload={handleDownload}
                    onSearchSubmit={handleSearchSubmit}
                    placeHolder={"Search Customer"}
                    onSearchReset={() => setSearch("")}
                    downloadBtn={true}
                    isSelectDate={true}
                    SelectDate={[from, to]}
                    isDateSelected={isDateSelected}
                  />
                </div>
                <div className={`${styles.table_ui_style} mt-5`}>
                  <Table
                    perPage={perPage}
                    data={allTransactionData}
                    columns={columns}
                    showPagination={true}
                    totalRows={totalRows}
                    showPointer={true}
                    onRowClick={(val) => onRowClick(val)}
                    onPageChange={handleOnPageChange}
                    pending={tableLoading}
                    conditionalRowStyles={conditionalRowStyles}
                  />
                </div>
              </div>
            ) : (
              <NoData />
            )}
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </>
  );
}
