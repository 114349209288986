import React from "react";

export default function LoadingTable() {
  return (
    <>
      <div className="animate-pulse w-full">
        <div className="flex justify-between px-4 py-5 rounded bg-slate-300">
          {Array.from(Array(10), () => {
            return (
              <div
                key={Math.random()}
                className="h-3 rounded bg-slate-400 w-14 mx-1"
              ></div>
            );
          })}
        </div>
        {Array.from(Array(10), () => {
          return (
            <div
              key={Math.random()}
              className="flex justify-between px-4 py-4 mt-1 rounded bg-slate-50"
            >
              {Array.from(Array(10), () => {
                return (
                  <div
                    key={Math.random()}
                    className="h-3 rounded bg-slate-200 w-14"
                  ></div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
}
