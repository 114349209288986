import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import _, { toNumber } from "lodash";
import { currencyFormat, roundUp } from "../../Utils/Common";
import download from "downloadjs";
import Loader from "./widget/Loader";
import { HasPermission } from "./Permission";
import CompanyLayout from "../Company/Layouts";
import Filters from "./transaction/Filters";
import styles from "../Scss/Admin.module.scss";
import Table from "./Table";
import Unauthorised from "../../Page/Global/Unauthorised";
import Axios from "../../Config/AxiosInstance";
import convertToLocalTime from "../../Utils/timeZoneConverter";

const COLUMNS = [
  {
    name: "Employee",
    selector: (item) => [item.firstName, item.lastName].join(" "),
    sortable: true,
    sortField: "fullName",
  },
  {
    name: "Store Name",
    selector: (item) => _.startCase(_.toLower(item?.accountNumber || "")),
    sortable: true,
    sortField: "storeName",
  },
  {
    name: "Role",
    selector: (item) => item.role,
    sortable: true,
    sortField: "role",
  },
  {
    name: "Sales Amount",
    selector: (item) => currencyFormat(roundUp(item?.orderAmount || 0)),
    style: {
      paddingTop: "0",
      paddingBottom: "0",
      div: {
        background: "rgba(5, 66, 120, 0.0509803922)",
        color: "#054278",
        fontWeight: "bolder",
        borderRadius: "1.5rem",
        padding: "5px 15px",
      },
    },
    sortable: true,
  },
  {
    name: "Tip",
    selector: (item) => currencyFormat(toNumber(item?.tip || 0)),
    sortable: true,
  },
  {
    name: "Sales + Tip",
    selector: (item) => currencyFormat(roundUp(item?.totalAmount || 0)),
    sortable: true,
  },
];

const DEFAULT_SORT = {
  field: "fullName",
  direction: "asc",
};

export default function RoleBasedTransactions({
  permission,
  initialRole = [],
  showTitle = true,
  title = "",
  searchPlaceHolder = "Search..",
  showDownload = true,
  showPointer = true,
  showPagination = true,
  reportPrefix,
}) {
  const { token, selectedStoreIds, selectedDateList } = useAuth();
  const [data, setData] = useState([]);
  const [datePick, setDatePick] = useState();
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(selectedDateList[0]);
  const [to, setTo] = useState(selectedDateList[1]);
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(initialRole);
  const [sort, setSort] = useState(DEFAULT_SORT);

  const requestData = {
    days: "",
    type: "store",
    s: search,
    from,
    to,
    page: currentPage,
    size: perPage,
    stores: selectedStoreIds,
    role: selectedRole,
    sort,
  };

  const fetchTransactions = async () => {
    if (!token || !selectedStoreIds) {
      return;
    }

    try {
      setLoading(true);
      const response = await Axios(token).post("/transactions", requestData);
      if (response?.data?.response) {
        setData(response.data.response.records);
        setTotalRows(response.data.response?.totalItems);
      }
      setLoading(false);
    } catch (e) {
      console.error("Error happen during fetching transactions", e);
      setLoading(false);
      setData([]);
      setTotalRows(null);
    }
  };

  useEffect(() => {}, [selectedStoreIds]);
  useEffect(() => {
    fetchTransactions().then();
  }, [
    token,
    datePick,
    search,
    selectedStoreIds,
    currentPage,
    selectedRole,
    initialRole,
    sort,
    perPage,
  ]);

  const handleRowClick = (val) => {
    navigate(`/user/${val?.userID || val?.external_id || val?.externalId}`);
  };

  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(convertToLocalTime(val[0]).format("yyyy-MM-dd"));
    setTo(convertToLocalTime(val[1]).format("yyyy-MM-dd"));
  };

  const handleSearchSubmit = async (val) => {
    if (val) {
      setSearch(val);
    }
  };

  const handleOnPageChange = async (val) => {
    if (val) {
      setCurrentPage(val);
    }
  };
  const handleDownload = async () => {
    try {
      setDownloadLoading(true);
      const date = convertToLocalTime(new Date(), `MMMM Do YYYY`);
      const fileName = reportPrefix
        ? `${reportPrefix} report, ${date}`
        : `Report, ${date}`;
      const data = await Axios(token).post("/download/report", requestData, {
        responseType: "blob",
        headers: { Authorization: "Bearer " + token },
      });
      setDownloadLoading(false);
      download(data.data, fileName);
    } catch (e) {
      setDownloadLoading(false);
    }
  };

  const handleRole = (position) => {
    setSelectedRole([position]);
  };

  const handleSort = async (column, sortDirection) => {
    let col_name = "";
    if (column === "Full Name") {
      col_name = "fullName";
    }
    if (column === "Sales Amount") {
      col_name = "orderAmount";
    }
    if (column === "Tips") {
      col_name = "tip";
    }
    if (column === "Sales + Tip") {
      col_name = "totalAmount";
    }
    setSort({
      field: col_name,
      direction: sortDirection,
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(currentPage);
  };

  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        justifyContent: "center !important",
        "&:nth-child(5)": {
          textAlign: "left",
          justifyContent: "start !important",
        },
      },
    },
    cells: {
      style: {
        textAlign: "center",
        justifyContent: "center !important",
        "&:nth-child(5)": {
          textAlign: "left",
          justifyContent: "start !important",
        },
      },
    },
  };
  return (
    <CompanyLayout>
      {downloadLoading && <Loader />}
      {HasPermission(permission) ? (
        <>
          {showTitle && title && (
            <div className="flex items-center justify-between">
              <h5
                className={`flex-1 text-xl md:text-2xl font-semibold mr-4 text-gray-700`}
              >
                {title}
              </h5>
              {HasPermission(["create_people"]) && (
                <Link
                  to={"/register/user"}
                  className={`px-3 py-2 md:py-2.5 md:px-5 text:xs md:text-sm  text-white flex items-center rounded bg-green-500 focus:bg-green-700 hover:bg-green-700 right-0 top-0 border-0 outline-none`}
                >
                  Register User
                </Link>
              )}
            </div>
          )}
          <div className={` mt-2 md:mt-5`}>
            <Filters
              placeHolder={searchPlaceHolder}
              date={handleDateChange}
              onSearchSubmit={handleSearchSubmit}
              onSearchReset={() => setSearch("")}
              downloadBtn={showDownload}
              onDownload={handleDownload}
              downloadBtnDisable={!data.length}
              roleDropdown={true}
              showEmployeeRole={false}
              onChangeEmployeeRole={handleRole}
              stores={selectedStoreIds}
              onRoleChange={(val) => setSelectedRole(val)}
            />
            <div className={`mt-5 ${styles.table_ui_style}`}>
              <Table
                data={data}
                pending={loading}
                columns={COLUMNS}
                showPointer={showPointer}
                showPagination={showPagination}
                perPage={perPage}
                totalRows={totalRows}
                onPageChange={handleOnPageChange}
                onRowClick={handleRowClick}
                defaultSortFieldId={1}
                defaultSortAsc={DEFAULT_SORT.direction === "asc"}
                sortServer
                handleSort={handleSort}
                customStyles={customStyles}
                sortIcon={
                  <svg
                    width="9"
                    height="12"
                    viewBox="0 0 9 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.5 1.5L4.5 10.5M4.5 10.5L8 7M4.5 10.5L1 7"
                      stroke="#064278"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                }
                onRowPerPageChange={(currentRowsPerPage, currentPage) =>
                  handleRowsPerPageChange(currentRowsPerPage, currentPage)
                }
              />
            </div>
          </div>
        </>
      ) : (
        <Unauthorised />
      )}
    </CompanyLayout>
  );
}
