import React, { useEffect, useState } from "react";
import styles from "../../../Components/Scss/Admin.module.scss";
import { useAuth } from "../../../Context/AuthContext";
import Withdrawal from "../../../Components/Global/transaction/withdrawal";
import CompanyLayout from "../../../Components/Company/Layouts";
import { HasPermission } from "../../../Components/Global/Permission";
import Unauthorised from "../../Global/Unauthorised";

function Withdrawls({ permission }) {
  const { selectedStoreIds } = useAuth();
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);
  return (
    <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
      {HasPermission(permission) ? (
        <>
          <h5
            className={`text-xl md:text-2xl font-semibold sm:pb-0 text-gray-700`}
          >
            Payouts
          </h5>

          <div className={`mt-2 md:mt-4`}>
            <div
              className={`xl:col-span-12 md:col-span-12  ${styles.table_ui_style}`}
            >
              <Withdrawal selectedStoreId={currentStoreId} />
            </div>
          </div>
        </>
      ) : (
        <Unauthorised />
      )}
    </CompanyLayout>
  );
}

export default Withdrawls;
