import React, { useEffect, useState } from "react";
import { currencyFormat, roundUp } from "../../../Utils/Common";
import {toNumber} from "lodash";

export default function StoreInfoModal({ show, data, onStoreModalClose }) {
  const [showModal, setShowModal] = useState(false);
  const [checkCreditFlat, setCheckCreditFlat] = useState(false);
  const [checkDebitFlat, setCheckDebitFlat] = useState(false);
  const [checkCreditPercentage, setCheckCreditPercentage] = useState(false);
  const [checkDebitPercentage, setCheckDebitPercentage] = useState(false);
  useEffect(() => {
    setShowModal(show);
  }, [show]);
  const modalIsClose = (e) => {
    e.preventDefault();
    setShowModal(false);
    onStoreModalClose();
  };
  useEffect(() => {
    if (data) {
      data?.map((store) => {
        if (store?.store_credit_fee) {
          setCheckCreditFlat(true);
        }
        if (store?.store_debit_fee) {
          setCheckDebitFlat(true);
        }
        if (store?.store_credit_fee_percentage) {
          setCheckCreditPercentage(true);
        }
        if (store?.store_debit_fee_percentage) {
          setCheckDebitPercentage(true);
        }
      });
    }
  }, [data]);

  return (
    showModal && (
      <>
        <div>
          <div className="fixed inset-0 z-[999999] flex justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="flex flex-col justify-center items-center h-[100vh]">
              <div className="relative flex w-full flex-col rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] ">
                <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white p-4 shadow-2xl shadow-gray-100 ">
                  <h4 className="text-lg font-bold text-navy-700 !mb-0">
                    {data?.length > 1 ? "Stores" : "Store"} Information
                  </h4>
                  <button
                    onClick={modalIsClose}
                    className="linear text-red-500 underline text-base font-medium "
                  >
                    Close
                  </button>
                </div>
                <div className="w-full overflow-x-scroll">
                  <table role="table" className="w-full overflow-x-scroll ">
                    <colgroup>
                      <col span="1" className={`border-r border-dashed`} />
                    </colgroup>
                    <thead className={`text-left border-b border-dashed `}>
                      <tr role="row" className={`p-4 `}>
                        <th
                          colSpan="1"
                          role="columnheader"
                          className={`px-4 py-2`}
                        >
                          Store Name
                        </th>

                        {checkDebitFlat || checkCreditFlat ? (
                          <th
                            colSpan="1"
                            role="columnheader"
                            className={`px-4`}
                          >
                            Store Fees ($)
                          </th>
                        ) : (
                          ""
                        )}
                        {checkDebitPercentage || checkCreditPercentage ? (
                          <th
                            colSpan="1"
                            role="columnheader"
                            className={`px-4`}
                          >
                            Store Fees (%)
                          </th>
                        ) : (
                          ""
                        )}

                        <th colSpan="1" role="columnheader" className={`px-4`}>
                          Payout Schedule
                        </th>
                        <th colSpan="1" role="columnheader" className={`px-4`}>
                          Chargeback Fees
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup" className="px-4">
                      {data?.map((store, index) => (
                        <tr
                          role="row"
                          key={index}
                          className={`border-b border-dashed last:border-b-0 even:bg-gray-100`}
                        >
                          <td className="p-4 text-sm " role="cell">
                            <p className="text-sm font-semibold">
                              {store.name}
                            </p>
                          </td>
                          {checkDebitFlat || checkCreditFlat ? (
                            <td className="p-4 text-sm text-center" role="cell">
                              {(store?.store_credit_fee || store?.store_debit_fee) ? (
                                <div className="text-md font-medium text-gray-600 ">
                                  {toNumber(store?.store_credit_fee) > 0 && (
                                    <p>
                                      Credit:{" "}
                                      {roundUp(
                                        store?.store_credit_fee || 0,
                                      )?.toFixed(2)}{" "}
                                    </p>
                                  )}
                                  {toNumber(store?.store_debit_fee) > 0 && (
                                    <p>
                                      Debit:{" "}
                                      {roundUp(
                                        store?.store_debit_fee || 0,
                                      )?.toFixed(2)}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                          ) : (
                            ""
                          )}

                          {(checkCreditPercentage || checkDebitPercentage) ? (
                            <td className="p-4 text-sm text-center" role="cell">
                              {(store?.store_credit_fee_percentage || store?.store_debit_fee_percentage) ? (
                                <div className="text-md font-medium text-gray-600 ">
                                  {toNumber(store?.store_credit_fee_percentage) > 0 && (
                                    <p>
                                      Credit:{" "}
                                      {roundUp(
                                        store?.store_credit_fee_percentage || 0,
                                        2,
                                      )?.toFixed(2)}
                                    </p>
                                  )}
                                  {toNumber(store?.store_debit_fee_percentage) > 0 && (
                                    <p>
                                      Debit:{" "}
                                      {roundUp(
                                        store?.store_debit_fee_percentage || 0,
                                        2,
                                      )?.toFixed(2)}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                          ) : (
                            ""
                          )}
                          <td className="p-4 text-sm text-center" role="cell">
                            <div className="text-md font-medium text-gray-600 ">
                              <span>{store?.payout_period || "N/A"}</span>
                            </div>
                          </td>
                          <td className="p-4 text-sm text-center" role="cell">
                            <p className="text-md font-medium text-gray-600 ">
                              <span>
                                {currencyFormat(
                                  store?.chargeback_fee || 0,
                                )}
                              </span>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-[999998] backdrop-blur-sm bg-white/30" />
        </div>
      </>
    )
  );
}
