import * as yup from "yup";
import {MAX_FILE_NAME_LENGTH, MAX_FILE_SIZE} from "./constants";

export const validationSchema = yup.object().shape({
  date: yup.string().required(),
  customer: yup.string().required(),
  comment: yup.string().label('Comment').when('files', {
    is: (files) => files?.length === 0,
    then: (schema) => schema.min(2).max(500).required()
  }),
  files: yup
    .mixed()
    .when('comment', {
      is: (comment) => comment?.length === 0,
      then: (schema) => schema.test('length', 'At least 1 files should be attached', (files) => files?.length > 0)
    })
    .test('sizes', 'Files are too big. Max 100 Mb is allowed', (files) => {
      let totalSize = 0;
      for (const file of files) {
        totalSize += file.size;
      }
      return totalSize <= MAX_FILE_SIZE;
    })
    .test('names', 'File names are too long. Max 50 characters are allowed', (files) => {
      const validationResults = [];
      for (const file of files) {
        validationResults.push(file.name.length <= MAX_FILE_NAME_LENGTH);
      }
      return validationResults.every((v) => v);
    })
}, [['comment', 'files']]).required();
