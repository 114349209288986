import React from "react";
import RoleBasedTransactions from "../../../Components/Global/RoleBasedTransactions";

export default function CustomReporting({ permission }) {
  return (
    <RoleBasedTransactions
      initialRole={["all"]}
      title={`Customize Report`}
      searchPlaceHolder={"Search.."}
      showTitle={true}
      roleBasedFilter={true}
      permission={permission}
    />
  );
}
