import React, { Fragment, useState } from "react";
import styles from "../../../Components/Scss/Admin.module.scss";
import IconImage from "../../../Public/admin-images/form-icon-image.png";
import { useAuth } from "../../../Context/AuthContext";
import Modal from "../../../Components/Modal/modal";
import Loader from "../../../Components/Global/widget/Loader";
import { trimStart } from "lodash";
import TempPINPasswordModal from "../../../Components/Modal/TempPINPasswordModal";
import Axios from "../../../Config/AxiosInstance";

function TerminalRegistration({ selectedStoreId }) {
  const { token } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [data, setData] = useState({
    name: "",
    isUser: false,
  });
  const [state, setState] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    device_name: "",
    location: "",
    disabled: false,
    buttonText: "Add Terminal",
    success: false,
  });
  const { disabled, buttonText, username, device_name, location, password } = state;
  const submitHandler = (e) => {
    e.preventDefault();
    let value = {};
    value.device_name = device_name;
    value.location = location;
    value.username = username;
    value.password = password
    value.id = selectedStoreId;
    setLoading(true);
    Axios(token)
      .post("/terminal/create", value)
      .then(function (response) {
        setConfirmationModal(true);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);

        if (error?.response?.data?.message
        ) {
          setError(error.response.data.message);
          setIsModalOpen(true);
        }
      });
  };

  const HandleChanges = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  return (
    <>
      {loading && <Loader />}
      {isModalOpen && (
        <Modal
          isModalOpen={isModalOpen}
          type={"Error"}
          message={error}
          showOk={true}
          modalCallBack={() => setIsModalOpen(false)}
        />
      )}
      {confirmationModal && data && (
        <TempPINPasswordModal
          data={data}
          isModalOpen={confirmationModal}
          type={`Terminal`}
        />
      )}

      <div className="relative md:pb-6 lg:items-center lg:flex-row">
        {Array.isArray(selectedStoreId) && selectedStoreId?.length > 1 && (
          <div className="absolute bottom-0 left-0 right-0 z-10 flex justify-center bg-white/30 -top-3 backdrop-blur-sm">
            <div className="pt-24 text-center text-gray-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto h-14 w-14 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={1}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span className="relative block pt-2 text-lg text-gray-700">
                Please select a store to create a terminal!
              </span>
            </div>
          </div>
        )}
        <div className={`${styles.form_card} md:px-5 md:pt-0 pt-5`}>
          <div className="">
            <div className={` flex items-center`}>
              <div className={`w-16 h-16 p-4 rounded-full bg-yellow-400`}>
                <img src={IconImage} alt="" />
              </div>
              <div className={`pl-4`}>
                <p className="text-lg font-semibold">Terminal Registration</p>
                <p className={`text-gray-600 text-sm`}>
                  To Add an account please enter the information.
                </p>
              </div>
            </div>

            <div className="pt-7">
              <Fragment>
                <form
                  onSubmit={submitHandler}
                  className="grid grid-cols-2 gap-3 md:gap-4 md:grid-cols-3"
                >
                  <div className="pb-2 text-sm font-semibold text-gray-600 border-b col-span-full">
                    Terminal Information
                  </div>

                  <div className="col-span-full">
                    <div className="grid grid-cols-2 gap-4 pt-3 lg:grid-cols-3">
                      <div className="">
                        <input
                          type="text"
                          name={`device_name`}
                          id={"device_name"}
                          value={trimStart(device_name)}
                          className={`${styles.form_control}`}
                          placeholder="Device Name"
                          onChange={HandleChanges}
                        />
                      </div>
                      <div className="">
                        <input
                          type="text"
                          name={`location`}
                          id={"location"}
                          value={trimStart(location)}
                          onChange={HandleChanges}
                          className={`${styles.form_control}`}
                          placeholder="Location"
                        />
                      </div>

                      <div className="">
                        <input
                          type="text"
                          name={`username`}
                          id={"username"}
                          value={trimStart(username)}
                          className={`${styles.form_control}`}
                          placeholder="Username"
                          onChange={HandleChanges}
                        />
                      </div>
                      <div className="">
                        <input
                          type="password"
                          name={`password`}
                          id={"password"}
                          value={trimStart(password)}
                          className={`${styles.form_control}`}
                          placeholder="Password"
                          onChange={HandleChanges}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-4 col-span-full">
                    <button
                      type="submit"
                      className={`w-full bg-green-500 text-white uppercase tracking-wider font-semibold rounded py-3 px-4 text-base hover:bg-green-700 transition-all`}
                      disabled={disabled}
                    >
                      {buttonText}
                    </button>
                  </div>
                </form>
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TerminalRegistration;
