import React, { useEffect, useState } from "react";
import styles from "../../Scss/Admin.module.scss";
import Table from "../Table";
import Filters from "./Filters";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import _, { capitalize, toNumber } from "lodash";
import download from "downloadjs";
import { currencyFormat } from "../../../Utils/Common";
import Loader from "../widget/Loader";
import { sub } from "date-fns";
import convertToLocalTime from "../../../Utils/timeZoneConverter";
import Axios from "../../../Config/AxiosInstance";

const conditionalRowStyles = [
  {
    when: (row) => row.type === "debit",
    style: {
      backgroundColor: "rgba(239,68,68,0.09)",
    },
  },
];
const columns = [
  {
    name: "Date",
    selector: (row) => row.date,
  },
  {
    name: "Store Name",
    selector: (row) => _.startCase(_.toLower(row.store)),
  },
  {
    name: "Total Day Sales",
    selector: (row) => row.sales,
  },
  {
    name: "Total Day Tips",
    selector: (row) => row.tips,
  },
  {
    name: "Net Withdrawal",
    selector: (row) => row.withdrawal,
    style: {
      paddingTop: "0",
      paddingBottom: "0",
      div: {
        background: "rgba(5, 66, 120, 0.0509803922)",
        color: "#054278",
        fontWeight: "bolder",
        borderRadius: "1.5rem",
        padding: "5px 15px",
        minWidth: "75px",
      },
    },
  },
  {
    name: "Status",
    selector: (row) => row.status,
  },
];

export default function Withdrawal({ selectedStoreId }) {
  const route = useNavigate();
  const { filteredData, token } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [datePick, setDatePick] = useState();
  const [search, setSearch] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState();
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const perPage = 20;
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    try {
      if (token && selectedStoreId) {
        setLoading(true);
        let userType = "store";
        Axios(token)
          .post("/payouts", {
            s: search,
            days: "",
            from: from,
            page: currentPage,
            to: to,
            size: perPage,
            type: userType,
            stores: selectedStoreId,
          })
          .then((response) => {
            if (response?.data?.response?.records) {
              filterData(response.data.response.records);
              setTotalRows(response.data.response.totalItems);
            }
            //ref.current.complete();
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    } catch (e) {
      //ref.current.complete();
      setLoading(false);
    }
  }, [selectedStoreId, token, search, currentPage, datePick, filteredData]);
  const filterData = (data) => {
    let records = [];
    data.map((item) => {
      records.push({
        id: item.id,
        date: convertToLocalTime(
          sub(item?.created_at || new Date(), { days: 1 }),
          "PPP",
        ),
        store: capitalize(item.Store?.store_name),
        sales: currencyFormat(toNumber(item?.meta.total_days_sales)),
        tips: currencyFormat(toNumber(item?.meta.total_days_tips)),
        withdrawal: currencyFormat(toNumber(item?.meta.net_withdrawal)),
        status: item.status !== "PAID" ? "PENDING" : "PAID",
        type: item.type,
        external_id: item.external_id,
      });
    });
    setTransactions(records);
  };
  const handleSearchSubmit = async (val) => {
    if (val) {
      setSearch(val);
    }
  };
  const onRowClick = (val) => {
    if (val.type === "debit") {
      route(`/overpaid/${val.external_id}`);
    } else {
      route(`/withdrawal/${val.id}`);
    }
  };

  const handleDownload = async () => {
    try {
      setTableLoading(true);
      const data = await Axios(token).post(
        "/payouts/report",
        {
          stores: selectedStoreId,
          s: search,
          days: "",
          from: from,
          to: to,
        },
        {
          responseType: "blob",
          headers: { Authorization: "Bearer " + token },
        },
      );
      setTableLoading(false);
      download(
        data.data,
        `Payouts report ${convertToLocalTime(new Date(), "MMMM Do YYYY")}`,
      );
    } catch (e) {
      setTableLoading(false);
    }
  };
  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(convertToLocalTime(val[0]).format("yyyy-MM-dd"));
    setTo(convertToLocalTime(val[1]).format("yyyy-MM-dd"));
  };

  return (
    <>
      {tableLoading && <Loader />}
      <Filters
        date={handleDateChange}
        enableSearch={false}
        onSearchSubmit={handleSearchSubmit}
        onSearchReset={() => setSearch("")}
        downloadBtn={true}
        onDownload={handleDownload}
        downloadBtnDisable={!transactions.length}
      />
      <div className={`mt-5 ${styles.table_ui_style} `}>
        <Table
          pending={loading}
          data={transactions}
          columns={columns}
          showPointer={true}
          perPage={perPage}
          totalRows={totalRows}
          conditionalRowStyles={conditionalRowStyles}
          onRowClick={(val) => onRowClick(val)}
          onPageChange={(val) => setCurrentPage(val)}
          isNoRowsPerPage={true}
          // className={"payout_table"}
        />
      </div>
    </>
  );
}
